import React, { useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Grid,
    CircularProgress
} from '@material-ui/core';

const GenericModal = ({ open, loading, title, children, handleCancel }) => {
    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

export default GenericModal;
