import { createAsyncAction } from 'redux-promise-middleware-actions';
import { exceptionCodeResponse, authUrlApi, validateResponse } from '../utils/urls';

export const getUserConfigAPI = createAsyncAction('GET_USER_CONFIG', async (token = null) => {
  const headers = { Authorization: `Bearer ${token}` };
  const res = await fetch(`${authUrlApi}/config/`, { headers })
    .then((response) => validateResponse(response))
    .catch((error) => {
      throw exceptionCodeResponse(error);
    });
  return res;
});