import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import { withStyles, TextField, Box } from '@material-ui/core'
import { CheckCircleOutline, Edit } from '@material-ui/icons';
import NumberFormat from 'react-number-format';

export const styles = (theme) => ({
    label: {
        fontFamily: 'Poppins',
        marginBottom: '5px',
        fontSize: '12px',
        letterSpacing: '0.4px',
        lineHeight: '16px',
        color: 'rgba(0, 0, 0, 0.38)'
    },
    input: {
        color: 'black',
        outline: 0,
        paddingLeft: '10px'
    },
    errorInput: {
        border: '1px solid #FF2272'
    },
    errorLabel: {
        color: '#FF2272'
    },
    fullWidth: {
        width: '100%',
        '& .MuiFormLabel-root.Mui-focused': {
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: '1.25px'
        },
        //helper text
        '& .MuiFormHelperText-root.Mui-error': {
            position: 'absolute',
            bottom: -20
        },
        //text label
        '& .MuiFormLabel-root': {
            color: theme.palette.variants && theme.palette.variants.variant4
        },
        //line disabled
        '& .MuiInput-underline': {
            '&::before': {
                borderBottom: `1px solid ${theme.palette.variants && theme.palette.variants.variant2}`
            },
            '&:hover': {
                borderBottom: `1px solid ${theme.palette.variants && theme.palette.variants.variant2}`
            }
        }
    },
    iconCheck: {
        color: '#23D9B7'
    },
    iconEdit: {
        color: theme.palette.secondary.main
    }
});

class PhoneInput extends ValidatorComponent {

    renderValidatorComponent() {
        const {
            classes,
            edit,
            label,
            name,
            value,
            handleEnableInput,
            onInputChange,
            disabled,
            errorMessages,
            validators,
            requiredError,
            validatorListener,
            cc,
            ...rest
        } = this.props;
        const { isValid } = this.state;

        return (
            <div style={{ position: 'relative' }}>
                <NumberFormat
                    {...rest}
                    label={label}
                    className={classes.fullWidth}
                    onValueChange={e => {
                        e = { ...e, target: { name, value: e.value } };
                        onInputChange(e);
                    }}
                    customInput={TextField}
                    error={!isValid ? true : false}
                    helperText={!isValid ? this.getErrorMessage() : false}
                    InputProps = {{
                        readOnly: edit || disabled ? true : false
                    }}
                    format={`${cc} ${getMaxValueStr(validators)}`}
                    value={value}
                    disabled={edit}
                />
                {
                    isValid && !edit ? (
                        <Box style={{ position: 'absolute', right: 0, bottom: 10 }}>
                            <CheckCircleOutline className={classes.iconCheck}/>
                        </Box>
                    ) : null
                }
            </div>
        );
    }
}

function getMaxValueStr(arr) {
    const max = Number(arr[arr.length - 1].replace(/\D/g, ""));
    return Array.from(Array(max).keys()).map( i => '#').join('');
}

export default withStyles(styles)(PhoneInput);