import React, { useState } from 'react';
import { Field, change} from 'redux-form';
import PropTypes from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import {
    renderTextField,
    renderSelectField,
    renderNumberField,
    CustomUploadFileAsync,
    renderDateField,
    renderDateTimeField
} from '../../form/renderInputsForm';
import { renderPhoneField } from '../../inputValidator/renderInputsForm';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useStyles } from '../styles';

const boolValues = [
    {
        'value':'true',
        'name':'Si'
    },
    {
        'value':'false',
        'name':'No'
    }
]

const WithoutviolenceForm = () =>{
    const classes = useStyles();
    const dispatch = useDispatch();
    const [dateLookVehicle, setDateLookVehicle] = useState();
    const [dateLeftVehicle, setDateLeftVehicle] = useState();
    const [dateSatelliteRecovery, setDateSatelliteRecovery] = useState();
    const [disableSave, setDisableSave] = useState(false);
    const [settingField, setSettingField] = useState({
        identifierType: "sinesterVideo",
        branch: null,
        isTemporal: true,
      });

    const changeDate = (value, type) =>{
        const formatedtime = moment(value).format('DD/MM/YYYY hh:mm A');

        if(type == "dateLeftVehicle"){
            dispatch(change('stealingForm', 'dateLeftVehicle', formatedtime));
            setDateLeftVehicle(formatedtime) 
        }

        if(type == "dateLookVehicle"){
            dispatch(change('stealingForm', 'dateLookVehicle', formatedtime));
            setDateLookVehicle(formatedtime) 
        }

        if(type == "dateSatelliteRecovery"){
            dispatch(change('stealingForm', 'dateSatelliteRecovery', formatedtime));
            setDateSatelliteRecovery(formatedtime); 
        }
    }

    return (
        <Grid container> 
            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field
                    name="dateLeftVehicle"
                    label="Fecha y hora de cuándo dejó el vehículo"
                    value={dateLeftVehicle}
                    dateValue={dateLeftVehicle}
                    component={renderDateTimeField}
                    custom={{ tabIndex: 0 }}
                    classes={classes.fontSizeLabel}
                    onChange={ (value) => changeDate(value,"dateLeftVehicle") }
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field
                    name="dateLookVehicle"
                    label="Fecha y hora de cuándo lo fue a buscar"
                    value={dateLookVehicle}
                    dateValue={dateLookVehicle}
                    component={renderDateTimeField}
                    custom={{ tabIndex: 1 }}
                    classes={classes.fontSizeLabel}
                    onChange={ (value) => changeDate(value,"dateLookVehicle") }
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field 
                    name="placeAfterLeavingVehicle" 
                    label="¿Donde se dirigió después de dejar el vehículo?" 
                    component={renderTextField}
                    custom={{ tabIndex: 2 }}
                    rows={4}
                    multiline
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field 
                    name="callEmergency" 
                    label="¿Llamó al 911? ¿Solicitó ayuda de alguien en la vía pública?" 
                    component={renderTextField}
                    custom={{ tabIndex: 3 }}
                    rows={4}
                    multiline
                />
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <p style={{marginTop: "2%"}}>Cuando tomó conocimiento del hurto de su vehículo</p>
                </Grid>
                <Grid item xs={12} md={12} style={{ padding: 8 }}>
                    <Field 
                        name="inquiriesInArea" 
                        label="A) ¿Realizó averiguaciones por la zona? ¿De qué tipo?" 
                        component={renderTextField}
                        custom={{ tabIndex: 3 }}
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{ padding: 8 }}>
                    <Field
                        name="dateSatelliteRecovery"
                        label="B) Si la unidad contaba con recuperación satelital, ¿Qué día y hora exacta dio aviso activando dicho sistema?" 
                        value={dateSatelliteRecovery}
                        dateValue={dateSatelliteRecovery}
                        component={renderDateTimeField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                        onChange={ (value) => changeDate(value,"dateSatelliteRecovery") }
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{ padding: 8 }}>
                    <Field 
                        name="recoveryMechanism" 
                        label="C) ¿Tenía el conductor conocimiento que la unidad estaba equipada con este mecanismo de recuperación?" 
                        component={renderSelectField}
                        custom={{ tabIndex: 6 }}
                    >
                        {
                            boolValues.map((reg, index) => (
                                <MenuItem 
                                key={index} 
                                value={reg['value']}>
                                    {reg['name']}
                                </MenuItem>
                            ))
                        }
                    </Field>
                </Grid>
                <Grid item xs={12} md={12} style={{ padding: 8 }}>
                    <Field 
                        name="recoveryMechanismActivation" 
                        label="D) ¿Conocía el conductor el procedimiento que debía llevar a cabo para activarlo?" 
                        component={renderSelectField}
                        custom={{ tabIndex: 6 }}
                    >
                        {
                            boolValues.map((reg, index) => (
                                <MenuItem 
                                key={index} 
                                value={reg['value']}>
                                    {reg['name']}
                                </MenuItem>
                            ))
                        }
                    </Field>
                </Grid> 
            </Grid>   
            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field 
                    name="parkedPublicRoad" 
                    label="¿Estaba estacionado en la vía pública?" 
                    component={renderSelectField}
                    custom={{ tabIndex: 6 }}
                >
                    {
                        boolValues.map((reg, index) => (
                            <MenuItem 
                            key={index} 
                            value={reg['value']}>
                                {reg['name']}
                            </MenuItem>
                        ))
                    }
                </Field>
            </Grid>

            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field 
                    name="alarm" 
                    label="¿El vehículo poseía alarma y/u otro dispositivo de seguridad?" 
                    component={renderSelectField}
                    custom={{ tabIndex: 6 }}
                >
                    {
                        boolValues.map((reg, index) => (
                            <MenuItem 
                            key={index} 
                            value={reg['value']}>
                                {reg['name']}
                            </MenuItem>
                        ))
                    }
                </Field>
            </Grid>
            <Grid item xs={12} md={12} style={{ padding: 8 }}>
                <Field 
                    name="consultedTrafficControlCompany" 
                    label="¿Consultó con la empresa de control de tránsito (grúas) o en la playa municipal?" 
                    component={renderSelectField}
                    custom={{ tabIndex: 6 }}
                >
                    {
                        boolValues.map((reg, index) => (
                            <MenuItem 
                            key={index} 
                            value={reg['value']}>
                                {reg['name']}
                            </MenuItem>
                        ))
                    }
                </Field>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field 
                    name="securityMeasures" 
                    label="¿Con qué medidas de seguridad fue dejado?" 
                    component={renderTextField}
                    custom={{ tabIndex: 3 }}
                    rows={4}
                    multiline
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field 
                    name="cameras" 
                    label="¿En el lugar de ocurrencia hay cámaras de seguridad públicas o privadas? Mencione dónde y si fueron solicitadas" 
                    component={renderTextField}
                    custom={{ tabIndex: 3 }}
                    rows={4}
                    multiline
                />
            </Grid>           
            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field 
                    name="keys" 
                    label="¿Tiene en su poder las llaves del vehículo? ¿Posee original y copia?" 
                    component={renderTextField}
                    custom={{ tabIndex: 3 }}
                    rows={4}
                    multiline
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field 
                    name="witness" 
                    label="¿Hubo algún testigo de lo sucedido que haya omitido en la denuncia policial? En caso afirmativo, aporte por favor nombre completo y teléfono de contacto y/o dirección." 
                    component={renderTextField}
                    custom={{ tabIndex: 3 }}
                    rows={4}
                    multiline
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field 
                    name="carUse" 
                    label="¿Qué uso le daba al vehículo?" 
                    component={renderTextField}
                    custom={{ tabIndex: 3 }}
                    rows={4}
                    multiline
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field 
                    name="documentation" 
                    label="¿En el interior del vehículo había documentación y/o elementos de valor cuando fue robada? Menciona cuáles." 
                    component={renderTextField}
                    custom={{ tabIndex: 3 }}
                    rows={4}
                    multiline
                />
            </Grid>
            <Grid item xs={12} md={12} style={{ padding: 8 }}>
                <Field 
                    name="carStore" 
                    label="¿Dónde guardaba habitualmente el vehículo?" 
                    component={renderTextField}
                    custom={{ tabIndex: 3 }}
                    rows={4}
                    multiline
                />
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12}><p>Datos del asegurado: </p></Grid>
                <Grid item xs={12} md={6} style={{ padding: 8 }}>
                    <Field
                        name="occupation"
                        label="Ocupación"
                        component={renderTextField}
                        custom={{ tabIndex: 6 }}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: 8 }}>
                    <Field
                        name="name"
                        label="Apellido y Nombre"
                        component={renderTextField}
                        custom={{ tabIndex: 7 }}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: 8 }}>
                    <Field
                        name="typeNumberDocument"
                        label="Tipo y numero de documento"
                        component={renderTextField}
                        custom={{ tabIndex: 8 }}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: 8 }}>    
                    <Field
                        name="homeAddress"
                        label="Domicilio real"
                        component={renderTextField}
                        custom={{ tabIndex: 9 }}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: 8 }}>
                    <Field
                        name="workAddress"
                        label="Domicilio laboral"
                        component={renderTextField}
                        custom={{ tabIndex: 10 }}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: 8 }}>
                    <Field
                        name="mail"
                        label="E-mail"
                        component={renderTextField}
                        custom={{ tabIndex: 11 }}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: 8 }}>
                    <Field
                        name="phoneNumber"
                        label="Teléfono celular"
                        component={renderPhoneField}
                        custom={{ tabIndex: 12 }}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>
            </Grid>    
            <Grid item xs={12} md={6} style={{ padding: 8 }}>    
                <Field 
                    name="delayReasons" 
                    label="Si se demoró en realizar la denuncia policial y/o la del siniestro ante esta aseguradora, indique por favor los motivos de dicha demora." 
                    component={renderTextField}
                    custom={{ tabIndex: 3 }}
                    rows={4}
                    multiline
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field 
                    name="insuredOtherCompanies" 
                    label="¿El vehículo estuvo asegurado en otras compañías? ¿Cuáles?" 
                    component={renderTextField}
                    custom={{ tabIndex: 3 }}
                    rows={4}
                    multiline
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field 
                    name="kilometres" 
                    label="¿Qué kilometraje aproximadamente recorrido tenía al momento del hecho?" 
                    component={renderNumberField}
                    custom={{ tabIndex: 3 }}
                />
            </Grid>
            <Grid container style={{marginBottom:"3%", marginTop:"3%"}}>
                <div className={classes.note}>
                    <p className={classes.noteHeading}>Nota:</p>
                    <p>
                        Si cuenta con algún archivo con formato de video que aporte información para el Siniestro, le agradecemos lo envíe como un anexo al correo electrónico de{' '}
                        <span className={classes.email}>robototal@libraseguros.com.ar</span> indicando en el asunto el Número de Siniestro y nombre del Tomador.
                    </p>
                </div>
            </Grid>
            {/* <Grid item xs={12} md={6} style={{ padding: 8 }}>
                <Field
                    name={"video"}
                    label={"¿Posee video del hecho? En caso afirmativo, apórtelo" }
                    custom={{ tabIndex: 1 }}
                    component={CustomUploadFileAsync}
                    settings={settingField}
                    disabled={disableSave}
                    fileTypes={".mp4, .webm, .ogg, .avi, .mpeg, .mpg, .mov, .wmv"}
                    maxSize={20000}
                    required
                    helperText="Campo es requerido"
                    footerDescription={
                        "Tamaño de video permitido 20MB."
                    }
                />
            </Grid> */}
        </Grid>
    )
}

export default WithoutviolenceForm;