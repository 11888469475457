import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    buttonEdit: {
        width: '35px !important',
        backgroundColor: theme.palette.secondary.main || '#00A0F8',
        height: '35px !important',
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main || '#00A0F8'
        },
        marginLeft: '10px'
    }
}));

export function ButtonEdit({ onClick }) {
    const classes = useStyles();
    return (
        <IconButton onClick={onClick} className={classes.buttonEdit}>
            <Edit />
        </IconButton>
    );
}

export function ButtonExtra({ children }) {
    return (
        <Button
            variant="outlined"
            color="secondary"
            style={{ marginRight: 16 }}
        >
            {children}
        </Button>
    );
}
