import {
    GET_STATE_LIST,
    GET_STATE_LIST_FULFILLED,
    GET_STATE_LIST_REJECTED,
    GET_STATE_LIST_PENDING,
    RESET_STATE_LIST
  } from "../actions/types";
  
  const initialState = {
    data: [],
    loading: false,
    error: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_STATE_LIST_PENDING:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case GET_STATE_LIST_FULFILLED:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case GET_STATE_LIST_REJECTED:
        return {
          ...state,
          data: [],
          loading: false,
          error: true,
        };
      case RESET_STATE_LIST:
          return {
              ...state,
              data: [],
              loading: false,
              error: false,
          };
      case GET_STATE_LIST:
        return {
          ...state,
          data: [],
        };
      default:
        return state;
    }
  }
  