import React, { Fragment } from "react";
import moment from "moment";
import { Button, Typography, CircularProgress } from "@material-ui/core";
import { CalendarTodayOutlined } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

import { general } from "../../../../utils/icons";

const TripHistoryCard = ({
  classes,
  policy,
  handleOpenTravels,
  isLoaded,
  refetching = true,
}) => {
  const getTotalDuration = (travels) => {
    const total = travels.reduce(function (a, b) {
      return a + b.durationMs;
    }, 0);
    const totalDuration = moment.duration(total);
    const format =
      Math.floor(totalDuration.asHours()) + moment.utc(total).format(":mm:ss");
    const [hour, minutes, seconds] = format.split(":");
    return `${hour}hs ${minutes}min ${seconds}seg`;
  };

  const fetchingPlaceholder = (
    <div className={classes.fetchingPlaceholder}>
      <Skeleton
        animation="wave"
        width="100%"
        height={350}
        style={{
          transform: "scale(1)",
          borderRadius: "0px 0px 15px 15px",
          backgroundColor: "rgba(0, 0, 0, 0.005)",
        }}
      />
      <p>Cargando...</p>
    </div>
  );

  return (
    <div
      className={`${classes.orangeCard} ${refetching ? "refetching" : ""}`}
      style={{
        flex: 1,
        maxWidth: "100%",
        height: "100%",
      }}
    >
      <div className={classes.orangeCardHeader}>
        <div style={{ width: "60%" }}>
          {general.car}
          <Typography>Chequea tus viajes</Typography>
        </div>
        {refetching ? (
          <CircularProgress size={16} />
        ) : (
          <div>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleOpenTravels(policy)}
            >
              ver más
            </Button>
          </div>
        )}
      </div>
      {isLoaded ? (
        <div className={classes.orangeTable}>
          <div>
            <span>
              <CalendarTodayOutlined
                color="primary"
                style={{ fontSize: 12.5 }}
              />{" "}
              Inicio del viaje
            </span>
            <span>Consumo</span>
          </div>
          {Object.keys(policy.history)
            .filter((_, i) => i < 1)
            .map((h, i) => (
              <Fragment key={`key__${h}__${i}`}>
                <div className={classes.orangeRow}>
                  <span>{h}</span>
                  <span>{getTotalDuration(policy.history[h])}</span>
                </div>
                {policy.history[h]
                  .filter((_, i) => i < 5)
                  .map((travels, i) => (
                    <div key={`key__${h}__${travels.time}__${i}`}>
                      <span>{travels.time}</span>
                      <span>{travels.strDuration}</span>
                    </div>
                  ))}
              </Fragment>
            ))}
        </div>
      ) : (
        fetchingPlaceholder
      )}
    </div>
  );
};

export default TripHistoryCard;
