import {
  attendanceAvailableAPI,
  getAttendanceByInsuredAPI,
} from "../sevices/attendanceService";

export const attendanceAvailable = (token, payload) => async (dispatch) => {
  await dispatch(attendanceAvailableAPI(token, payload, dispatch));
};

export const getAttendanceByInsured = (token, payload) => async (dispatch) => {
  await dispatch(getAttendanceByInsuredAPI(token, payload, dispatch));
};
