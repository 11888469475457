import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;
export const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: 5,
    height: 100,
    justifyContent: "space-between",
  },
  toolbarIcon: {
    display: "flex",
    padding: "0 8px",
    height: 110,
    ...theme.mixins.toolbar,
    "& > div": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      paddingLeft: 13,
    },
  },
  sectionMenu: {
    display: "flex",
    alignItems: "center",
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    zIndex: "1000 !important",
    borderRight: "none !important",
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    zIndex: "1000 !important",
    overflowX: "hidden",
    position: "relative",
    borderRight: "none !important",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  icon: {
    fontSize: 23,
    color: theme.palette.primary.main,
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  iconActive: {
    fontSize: 23,
    "& svg": {
      color: theme.palette.secondary.dark,
    },
  },
  navList: {
    padding: "4px 0px 4px 9px",
  },
  deactiveLink: {
    "& span": {
      color: theme.palette.secondary.main,
    },
    "& svg": {
      color: theme.palette.secondary.main,
    },
  },
  activeLink: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.secondary.dark,
      zIndex: 2,
      marginTop: -1,
    },
    "& .MuiListItem-root": {
      zIndex: 1,
    },
    "& .hover-effect": {
      width: "100% !important",
      height: "100%",
      left: 0,
      top: 0,
      position: "absolute",
      borderRadius: 40,
      zIndex: 0,
      transition: "width 0.5s ease-in",
    },
    "& .MuiListItemIcon-root": {
      minWidth: 40,
      color: theme.palette.secondary.main,
    },
    "& span": {
      fontWeight: 700,
      color: theme.palette.secondary.main,
    },
    "& li": {
      padding: "8px 15px 7px 9px",
    },
    "& svg": {
      color: theme.palette.secondary.dark,
    },
  },
  deactiveNavlink: {
    color: theme.palette.secondary.main,
    "& li": {
      padding: "8px 15px 7px 9px",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 40,
    },
  },

  deactiveNavlinkItem: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 40,
    },
    "& span": {
      color: theme.palette.secondary.main,
    },
  },
  listText: {
    wordBreak: "break-word",
    whiteSpace: "break-spaces",
    minWidth: 140,
  },
  barIcon: {
    transition: "all .5s ease-out",
    "& .hover-effect": {
      width: "100% !important",
      height: "100%",
      left: 0,
      top: 0,
      position: "absolute",
      backgroundColor: theme.palette.background.default,
      borderTopLeftRadius: 40,
      borderBottomLeftRadius: 40,
      zIndex: -1,
      transition: "width 0.5s ease-in",
    },
  },
  barIconRotate: {
    transform: "rotate(-180deg)",
  },
  listItem: {
    borderTopLeftRadius: 40,
    borderBottomLeftRadius: 40,
    padding: "7px 15px 8px 10px",
    position: "relative",
    "& .MuiListItemIcon-root": {
      minWidth: 40,
    },
    "&.MuiListItem-button:hover": {
      backgroundColor: theme.palette.background.default,
    },
    "& .line": {
      borderLeft: "4px solid",
      position: "absolute",
      height: 39,
      top: 4,
      left: -9,
      borderRadius: "0px 10px 10px 0px",
    },
  },
  optionOpen: {
    backgroundColor: "#F4F4FA",
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
  },
  appBar: {
    height: 111,
    backgroundColor: "transparent !important",
    width: "calc(100% - 59px) !important",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "0 0 0 0 !important",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      backgroundColor: "white",
      height: 79,
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    },
  },
  appBarShift: {
    backgroundColor: "transparent",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: "0 0 0 0",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    color: theme.palette.secondary.main,
    fontSize: "24px !important",
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  iconUser: {
    backgroundColor: theme.palette.primary.main,
  },
  // Drawer Profile
  widthDrawerProfile: {
    "& .options": {
      padding: "0 50px",
      [theme.breakpoints.down(426)]: {
        // ["@media (max-width:426px)"]: {
        // eslint-disable-line no-useless-computed-key
        padding: "0 20px",
      },
    },
  },

  drawerPaperDrawRight: {
    width: 300,
    zIndex: "9000 !important",
    // ["@media (max-width:426px)"]: {
    [theme.breakpoints.down(426)]: {
      // eslint-disable-line no-useless-computed-key
      width: 250,
      zIndex: "9000 !important",
    },
    // ["@media (max-width:360px)"]: {
    [theme.breakpoints.down(360)]: {
      // eslint-disable-line no-useless-computed-key
      width: 220,
      zIndex: "9000 !important",
    },
  },
  //Bottom navigation
  menuBottom: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    zIndex: 200,
    height: 65,
  },
  navActions: {
    "&:not(.Mui-selected) > li": {
      paddingTop: "0px",
      color: theme.palette.primary.main,
    },
    "&.Mui-selected > li": {
      color: theme.palette.secondary.dark,
      width: 55,
      height: 65,
      backgroundColor: theme.palette.background.main,
      borderBottomLeftRadius: 40,
      borderBottomRightRadius: 40,
    },
    flex: 0,
    maxWidth: "auto !important",
    minWidth: "auto !important",
  },
  topMenu: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      "& > button:nth-of-type(2)": {
        marginLeft: "auto",
      },
    },
  },
}));
