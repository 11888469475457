import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';
export default function Wrapper({ loading = false, children }) {
    const classes = useStyles();
    return (
        <Grid
            className={classes.cardGraph}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid
                item
                xs={12}
                className={clsx(
                    classes.flexCenter,
                    loading ? classes.paddingBox : ''
                )}
            >
                {children}
            </Grid>
        </Grid>
    );
}
