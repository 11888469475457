import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import "moment/locale/es";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  SvgIcon,
  Drawer,
  IconButton,
  Container,
  Button,
  Paper,
} from "@material-ui/core";
import PaymentLinkBTN from "../../../components/paymentLinks/paymentLinkBtn";

import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  Close,
  HelpOutline,
} from "@material-ui/icons";
import MUICarousel from "react-material-ui-carousel";
import { useStyles } from "../styles";
import QsiseExecuteWrapper from "qsise-execute";
import { getQsiseDashboard } from "../../../actions/qSiseDashboardAction";
import { getQsiseSinister } from "../../../actions/qSiseSinisterAction";
import ButtonAction from "../../../components/buttonAction";
import {
  ID_Q_SISE_CARTERA,
  SISE_URL,
  ID_Q_SISE_FEES_PAYMENTS,
  quotationUrlBase,
  ID_Q_SISE_ATTENDANCES_INSURED,
} from "../../../utils/urls";
import CardInsured from "../../../components/widgets/cards/insured";
import CardPay from "../../../components/widgets/cards/payCustom";
import CardComplaints from "../../../components/widgets/cards/complaintsAlternative";
import { general } from "../../../utils/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import { getDataUserLoggeIn } from "../../../actions/userInfoActions";
import { getPaymentMethods } from "../../../actions/paymentMethodsActions";
import { getAllRiskType } from "../../../actions/riskTypeActions";
import { getPaymentLinks } from "../../../actions/paymentsLinks";

import { BannersNews } from "news-list";
import { Alert } from "@material-ui/lab";
import { getAllActions } from "../../../actions/actions";
import { navigate } from "@reach/router";
import { getDataToken } from "../../../utils/index";

import ISinister from "../../../assets/images/icon_sinister_libra.png";
import ActivityTracker from "../../../components/widgets/activityTracker";
import OrangeTime from "../../../components/widgets/orangeTime";
import AvatarDefault from "../../../assets/images/avatar_libra_asegurados.png";
import VisibilityIcon from '@material-ui/icons/Visibility';
import PaymentLinkModal from "../../../components/paymentLinks/paymentLinkModal";
import { attendanceAvailable } from "../../../actions/attendanceActions";
import { useMemo } from "react";
import { AttendancesServices } from "../../../components/attendance";

const Dashboard = () => {
  SwiperCore.use([Navigation, Pagination]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector((state) => state.auth.token);
  const paymentMethods = useSelector((state) => state.paymentMethods);
  const [openDetails, setOpenDetails] = useState(false);
  const [openPaymentsDetails, setOpenPaymentsDetails] = useState(false);
  const [policy, setPolicy] = useState(null);
  const [openAttendanceInfo, setOpenAttendanceInfo] = useState(false);
  const config = useSelector((state) => state.userConfig.data);
  const { loading: loadingDashboard, ...qSiseDashboard } = useSelector(
    (state) => state.qSiseDashboard
  );
  const qSiseSinister = useSelector((state) => state.qSiseSinister);
  const pendingPaymentLinks = useSelector(
    (state) => state.paymentLinksPaymentProgess.data
  );
  const userInfo = useSelector((state) => state.userInfo);
  const auth = useSelector((state) => state.auth);
  const actions = useSelector((state) => state.actions);
  const risktype = useSelector((state) => state.risktype);
  const [, setDataDashboard] = useState(null);
  const [policies, setPolicies] = useState(null);
  const [dataSinister, setDataSinister] = useState(null);
  const [isHistory, setIsHistory] = useState(false);
  const [selectedPolice, setSelectedPolice] = useState(null);
  const swiperPolice = useRef(null);
  const [dataToken, setDataToken] = useState({});
  const [userInfoUpdated, setUserInfoUpdated] = useState(userInfo);
  const [currentUserCode, setCurrentUserCode] = useState(null);
  const [openPaymentLinksModal, setOpenPaymentLinksModal] = useState(false);
  const { attendanceAvailableByInsured } = useSelector((state) => state);

  const getUnformatedUserCode = (formatedUserCode) => {
    if (!formatedUserCode) {
      return "";
    }

    const userCodeSplit = formatedUserCode.split("-");
    return userCodeSplit[userCodeSplit.length - 1];
  };

  useEffect(() => {
    // Varify if user information has the profile picture
    // then it's added
    let data = { ...userInfo };
    if (data.data.pictureUrl === undefined) {
      data.data = { ...data.data, pictureUrl: AvatarDefault };
    }
    setUserInfoUpdated(data);
  }, [userInfo]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      setOpenDetails(false);
    };

    const handleStorageOpenModalPendingPaymentLinkChange = (event) => {
      setOpenPaymentLinksModal(!openPaymentLinksModal);
    };

    window.addEventListener("storage", handleStorageChange);
    localStorage.setItem("isPaymentLinkInPaymentProcess", false);

    window.addEventListener(
      "storageOpenModalPendingPaymentLink",
      handleStorageOpenModalPendingPaymentLinkChange
    );
    localStorage.setItem("openModalPendingPaymentLink", false);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener(
        "storageOpenModalPendingPaymentLink",
        handleStorageOpenModalPendingPaymentLinkChange
      );
    };
  }, []);

  useEffect(() => {
    searchPendingLinks()
  }, [currentUserCode]);


  
  useEffect(() => {
    if (
      pendingPaymentLinks &&
      pendingPaymentLinks.docs &&
      pendingPaymentLinks.docs.length &&
      !openPaymentLinksModal
    ) {

      setOpenPaymentLinksModal(true);
      localStorage.setItem("persist:pendingPaymentLink", pendingPaymentLinks.docs ); 
    }else{

      localStorage.setItem("persist:pendingPaymentLink", false ); 
    }
  }, [pendingPaymentLinks]);

  const searchPendingLinks = () => {
    if (token && currentUserCode) {
      dispatch(getPaymentLinks(token, [currentUserCode]));
    }
  }


  const handlePolicyDrawer = (policyNumber) => {
    setPolicy(policyNumber);
    setOpenDetails(true);
  };

  const handlePaymentDrawer = (policyNumber) => {
    setPolicy(policyNumber);
    setOpenPaymentsDetails(true);
  };

  const executeInitQsiseDashboard = async () => {
    await dispatch(getQsiseDashboard(token));
  };

  const executeInitQsiseSinister = async () => {
    await dispatch(getQsiseSinister(token));
  };

  useEffect(() => {
    if (Object.keys(qSiseDashboard.data).length === 0) {
      executeInitQsiseDashboard();
    }
    if (Object.keys(qSiseSinister.data).length === 0) {
      executeInitQsiseSinister();
    }
    if (auth && !auth.token) {
      dispatch(getDataUserLoggeIn(auth?.token));
    }
    if (actions && actions.data && !actions.data.length) {
      dispatch(getAllActions(token, { active: true }));
    }

    if (auth && auth.token) {
      const _dataToken = getDataToken(auth?.token);
      const unFormatedUserCode = getUnformatedUserCode( _dataToken.user.insuredCode);
      dispatch(attendanceAvailable(auth?.token, _dataToken.user.insuredCode));

      setCurrentUserCode(unFormatedUserCode);
      setDataToken(_dataToken);
      if (
        paymentMethods &&
        paymentMethods.data &&
        !paymentMethods.data.length
      ) {
        dispatch(getAllRiskType(auth?.token));
        dispatch(getPaymentMethods(auth?.token));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token]);

  /* useEffect(() => {
        if (
            auth &&
            auth.token &&
            paymentMethods &&
            paymentMethods.data &&
            !paymentMethods.data.length
        ) {
            dispatch(getAllRiskType(auth?.token));
            dispatch(getPaymentMethods(auth?.token));
            const _dataToken = getDataToken(auth?.token);
            setDataToken(_dataToken);
        }
    }, [auth.token]); */

  useEffect(() => {
    if (qSiseDashboard?.data?.data) {
      setDataDashboard(qSiseDashboard?.data?.data?.listaPolizas?.result);
      setPolicies(qSiseDashboard?.data?.data?.listaPolizas?.result);
    }
    if (qSiseSinister?.data?.data) {
      setDataSinister(qSiseSinister?.data?.data?.listaSiniestros?.result);
    }
  }, [qSiseDashboard, qSiseSinister]);

  useEffect(() => {
    changeActionAttendance();
  }, [actions]);

  const policiesSort = policies?.sort((a, b) => {
    if (a.Estado === "VENCIDA" && b.Estado !== "VENCIDA") {
      return -1;
    }
    if (
      a.Estado === "VENCIDA" &&
      b.Estado === "VENCIDA" &&
      moment(moment(a.proxima_cuota_vencimiento).format("YYYY-MM-DD")).isBefore(
        moment(b.proxima_cuota_vencimiento).format("YYYY-MM-DD")
      )
    ) {
      return -1;
    }

    if (
      a.Estado !== "VENCIDA" &&
      b.Estado !== "VENCIDA" &&
      a.Estado === "PENDIENTE" &&
      b.Estado === "PENDIENTE" &&
      moment(moment(a.proxima_cuota_vencimiento).format("YYYY-MM-DD")).isBefore(
        moment(b.proxima_cuota_vencimiento).format("YYYY-MM-DD")
      )
    ) {
      return -1;
    }

    if (a.estado_poliza === "CANCELADA" && b.estado_poliza !== "CANCELADA") {
      return -1;
    }

    return 0;
  });

  // toggleHistory : sirve para cambiar de state del toogle que renderiza entre el historico y la lista de polizas
  const toggleHistory = () => {
    setIsHistory(!isHistory);
  };

  // onActiveAction: sirve para actualizar el state de selectedPolice y poder identificar que poliza se esta observando en pagos
  const onActiveAction = (item) => {
    setSelectedPolice(item?.id_pv);
  };
  // openNewTab: permite abrir una nueva ventana con una url
  const openNewTab = (url) => {
    window.open(url, "_blank");
  };

  // openMainUrlNewTab: permite abrir una nueva ventana
  const openMainUrlNewTab = (url) => {
    if (url.includes("https") || url.includes("http") || url.includes("www")) {
      window.open(url, "_blank");
      return;
    } else {
      navigate(url);
    }
  };

  const openDrawerAttendance = () => {
    setOpenAttendanceInfo(true);
  };

  const getAttendanceAvailable = useMemo(() => {
    return attendanceAvailableByInsured;
  }, [attendanceAvailableByInsured]);


  const changeActionAttendance = () => {
    actions.data.forEach((item) => {
      if (item.name === "Servicios de asistencia") {
        item.pathType = "drawer";
      }
    });
  };
  // query filter visibility news
  const query = { query: "visibility=/1757/i" };
  return (
    <>
      <PaymentLinkModal
        open={openPaymentLinksModal}
        onClose={() => setOpenPaymentLinksModal(false)}
        payments={pendingPaymentLinks}
      />

      {/* Banner Slider */}
      <BannersNews
        token={token}
        url={quotationUrlBase}
        query={query}
        userInfo={userInfoUpdated}
      />
      <Container className={classes.content} maxWidth={"lg"}>
        {/* Orange Time */}
        {auth.token && dataToken.user && (
          <Grid container>
            <OrangeTime user={dataToken.user} token={auth.token} />
          </Grid>
        )}
        {/* Cards Policies */}
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
          <Grid item xs={6} md={6}>
            <Typography className={classes.subtitle}>
              {isHistory ? `Pólizas No Vigentes` : `Pólizas Vigentes`}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            xs={6}
            md={6}
          >
            <Button color="primary" onClick={toggleHistory} endIcon={<VisibilityIcon style={{ marginBottom: '4px' }} />}>
              {isHistory ? `ver pólizas vigentes` : `Ver pólizas no vigentes`}
            </Button>
          </Grid>
        </Grid>

        {!loadingDashboard &&
        policies &&
        policies?.filter((e) =>
          isHistory
            ? e?.estado_poliza !== "VIGENTE"
            : e?.estado_poliza === "VIGENTE"
        ).length === 0 ? (
          <Alert severity="info">Sin pólizas para mostrar</Alert>
        ) : (
          <div className={classes.rootSwiper}>
            <div className="swiper-left-arrow">
              <KeyboardArrowLeft color="primary" />
            </div>
            <Swiper
              ref={swiperPolice}
              className={classes.sliderInsured}
              spaceBetween={21}
              slidesPerGroupSkip={1}
              slidesOffsetBefore={0}
              navigation={{
                nextEl: ".swiper-right-arrow",
                prevEl: ".swiper-left-arrow",
                clickable: true,
              }}
              breakpoints={{
                100: {
                  slidesPerView: 0,
                  slidesOffsetBefore: 1,
                },
                700: {
                  slidesPerView: 2,
                  slidesOffsetBefore: 0,
                },
                1150: {
                  slidesPerView: 4,
                  slidesOffsetBefore: 0,
                },
              }}
              modules={[Navigation, Pagination]}
            >
              {loadingDashboard &&
                Array(4)
                  .fill(true)
                  .map((item, index) => (
                    <SwiperSlide key={index}>
                      <CardInsured loading={item} />
                    </SwiperSlide>
                  ))}
              {!loadingDashboard &&
                policies &&
                policies.length &&
                policies
                  ?.filter((e) =>
                    isHistory
                      ? e?.estado_poliza !== "VIGENTE"
                      : e?.estado_poliza === "VIGENTE"
                  )
                  .map((policy, index) => (
                    <SwiperSlide key={index}>
                      <CardInsured
                        risktype={risktype.data.find(
                          (e) => e.codeBE === policy.codigo_ramo
                        )}
                        selected={policy?.id_pv === selectedPolice}
                        isHistory={isHistory}
                        policy={policy}
                        handleOpenDrawer={() => {
                          handlePolicyDrawer(policy.id_pv);
                        }}
                      />
                    </SwiperSlide>
                  ))}
            </Swiper>
            <div className="swiper-right-arrow">
              <KeyboardArrowRight color="primary" />
            </div>
          </div>
        )}

        {/* Pagos y Siniestros pendientes */}
        <div style={{ overflow: "hidden", marginTop: 28 }}>
          <Grid container spacing={2}>
            {
              // Adding policies validation to hide
              // pay cards like policies card
              !loadingDashboard &&
              policies &&
              policies?.filter((e) =>
                isHistory
                  ? e?.estado_poliza !== "VIGENTE"
                  : e?.estado_poliza === "VIGENTE"
              ).length === 0 ? (
                <Alert severity="info">Sin pagos para mostrar</Alert>
              ) : (
                !loadingDashboard &&
                policies &&
                policies.length > 0 && (
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subtitle}>Pagos</Typography>
                    {policiesSort && policiesSort.length && (
                      <MUICarousel
                        classes={{
                          root: classes.carouselComplaint,
                        }}
                        NextIcon={<KeyboardArrowRight />}
                        PrevIcon={<KeyboardArrowLeft />}
                        navButtonsProps={{
                          style: {
                            padding: 0,
                            backgroundColor: "transparent",
                            color: "#979797",
                          },
                        }}
                        navButtonsAlwaysVisible={true}
                        cycleNavigation={false}
                        indicators={false}
                        autoPlay={false}
                        onChange={(now) => onActiveAction(policiesSort[now])}
                      >
                        {policiesSort
                          .filter(
                            (item) =>
                              item?.importe_vencido !== 0 &&
                              item?.proxima_cuota_importe !== 0 &&
                              item?.estado_poliza !== "CANCELADA"
                          )
                          .map((policy, key) => (
                            <SwiperSlide key={key}>
                              <CardPay
                                policy={policy}
                                handleOpenDrawer={() =>
                                  handlePaymentDrawer(policy.id_pv)
                                }
                              />
                            </SwiperSlide>
                          ))}
                      </MUICarousel>
                    )}
                  </Grid>
                )
              )
            }

            {!loadingDashboard && dataSinister && dataSinister.length > 0 && (
              <Grid item xs={12} md={6}>
                <Typography className={classes.subtitle}>Siniestros</Typography>
                <MUICarousel
                  classes={{
                    root: classes.carouselComplaint,
                  }}
                  NextIcon={<KeyboardArrowRight />}
                  PrevIcon={<KeyboardArrowLeft />}
                  navButtonsProps={{
                    style: {
                      padding: 0,
                      backgroundColor: "transparent",
                      color: "#979797",
                    },
                  }}
                  navButtonsAlwaysVisible={true}
                  cycleNavigation={false}
                  indicators={false}
                  autoPlay={false}
                >
                  {dataSinister.map((sinister, key) => (
                    <CardComplaints
                      sinister={sinister}
                      position={key + 1}
                      total={dataSinister.length}
                      key={key}
                      handleOpenDrawer={() =>
                        handlePolicyDrawer(sinister.id_pv)
                      }
                    />
                  ))}
                </MUICarousel>
              </Grid>
            )}
          </Grid>
        </div>
        {/* Button Call to Action*/}
        <div style={{ marginTop: 28 }}>
          <Grid container spacing={2}>
            {actions &&
              actions.data &&
              actions.data.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <ButtonAction
                    title={item?.name}
                    onClick={() =>
                      item.pathType === "relative"
                        ? openNewTab(item.url)
                        : item.pathType === "drawer"
                        ? openDrawerAttendance()
                        : openMainUrlNewTab(item.url)
                    }
                    isMenu={item?.isMenu}
                    ariaControls={item?.ariaControls}
                    listMenu={item?.listMenu}
                    icon={
                      item?.icon === "info" ? (
                        <img
                          className={classes.iconSinister}
                          src={ISinister}
                          alt="icon"
                        />
                      ) : item?.icon === "help_outline" ? (
                        <HelpOutline style={{ color: "#fff" }} />
                      ) : (
                        <SvgIcon viewBox="0 0 20 20">
                          {general[item?.icon]}
                        </SvgIcon>
                      )
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </div>
        {/* <ActivityTracker /> */}
        {policy && openDetails && (
          <Drawer
            ModalProps={{
              disableBackdropClick: true,
              hideBackdrop: true,
            }}
            anchor="right"
            open={openDetails}
            classes={{
              paper: classes.filterMenu,
            }}
            onClose={() => {
              searchPendingLinks()
              setOpenDetails(false);
            }}
          >
            <div className={classes.closeBtn}>
              <IconButton
                ariaClose-label="close details"
                onClick={() => {
                  searchPendingLinks()
                  setOpenDetails(!openDetails);
                }}
              >
                <Close style={{ color: "#fff" }} />
              </IconButton>
            </div>
            <QsiseExecuteWrapper
              token={token}
              endpoint={SISE_URL + "/api"}
              qSiseID={ID_Q_SISE_CARTERA}
              theme={config?.theme}
              options={{
                credentials: {},
                variables: {
                  policyId: policy,
                  insuranceCode: dataToken?.user?.insuredCode,
                  codigo_usuario: "0-0-0-0",
                  nro_stro: null,
                },
                qSiseIndex: 0,
              }}
              customComponents={[
                {
                  tag: "payment-link-btn",
                  component: PaymentLinkBTN,
                },
              ]}
            />
          </Drawer>
        )}
        {/* Drawer para detalles de cuotas y pagos */}
        {policy && openPaymentsDetails && (
          <Drawer
            ModalProps={{
              disableBackdropClick: true,
              hideBackdrop: true,
            }}
            anchor="right"
            open={openPaymentsDetails}
            classes={{
              paper: classes.filterMenu,
            }}
            onClose={() => {
              setOpenPaymentsDetails(false);
            }}
          >
            <div className={classes.closeBtn}>
              <IconButton
                ariaClose-label="close details"
                onClick={() => {
                  setOpenPaymentsDetails(!openPaymentsDetails);
                }}
              >
                <Close style={{ color: "#fff" }} />
              </IconButton>
            </div>
            <QsiseExecuteWrapper
              token={token}
              endpoint={SISE_URL + "/api"}
              qSiseID={ID_Q_SISE_FEES_PAYMENTS}
              theme={config?.theme}
              options={{
                credentials: {},
                variables: {
                  policyId: policy,
                  insuranceCode: dataToken?.user?.insuredCode,
                },
                qSiseIndex: 0,
              }}
              customComponents={[
                {
                  tag: "payment-link-btn",
                  component: PaymentLinkBTN,
                },
              ]}
            />
          </Drawer>
        )}
        {/* Drawer para mostrar tipos de servicios disponibles */}
        <Drawer
            ModalProps={{
              disableBackdropClick: true,
              hideBackdrop: true,
            }}
            anchor="right"
            open={openAttendanceInfo}
            classes={{
              paper: classes.filterMenu,
            }}
            onClose={() => {
              setOpenAttendanceInfo(false);
            }}
          >
            <div className={classes.closeBtn}>
              <IconButton
                ariaClose-label="close details"
                onClick={() => {
                  setOpenAttendanceInfo(false);
                }}
              >
                <Close style={{ color: "#fff" }} />
              </IconButton>
            </div>
            <QsiseExecuteWrapper
              token={token}
              endpoint={SISE_URL + "/api"}
              qSiseID={ID_Q_SISE_ATTENDANCES_INSURED}
              theme={config?.theme}
              options={{
                credentials: {},
                variables: {
                  insuredCode: dataToken?.user?.insuredCode
                },
                qSiseIndex: 0,
              }}
              customComponents={[
                {
                  tag: "payment-link-btn",
                  component: PaymentLinkBTN,
                },
              ]}
            />
          </Drawer>
        
      </Container>
    </>
  );
};

//export default Dashboard;
export default React.memo(Dashboard);
