import { createAsyncAction } from 'redux-promise-middleware-actions';
import {
    insuredApi,
    validateJSONResponse,
    exceptionCodeResponse
} from '../utils/urls';
import {
    getItemsPolicyPending,
    getItemsPolicyRejected
} from '../actions/itemsPolicyAction';

export const getSiseItemsPolicyAPI = createAsyncAction(
    'GET_Q_SISE_ITEMSPOLICY',
    async (token, id_pv) => {
        const res = await fetch(`${insuredApi}v1/insured/list/items`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id_pv })
        })
            .then((res) => {
                getItemsPolicyPending();
                return validateJSONResponse(res);
            })
            .catch((err) => {
                getItemsPolicyRejected();
                throw exceptionCodeResponse(err);
            });
        return res;
    }
);
