import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, navigate } from '@reach/router';
import {
    Grid,
    Drawer,
    Avatar,
    Typography,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core';
import {
    ArrowBackIos,
    Dashboard,
    Notifications,
    LocalHospital,
    BusinessCenter,
    PowerSettingsNew,
    Help,
    ChromeReaderMode
} from '@material-ui/icons';
import ListIcon from '@material-ui/icons/List';

import StyledBadge from '../../../notifications/styledBadge';
import BannerDrawer from '../../../../assets/images/bannerDrawerMobile.svg';
import iConnectance from '../../../../assets/images/iConnectance.png';
import { useStyles } from './styles';
import { getLogout } from '../../../../actions/authActions';
import useHasDocuments from '../../../../hooks/useHasDocuments';
import useIsEmployee from '../../../../hooks/useIsEmployee';

import { INSURED_CLIENT } from '../../../../utils/urls';
import AvatarDefault from '../../../../assets/images/avatar_libra_asegurados.png';
import { Can } from '../../../../authorization/abilities';

const DrawerMenuMobile = ({ open, handleDrawerMenuMobile }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo.data);
    const [, setGroupOpened] = useState('');
    const [nameGroupOpened, setNameGroupOpened] = useState(false);
    const hasDocuments = useHasDocuments();
    const isEmployee = useIsEmployee();
    const viewRequest = useMemo(
        () => (hasDocuments || isEmployee ? true : false),
        [hasDocuments, isEmployee]
    );

    const handleLogout = () => {
        dispatch(getLogout());
        navigate('/login');
    };

    const handleOpenGroup = (group) => (evt) => {
        group && setGroupOpened(group);
        group === nameGroupOpened
            ? setNameGroupOpened('')
            : setNameGroupOpened(group);
    };

    const NavLink = (props) => {
        const classes = useStyles();
        return (
            <Link
                {...props}
                getProps={({ isCurrent }) => {
                    // the object returned here is passed to the
                    // anchor element's props
                    return {
                        style: {
                            color: '#333333'
                        },
                        className: isCurrent
                            ? props.className
                            : classes.disabledActiveLink
                    };
                }}
            />
        );
    };

    const renderAvatarProfile = () => {
        if (Object.entries(userInfo).length !== 0) {
            if (userInfo.pictureUrl) {
                return (
                    <Avatar
                        src={userInfo.pictureUrl}
                        className={classes.photoProfile}
                    />
                );
            }
            return (
                <Avatar className={classes.photoProfile}>
                    <Typography
                        component="h4"
                        style={{ fontStyle: 'uppercase' }}
                    >
                        {`${userInfo.firstName?.charAt(
                            0
                        )}${userInfo.lastName?.charAt(0)}`}
                    </Typography>
                </Avatar>
            );
        }
    };

    const renderAvatarProfileLibra = () => {
        if (Object.entries(userInfo).length !== 0) {
            if (userInfo.pictureUrl) {
                return (
                    <Avatar
                        src={userInfo.pictureUrl}
                        className={classes.photoProfile}
                    />
                );
            }
            return (
                <Avatar src={AvatarDefault} className={classes.photoProfile} />
            );
        }
    };

    return (
        <Drawer anchor="left" open={open} className={classes.widthDrawer}>
            <Grid className={classes.containerDrawer}>
                <Grid className={classes.bannerHeader}>
                    <img
                        alt="logo"
                        src={iConnectance}
                        className="iConnectance-logo"
                    />
                    <img
                        alt="banner"
                        src={BannerDrawer}
                        style={{ width: '100%', display: 'none' }}
                    />
                    <IconButton
                        color="inherit"
                        className={classes.logout}
                        onClick={() => handleLogout()}
                    >
                        <PowerSettingsNew style={{ color: '#ffffff' }} />
                    </IconButton>
                    <Grid container className={classes.profile}>
                        <Grid style={{ paddingRight: 20 }}>
                            {INSURED_CLIENT === 'LIBRA'
                                ? renderAvatarProfileLibra()
                                : renderAvatarProfile()}
                        </Grid>
                        <Grid style={{ color: 'white', minWidth: 100 }}>
                            <Typography className={classes.userName}>
                                {Object.entries(userInfo).length != 0 &&
                                    `${userInfo.firstName} ${userInfo.lastName}`}
                            </Typography>
                            <Grid container style={{ alignItems: 'center' }}>
                                <Grid
                                    style={{
                                        width: 5,
                                        height: 5,
                                        borderRadius: 20,
                                        backgroundColor: '#6FCF97'
                                    }}
                                />
                                <Typography className={classes.rol}>
                                    Asegurado
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ position: 'absolute', right: 0 }}>
                            <IconButton onClick={handleDrawerMenuMobile}>
                                <ArrowBackIos style={{ color: 'white' }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.menuOptions}>
                    <Can do="read" on="Dashboard">
                        <NavLink
                            to="dashboard"
                            className={classes.activeLink}
                            onClick={handleOpenGroup('dashboard')}
                        >
                            <ListItem
                                className="list-option"
                                onClick={handleDrawerMenuMobile}
                            >
                                <ListItemIcon>
                                    <Dashboard className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Inicio"
                                    style={{ paddingLeft: 10 }}
                                />
                            </ListItem>
                        </NavLink>
                    </Can>
                    <Can do="read" on="MedicalNetWork">
                        <NavLink
                            to="medical-network"
                            className={classes.activeLink}
                            onClick={handleOpenGroup('medical-network')}
                        >
                            <ListItem
                                className="list-option"
                                onClick={handleDrawerMenuMobile}
                            >
                                <ListItemIcon>
                                    <LocalHospital className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Red Médica"
                                    style={{ paddingLeft: 10 }}
                                />
                            </ListItem>
                        </NavLink>
                    </Can>
                    <Can do="read" on="Notifications">
                        <NavLink
                            to="notifications"
                            className={classes.activeLink}
                            onClick={handleOpenGroup('notifications')}
                        >
                            <ListItem
                                className="list-option"
                                onClick={handleDrawerMenuMobile}
                            >
                                <ListItemIcon>
                                    <StyledBadge
                                        variant="dot"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        }}
                                        overlap="circular"
                                        color="secondary"
                                    >
                                        <Notifications className={classes.icon} />
                                    </StyledBadge>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Notificaciones"
                                    style={{ paddingLeft: 10 }}
                                />
                            </ListItem>
                        </NavLink>
                    </Can>
                    
                    {/* <Can do="read" on="FAQ">
                        <NavLink
                            to="faq"
                            className={classes.activeLink}
                            onClick={handleOpenGroup('faq')}
                        >
                            <ListItem
                                className="list-option"
                                onClick={handleDrawerMenuMobile}
                            >
                                <ListItemIcon>
                                    <Help className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Ayuda"
                                    style={{ paddingLeft: 10 }}
                                />
                            </ListItem>
                        </NavLink>
                    </Can> */}
                    <Can do="read" on="News">
                        <NavLink
                            to="news"
                            className={classes.activeLink}
                            onClick={handleOpenGroup('news')}
                        >
                            <ListItem
                                className="list-option"
                                onClick={handleDrawerMenuMobile}
                            >
                                <ListItemIcon>
                                    <ChromeReaderMode className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Noticias"
                                    style={{ paddingLeft: 10 }}
                                />
                            </ListItem>
                        </NavLink>
                    </Can>
                </Grid>
            </Grid>
        </Drawer>
    );
};

export default DrawerMenuMobile;
