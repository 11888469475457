import {
    GET_FILES_PROPOSAL_PRODUCT_FULFILLED,
    GET_FILES_PROPOSAL_PRODUCT_PENDING,
    GET_FILES_PROPOSAL_PRODUCT_REJECTED,
  } from "../actions/types";
  
const initialState = {
  data: [],
  loading: false,
  error: false,
};
    
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FILES_PROPOSAL_PRODUCT_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_FILES_PROPOSAL_PRODUCT_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_FILES_PROPOSAL_PRODUCT_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};