import {
    getAgentListAPI,
    getPostalCodeAPI,
    getStateListAPI,
    sendMailAPI,
    getFiscalConditionAPI,
    getDocumentsProposalListAPI,
    getFilesProposalProductAPI
} from '../sevices/quotationAgentService';

import { 
    RESET_STATE_LIST, 

  } from "../actions/types";

export const getAgentList = (token) => async (dispatch) => {
    await dispatch(getAgentListAPI(token, dispatch))
}; 

export const getStateList = (token) => async (dispatch) => {
    await dispatch(getStateListAPI(token, dispatch))
}; 

export const resetStateList = () => (dispatch) => {
    dispatch({ type: RESET_STATE_LIST });
};  
 
export const getPostalCode = (token,queryPrams) => async (dispatch) => {
    await dispatch(getPostalCodeAPI(token,queryPrams))
}

export const getFiscalCondition = (token) => async (dispatch) => {
    await dispatch(getFiscalConditionAPI(token));
}

export const getDocumentsProposalList = () => async (dispatch) => {
    await dispatch(getDocumentsProposalListAPI());
}

export const getFilesProposalProduct = () => async (dispatch) => {
    await dispatch(getFilesProposalProductAPI());
}

export const sendMail = (token,mail) => async (dispatch) => {
    await dispatch(sendMailAPI(token,mail)); 
};

