import {
    GET_RISK_TYPE_PENDING,
    GET_RISK_TYPE_FULFILLED,
    GET_RISK_TYPE_REJECTED
} from '../actions/types';

const initialState = {
    data: [],
    loading: false,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_RISK_TYPE_PENDING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case GET_RISK_TYPE_FULFILLED:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false
            };
        case GET_RISK_TYPE_REJECTED:
            return {
                ...state,
                data: [],
                loading: false,
                error: true
            };
        default:
            return state;
    }
}
