import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },

    title: {
        fontSize: 16,
        color: '#fff',
        ['@media (max-width:601px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: 16,
        },
    },
    commissionInformation: {
        marginLeft: 0,
        padding: 15,
        overflowX: 'hidden',
        margin: 0,
        padding: 0
    },
    header: {
        marginLeft: 0,
        padding: 25,
        overflowX: 'hidden',
        background: theme.palette.primary.main,
        color: '#fff'
    },
    btnToAccept: {
        height: 40,
        padding: '25px 50px',
        marginTop: 30,
        fontWeight: 'bold',
        background: theme.palette.primary.main
    },
    hiddenMenuItem: {
        display: 'none',
    },
    textOverflowNameFile: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'uppercase'
    },
    alignOnline: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon: {
        color: theme.palette.primary.main,
        width: 15,
        paddingRight: 5
    },
    pointer: {
        cursor: 'pointer'
    },
    import: {
        paddingTop: 20,
        paddingLeft: 30,
        paddingBottom: 30
    },
    importStyle: {
        fontWeight: 'bold',
        color: '#525252'
    },
    payoutStyle: {
        color: '#979797',
        fontStyle: 'italic'
    },
    paddingR30: {
        paddingRight: 30
    },
    button: {
        width: '70%',
        boxShadow: 'none',
        background: '#FBFBFF !important',
        color: `${theme.palette.secondary.main} !important`,
        borderRadius: 18,
        height: 64,
        fontWeight: 500,
        fontSize: 13
    },
    btnDesc: {
        fontSize: 14,
        color: '#4B4B4B',
        marginLeft: 20
    },
    footer: {
        padding: '0 35px !important',
        marginBottom: 20
    },
    textGray: {
        fontSize: 14,
        color: '#4B4B4B'
    },
    textPurple: {
        fontSize: 14,
        color: theme.palette.primary.main
    },
    msgError: {
        textTransform: 'uppercase',
        marginTop: 20,
        fontSize: 11,
        color: '#dc3545',
        textAlign: 'center'
    },
    divError: {
        margin: '0 auto',
        marginTop: 10
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: 3,
        marginLeft: -12,
    },
    mainSelect: {
        color: theme.palette.primary.main,
        marginTop: '15px',
        width: '100%',
    },
    minHeight: {
        minHeight: '85vh'
    },
    buttonContainer:{
        textAlign: "right"
    },
    buttonNextStep:{
        width: "140px",
        height: "44px",
        fontSize: "14px",
        backgroundColor: theme.palette.primary.main || '#4C1DB7' + " !important",
        color:"white !important",
        fontWeight: "bold !important",
        marginTop: "35px !important",
        position: "relative",
        right: "0px",
        '&:hover':{
            backgroundColor: theme.palette.primary.main || '#4C1DB7' + " !important",
        }
    },
    formControlSize: {
        width: '100%'
    },
    inputDate: {
        marginTop: '10px'
    },
    success: {
        fontSize: '14px',
        paddingTop: '15px'
    },
    buttonCloseDrawer:{
        width: "75px",
        height: "35px",
        fontSize: "12px",
        backgroundColor: '#ff3333 !important',
        color:"white !important",
        fontWeight: "bold !important",
        marginTop: "10px !important",
        position: "relative",
        right: "0px",
        '&:hover':{
            backgroundColor: theme.palette.primary.main || '#4C1DB7' + " !important",
        }
    },
    dialogInfo: {
        border: "1px solid #ff3333",
        borderRadius: "5px",
        padding: "5px"
    },

    blockInfo: {
        padding: 8,
        // padding: "10px 0px",
        background: "#FFFFFF",
        // padding: "1rem 1.5rem",
        borderRadius: "10px",
      },

      titleInfoMinor: {
        color: "#474747",
        fontSize: 14,
        fontWeight: 400,
      },

      blockInfoUnderlined: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid rgb(196, 196, 196)",
        margin: "0px 16px",
        padding: "0.5rem",
        color: "#777778",
        fontSize: 14,
        fontWeight: 400,
      },

      formElStyles: {
        padding: 16,
      },

      table: {
        minWidth: 350,
      }
}));