import { Badge, withStyles } from "@material-ui/core";

const StyledBadge = withStyles((theme) => ({
  colorSecondary: {
    backgroundColor: theme.palette.secondary.dark
  },
  anchorOriginBottomLeftCircle: {
    left: '15%',
    bottom: '25%'
  },
  dot: {
    height: 8,
    minWidth: 8
  }
}))(Badge);

export default StyledBadge;