import {
    GET_ACCOUNT_TYPES,
    GET_ACCOUNT_TYPES_FULFILLED,
    GET_ACCOUNT_TYPES_REJECTED,
    GET_ACCOUNT_TYPES_PENDING
  } from "../actions/types";

  const initialState = {
    data: [],
    loading: false,
    error: false,
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_ACCOUNT_TYPES_PENDING:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case GET_ACCOUNT_TYPES_FULFILLED:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case GET_ACCOUNT_TYPES_REJECTED:
        return {
          ...state,
          data: [],
          loading: false,
          error: true,
        };
      case GET_ACCOUNT_TYPES:
        return {
          ...state,
          data: [],
        };
      default:
        return state;
    }
  }
