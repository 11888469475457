import {
    GET_AGENT_BANK_INFO_PENDING,
    GET_AGENT_BANK_INFO_FULFILLED,
    GET_AGENT_BANK_INFO_REJECTED,
    UPDATE_AGENT_BANK_INFO_PENDING,
    UPDATE_AGENT_BANK_INFO_FULFILLED,
    UPDATE_AGENT_BANK_INFO_REJECTED,
    CREATE_AGENT_BANK_INFO_PENDING,
    CREATE_AGENT_BANK_INFO_FULFILLED,
    CREATE_AGENT_BANK_INFO_REJECTED
} from "../actions/types";

const initialState = {
    data: [],
    loading: false,
    error: false,
};

const agentBankReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AGENT_BANK_INFO_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_AGENT_BANK_INFO_REJECTED:
            return {
                ...state,
                data: [],
                error: true,
                loading: false,
            };
        case GET_AGENT_BANK_INFO_FULFILLED:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };
        case UPDATE_AGENT_BANK_INFO_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case UPDATE_AGENT_BANK_INFO_FULFILLED: {
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };
        }
        case UPDATE_AGENT_BANK_INFO_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case CREATE_AGENT_BANK_INFO_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case CREATE_AGENT_BANK_INFO_FULFILLED: {
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };
        }
        case CREATE_AGENT_BANK_INFO_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
}

export default agentBankReducer;