import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    modal: {
      outline: 0,
        '&:focus': {
            outline: 'none !important'
        }
    },
    gridModal: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        width: 400,
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 15,
        padding: '30px 55px',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        [theme.breakpoints.down("sm")]: {
          width: 300,
        },
    },
    title: {
      paddingTop: 20,
      color: theme.palette.secondary.main
    },
    text: {
      fontSize: 17,
      color: '#4B4B4B'
    }
}))
