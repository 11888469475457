import { getRiskTypeFromUrl } from './middlewares';

export const createNamedWrapperQuoteReducer = (reducerFunction, reducerName) => {
    return (state, action) => {
        let { riskType } = action;
        if (typeof riskType === 'undefined') {
            riskType = getRiskTypeFromUrl() && getRiskTypeFromUrl().toUpperCase();
        }

        const isInitializationCall = state === undefined;

        if (riskType !== reducerName && !isInitializationCall) return state;

        return reducerFunction(state, action);
    };
};
