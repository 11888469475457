import React from "react";
import moment from "moment";
import { Typography, CircularProgress } from "@material-ui/core";
import { CalendarTodayOutlined, MotorcycleOutlined } from "@material-ui/icons";
import StyledProgress from "../../../styledProgress";

import { general } from "../../../../utils/icons";
import { Skeleton } from "@material-ui/lab";

const TripReportCard = ({ classes, policy, handleSelect, isLoaded, refetching = true }) => {
  const hourToMinutes = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    return Number(seconds) / 100 + Number(minutes) + Number(hours) * 60;
  };

  const getPercentage = (v1, v2) => {
    const formattedV1 = hourToMinutes(v1);
    const formattedV2 = hourToMinutes(v2);

    if (formattedV1 > formattedV2) {
      return 100;
    }

    const result = Math.floor((formattedV1 / formattedV2) * 100);
    return result;
  };

  const statusColors = {
    28: "orange",
    37: "#121212",
    36: "#219653",
  };

  const getLabelByStatus = (status) => {
    switch (status) {
      case "28":
        return "FUERA DE ALCANCE GPS";
      case "37":
      case "41":
      case "21":
        return "VEHICULO DETENIDO";
      case "36":
        return "VIAJE EN CURSO";
      default:
        return null;
    }
  };

  const getFormattedDuration = (duration) => {
    const [hour, minutes] = duration.split(":");

    if (minutes === "00") {
      return (
        <span>
          {`${hour}:${minutes}`}
          <small>hs</small>
        </span>
      );
    }

    return (
      <span>
        {`${hour}`}
        <small>hs</small>
        {`${minutes}`}
        <small>min</small>
      </span>
    );
  };

  const isOnGoing = (status) => {
    return status == 36;
  };

  const fetchingPlaceholder = (
    <div className={classes.fetchingPlaceholder}>
      <Skeleton animation="wave" width="100%" height={350} style={{ transform: "scale(1)", borderRadius: '0px 0px 15px 15px', backgroundColor: 'rgba(0, 0, 0, 0.005)' }} />
      <p>Cargando...</p>
    </div>
  );

  return (
    <div
      className={`${classes.orangeCard} ${refetching ? 'refetching' : ''}`}
      onClick={() => handleSelect(policy)}
    >
      <div className={classes.orangeCardHeader}>
        <div>
          {policy.isCar ? general.car : <MotorcycleOutlined />}
          <Typography>{policy.patent}</Typography>
        </div>
        {refetching ? (
          <CircularProgress size={16} />
        ) : (
          <div>
            <Typography
              style={{
                color: isOnGoing(policy.status[0].code)
                  ? "#219653"
                  : statusColors[policy.status[0].code],
              }}
            >
              {getLabelByStatus(policy.status[0].code)}
            </Typography>
            <span
              style={{
                borderColor: isOnGoing(policy.status[0].code)
                  ? "#219653"
                  : statusColors[policy.status[0].code],
              }}
            />
          </div>
        )}
      </div>
      {isLoaded ? (
        <>
          <div className={classes.orangeCardBody}>
            <div>
              <CalendarTodayOutlined
                color="primary"
                style={{ fontSize: 12.5 }}
              />
              <Typography>
                Periodo {moment(policy.startDate).format("DD/MM/YYYY")} al{" "}
                {moment(policy.endDate).format("DD/MM/YYYY")}
              </Typography>
            </div>
            <div>
              <span>
                80<small>hs</small>
              </span>
              <StyledProgress
                value={getPercentage(policy.travel.strDuration, `80:00:00`)}
              />
              <div>
                {getFormattedDuration(policy.travel.strDuration)}
                <small>
                  Actualizado{" "}
                  {moment(policy.travel.last_updated).format(
                    "DD/MM/YYYY, hh:mm A"
                  )}
                </small>
              </div>
            </div>
            <div>
              <Typography>Poliza {policy.policy.number}</Typography>
            </div>
          </div>
          <div className={classes.orangeCardFooter}>
            <Typography>
              Consumo{" "}
              <span>
                {new Intl.NumberFormat("es-AR", {
                  style: "currency",
                  currency: "ARS",
                  maximumFractionDigits: 0,
                }).format(policy.travel.consumption)}
              </span>
            </Typography>
            <Typography>
              Ahorro{" "}
              <span>
                {new Intl.NumberFormat("es-AR", {
                  style: "currency",
                  currency: "ARS",
                  maximumFractionDigits: 0,
                }).format(policy.travel.savings)}
              </span>
            </Typography>
          </div>
        </>
      ) : (
        fetchingPlaceholder
      )}
    </div>
  );
};

export default TripReportCard;
