import React from 'react';
import { Grid } from '@material-ui/core';

const News = ({ children }) => {
    return (
        <Grid container style={{ marginTop: '1rem' }}>
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    );
};

export default News;
