import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  orangeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    width: '100%',
    marginBottom: 30
  },
  orangeMenu: {
    width: 450,
    borderTopLeftRadius: 10,
    backgroundColor: '#F2F2F2',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  orangeMenuHeader: {
    backgroundColor: '#4B4B4B',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 20px 20px 40px',
    alignItems: 'center',
    color: '#fff',
    '& p': {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '20px',
      color: '#fff'
    },
    '& button': {
      color: '#fff'
    }
  },
  orangeMenuBody: {
    backgroundColor: '#F2F2F2',
    padding: '28px 48px',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    alignItems: 'center'
  },
  orangeTopbar: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > div:first-child': {
      display: 'flex',
      alignItems: 'center',
      gap: 12
    },
    '& button': {
      height: 64,
      borderRadius: 18,
      backgroundColor: theme.palette.variants && theme.palette.variants.variant1,
      '& svg:first-child': {
        marginRight: 8,
        borderRadius: '50%',
        padding: '9px 11px',
        backgroundColor: theme.palette.variants && theme.palette.variants.variant3
      },
      '& svg:last-child': {
        marginLeft: 8
      },
      '&:hover': {
        backgroundColor: theme.palette.variants && theme.palette.variants.variant1,
      }
    }
  },
  orangeList: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    gap: 18,
    width: '100%',
    '& > div': {
      width: '100%',
      '& > div:nth-child(2)': {
        '& > div': {
          gridTemplateColumns: ({ count }) => `repeat(${count}, minmax(240px, 310px))`
        }
      }
    },
    '& > div.swiper-left-arrow:first-child, & > div.swiper-right-arrow:last-child': {
      alignSelf: 'center',
      cursor: 'pointer',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'background-color .15s ease-in-out',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
      }
    }
  },
  orangeCard: {
    backgroundColor: '#fff',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    cursor: 'pointer',
    maxWidth: 310,
    '&.details': {
      maxWidth: 433
    },
    '&.refetching': {
      pointerEvents: 'none'
    }
  },
  orangeCardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px',
    '& > div:first-child': {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      width: '100%',
      '& > p': {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: 0.1,
        color: '#4B4B4B'
      }
    },
    '& > div:last-child': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      '& p': {
        fontSize: 8,
        fontWeight: 500,
        lineHeight: '11.5px',
        letterSpacing: 1,
        textAlign: 'center'
      },
      "& > span": {
        border: "6px solid",
        borderRadius: '50%',
        width: 4,
        display: 'block',
        height: 4,
        marginRight: 8
      },
      '& button': {
        fontSize: 10,
        letterSpacing: 1.25,
        lineHeight: '16px',
        fontWeight: 500
      }
    }
  },
  orangeCardBody: {
    borderTop: '0.5px solid #C4C4C4',
    borderBottom: '0.5px solid #C4C4C4',
    padding: '12px 20px',
    display: 'flex',
    flexDirection: 'column',
    '& > div:nth-child(1)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 7,
      '& p': {
        fontSize: 11,
        fontWeight: 400,
        lineHeight: '14px',
        color: '#4B4B4B'
      }
    },
    '& > div:nth-child(2)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      marginTop: 20,
      '& > span': {
        position: 'absolute',
        top: -12,
        display: 'flex',
        height: 36,
        width: 36,
        backgroundColor: theme.palette.primary && theme.palette.primary.main,
        color: '#fff',
        fontSize: 14,
        fontWeight: 400,
        borderRadius: '50%',
        textAlign: 'center',
        zIndex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& small': {
          display: 'block'
        }
      },
      '& > div:last-child': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '& > span': {
          color: '#000000',
          fontSize: 26,
          lineHeight: '25px',
          fontWeight: 400,
          fontFamily: 'Work Sans',
          '& > small': {
            color: '#a4a4a4',
            fontSize: 14,
            lineHeight: '20.85px',
            letterSpacing: 0.26,
            marginLeft: 4,
            marginRight: 2,
          }
        },
        '& > small': {
          fontSize: 10,
          lineHeight: '24px',
          letterSpacing: 0.15,
          color: '#a4a4a4',
          textAlign: 'center'
        }
      }
    },
    '& > div:nth-child(3)': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 30,
      '& > p': {
        color: '#4B4B4B',
        fontSize: 11,
        lineHeight: '12px'
      }
    }
  },
  orangeCardFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 20px',
    '& p': {
      fontSize: 11,
      lineHeight: '11px',
      color: '#4B4B4B',
      fontWeight: 600,
      '& span': {
        fontSize: 15,
        lineHeight: '24px',
        letterSpacing: 0.5,
        color: theme.palette.primary && theme.palette.primary.main,
        marginLeft: 5,
        fontWeight: 700
      }
    }
  },
  orangeCardDuration: {
    display: 'flex',
    alignItems: 'center',
    gap: 40,
    justifyContent: 'center',
    marginTop: 12,
    marginBottom: 30,
    '& > span': {
      border: '0.5px solid #E7E7E7',
      height: 56,
      display: 'block'
    },
    '& > p': {
      fontSize: 11,
      lineHeight: '14px',
      color: '#4B4B4B',
      textAlign: 'center',
      '& span': {
        fontSize: 18,
        lineHeight: '24px',
        letterSpacing: 0.15,
        fontWeight: 500,
        color: theme.palette.primary && theme.palette.primary.main,
      }
    }
  },
  orangeMap: {
    position: 'relative',
    flex: 1,
    /* '& img': {
      width: '100%',
      height: '100%',
      '&.disabled': {
        filter: 'grayscale(100%)',
        pointerEvents: 'none'
      }
    }, */
    '& > div.map-overlay': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      zIndex: 1000,
      '& p': {
        color: '#fff',
        marginTop: 16,
        padding: '0px 20px',
        textAlign: 'center'
      }
    }
  },
  orangeMapFooter: {
    padding: '15px 28px 5px 28px',
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    '& > p': {
      fontSize: 11,
      lineHeight: '14px',
      color: '#4B4B4B'
    },
    '& > span': {
      fontSize: 8,
      lineHeight: '24px',
      color: '#4B4B4B',
      float: 'right'
    }
  },
  orangeTable: {
    width: '100%',
    display: 'flex',
    borderTop: '0.5px solid #C4C4C4',
    flexDirection: 'column',
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    '& > div': {
      display: 'flex',
      padding: '20px 0px',
      color: '#4B4B4B',
      backgroundColor: '#fff',
      '& span': {
        width: '33.33%',
        flex: 1,
        textAlign: 'center',
        fontSize: 11,
        lineHeight: '14px',
        '&:first-child': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      },
      '&:first-child': {
        color: theme.palette.primary && theme.palette.primary.main,
        padding: '10px 0px'
      },
      '&:last-child': {
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
      }
    }
  },
  orangeRow: {
    backgroundColor: '#F7F7F7 !important',
    '& span': {
      color: theme.palette.primary && theme.palette.primary.main,
      fontWeight: 600
    }
  },
  fetchingPlaceholder: {
    height: 350,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '& p': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  dialogCloseBtn: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))