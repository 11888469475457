import React from "react";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { cardPayStyle } from "./style";
import { general } from "../../../../utils/icons";
import { INSURED_CLIENT } from "../../../../utils/urls";
import formatNumber from "../../../../utils/formatNumber";

const CardPay = ({ policy, handleOpenDrawer }) => {


  const statusMatch = {
    PENDIENTE: "#219653",
    VENCIDA: "#EB5757",
    ACTIVA: "#6FCF97",
    DEFAULT: "#000000",
    OCULTA: "#000",
  };

  const estado = `${
    policy?.importe_vencido === 0 && policy?.proxima_cuota_importe === 0
      ? "oculta"
      : policy?.importe_vencido === 0 && policy?.proxima_cuota_importe > 0
      ? "activa"
      : policy?.importe_vencido > 0 && policy?.proxima_cuota_importe > 0
      ? "vencida"
      : policy?.importe_vencido > 0 && policy?.proxima_cuota_importe === 0
      ? "vencida"
      : "default"
  }`.toUpperCase();


  const classes = cardPayStyle({
    client: INSURED_CLIENT,
    colorStatus: statusMatch[estado],
  });

  /**
   * @name parseNumber
   * @description get a number, process and return with format $NN.NN,DD
   * @param {*} number
   * @returns string
   */
  const parseNumber = (number) => {
    const parsed = parseFloat((number ? number : 0).toString()).toFixed(2);
    return formatNumber.new(parsed, "$");
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.header}>
          <Grid container justifyContent="space-between">
            <div className={classes.status}>
              <Typography>
                <span />
                {estado === "OCULTA" ? null : estado || 'Sin estado'}
              </Typography>
            </div>
          </Grid>
        </div>
        <Grid container>
          <Grid item xs={12}>
            {/* <Typography className={classes.description}>
              {`Número de póliza: ${policy.nro_poliza}`}
            </Typography> */}
            <Typography className={classes.title}>
              {policy?.proxima_cuota_vencimiento
                ? moment(policy?.proxima_cuota_vencimiento).format("DD/MM/YYYY")
                : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className={clsx({
                [classes.price]: true,
                [classes.stateColor]: true,
              })}
            >
              {estado === "OCULTA"
                ? "N/A"
                : policy?.estado === "Vencida"
                ? parseNumber(policy?.importe_vencido)
                : parseNumber(policy?.proxima_cuota_importe)}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleOpenDrawer()}
              classes={{
                root: classes.button,
              }}
            >
              VER DETALLE
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardPay;
