import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '450px',
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        position: 'absolute',
        right: '-120px'
    },
    rootSubheaderWrapper: {
        overflow: 'auto'
    },
    rootSubheader: {
        borderRadius: '10px 0px',
    },
    rootTypo: {
        color: theme.palette.primary.main
    },
    inline: {
        display: 'inline',
    },
    rootBadge: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    shapeBadge: {
        backgroundColor: theme.palette.primary.main,
        width: 40,
        height: 40,
    },
    shapeCircleBadge: {
        borderRadius: '50%',
    },
    rootNotif: {
        '&:hover': {
            background: theme.palette.variants && theme.palette.variants.variant1,
            cursor: 'pointer'
        },
        maxHeight: 75,
        overflowY: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    rootNotifUnread: {
        '&:hover': {
            background: theme.palette.variants && theme.palette.variants.variant1,
            cursor: 'pointer'
        },
        backgroundColor: theme.palette.variants && theme.palette.variants.variant3,
        maxHeight: 75,
        overflowY: 'hidden',
    },
    hover: {
        '&:hover': {
            background: theme.palette.variants && theme.palette.variants.variant1,
            cursor: 'pointer'
        }
    },
    drawerPaper: {
        width: 450,
        borderRadius: 10,
        zIndex: '9000 !important',
        ['@media (max-width:426px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
            zIndex: '9000 !important'
        },
        ['@media (max-width:599px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: 0,
        }
    }
}));