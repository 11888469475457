import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { useStyledProgress } from './style'

const StyledProgress = ({ value = 0 }) => {
  const classes = useStyledProgress();
  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={200}
        thickness={1}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle
        }}
        value={value}
        size={200}
        thickness={2}
      />
    </div>
  )
}

export default StyledProgress