import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    styledLabel:{
        top: '2px',
        fontWeight: 400,
        color: '#6716F5',
        fontSize: '18px',
        display:'flex'
    },
    addFeatureObject:{
        position: 'relative !important',
        left: '3px !important',
        cursor: 'pointer !important',
        border:'1px dashed !important',
        borderRadius: '5px !important',
        boxSizing: 'border-box !important',
        fontWeight: '400 !important',
        fontSize: '14px !important',
        lineHeight: '16px !important',
        color: '#828282 !important',
        height: '85px !important',
        width: '312px !important',
        textAlign: 'left !important',
        marginTop: '23px'
    },
    addIcon:{
        fontSize: '40px !important',
        color:'#4C1DB7',
        marginLeft: '60px !important'
    },
    circleIconObject:{
        width: '50px !important',
        height: '50px !important',
        display: 'flex',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        background: '#E4DAF9 !important',
        borderRadius: '10px !important',
        marginLeft:'23px'
    },
    featureObjectContainer:{
        width: '100% !important',
        minHeight: '85px !important',
        display: 'flex',
        alignItems: 'center !important',
        backgroundColor: '#F4F4FA',
        borderBottom: 'solid 1px #afafaf'
    },
    itemContainer:{
        borderColor: theme.palette.primary.main || '#4C1DB7' + " !important",
        padding:'10px'
    },
    styledLabelInput:{
        fontSize: '0.8rem',
        position: 'absolute',
        top: '2px',
        left: '2px',
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)'
    },
    infoRowObject:{
        textAlign: 'right !important',
        width: '100% !important',
        display: 'flex',
        flexWrap: 'wrap'
    },
    actionsIncos:{
        height: '50px !important',
        display: 'flex',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        background: 'rgba(0,0,0,0)'
    },
}));