import { createTheme } from "@material-ui/core/styles";

import {
  primary,
  secondary,
  background,
  neutral,
  success,
  error,
  warning,
  variants,
} from "./paletteApp";

const themeDefault = createTheme({
  palette: {
    error,
    success,
    neutral,
    primary,
    variants,
    secondary,
    warning,
    background,
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 15,
      },
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiCard: {
      root: {
        overflow: "visible",
        boxShadow: "none",
      },
    },
  },
});

export default themeDefault;
