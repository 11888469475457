import { createAsyncAction } from 'redux-promise-middleware-actions';
import {
    urlBaseApi,
    validateJSONResponse,
    exceptionCodeResponse,
    quotationUrlApi,
    urlList
} from '../utils/urls';
import {
    GET_AGENT_LIST,
    GET_STATE_LIST,
    SEND_MAIL,
    GET_POSTAL_CODE,
    GET_FISCAL_CONDITION,
    GET_DOCUMENTS_PROPOSAL,
    GET_FILES_PROPOSAL_PRODUCT
} from '../actions/types';

import { getToken } from "../utils/index";

export const getAgentListAPI = createAsyncAction(
    GET_AGENT_LIST,
    async (token) => {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };
        const res = await fetch(
            `${urlBaseApi}/quotationAgent/agentList`,
            options
        )
            .then((response) => validateJSONResponse(response))
            .catch((error) => {
                throw exceptionCodeResponse(error);
            });
        return res;
    }
);

export const getStateListAPI = createAsyncAction(
    GET_STATE_LIST,
    async (token) => {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };
        const res = await fetch(`${urlBaseApi}/province/stateList`, options)
            .then((response) => validateJSONResponse(response))
            .catch((error) => {
                throw exceptionCodeResponse(error);
            });
        return res;
    }
);

export const getPostalCodeAPI = createAsyncAction(
    GET_POSTAL_CODE,
    async (token, queryPrams) => {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };
        const res = await fetch(
            `${urlBaseApi}/province/postalCode${queryPrams}`,
            options
        )
            .then((response) => validateJSONResponse(response))
            .catch((error) => {
                throw exceptionCodeResponse(error);
            });
        return res;
    }
);

export const getFiscalConditionAPI = createAsyncAction(
    GET_FISCAL_CONDITION,
    async (token) => {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };
        const res = await fetch(
            `${urlBaseApi}/quotationAgent/fiscalCondition`,
            options
        )
            .then((response) => validateJSONResponse(response))
            .catch((error) => {
                throw exceptionCodeResponse(error);
            });
        return res;
    }
);

export const sendMailAPI = createAsyncAction(
    SEND_MAIL,
    async (token, bodyReq) => {
        const headers = { Authorization: `Bearer ${token}` };
        let form = new FormData();
        form.append('attachments', bodyReq.attachments);
        form.append('to', bodyReq.to);
        form.append('subject', bodyReq.subject);
        form.append('message', bodyReq.message);
        const body = JSON.stringify(bodyReq);
        try {
            const opctions = {
                method: 'POST',
                headers,
                body: form
            };

            const response = await fetch(
                `${quotationUrlApi}/contact/sendEmail`,
                opctions
            );
            return response.data;
        } catch (error) {
            throw exceptionCodeResponse(error);
        }
    }
);

export const getDocumentsProposalListAPI = createAsyncAction(
    GET_DOCUMENTS_PROPOSAL,
    async () => {
        const token = getToken();
        const headers = { Authorization: `Bearer ${token}` };
        const options = {
            method: 'GET',
            headers
        };
        const res = await fetch(
            `${urlList}listCollection/filesProducts`,
            options
        )
            .then((response) => validateJSONResponse(response))
            .catch((error) => {
                throw exceptionCodeResponse(error);
            });
        return res;
    }
);

export const getFilesProposalProductAPI = createAsyncAction(
    GET_FILES_PROPOSAL_PRODUCT,
    async () => {
        const token = getToken();
        const headers = { Authorization: `Bearer ${token}` };
        const options = {
            method: 'GET',
            headers
        };
        const res = await fetch(
            `${urlList}listCollection/filesProposalProduct`,
            options
        )
            .then((response) => validateJSONResponse(response))
            .catch((error) => {
                throw exceptionCodeResponse(error);
            });
        return res;
    }
);
