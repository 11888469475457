import {
  GET_INSURANCE_PENDING,
  GET_INSURANCE_FULFILLED,
  GET_INSURANCE_REJECTED,
  GET_INSURANCE_ID_PENDING,
  GET_INSURANCE_ID_FULFILLED,
  GET_INSURANCE_ID_REJECTED,
  RESET_INSURANCE,
} from "../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INSURANCE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_INSURANCE_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_INSURANCE_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };

    case GET_INSURANCE_ID_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_INSURANCE_ID_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_INSURANCE_ID_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    case RESET_INSURANCE:
      return {
        ...state,
        filteredData: state.data,
      };
    default:
      return state;
  }
}
