import {
    GET_PAYMENT_METHODS_PENDING,
    GET_PAYMENT_METHODS_FULFILLED,
    GET_PAYMENT_METHODS_REJECTED,
    FILTER_PAYMENT_METHODS,
    RESET_PAYMENT_METHODS
} from '../actions/types';
//import { getFilterCondition } from '../components/filter/filterConditions';

const initialState = {
    data: [],
    filteredData: [],
    loading: false,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PAYMENT_METHODS_PENDING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case GET_PAYMENT_METHODS_FULFILLED:
            return {
                ...state,
                data: action.payload,
                filteredData: action.payload,
                loading: false,
                error: false
            };
        case GET_PAYMENT_METHODS_REJECTED:
            return {
                ...state,
                data: [],
                loading: false,
                error: true
            };
        case FILTER_PAYMENT_METHODS:
            const { filters, rules } = action.payload;
           /*  const filtered = state.data.filter((d) => {
                const conditions = Object.keys(filters).map((filter) =>
                    getFilterCondition(
                        filters[filter],
                        d[rules[filter].filterKey],
                        rules[filter].comparationType
                    )
                );

                return conditions.every((c) => c);
            });

            return {
                ...state,
                filteredData: filtered
            }; */
            return {
                ...state,
            };
        case RESET_PAYMENT_METHODS:
            return {
                ...state,
                filteredData: state.data
            };
        default:
            return state;
    }
}
