const diccionaryStealingForm = {
    withViolence: "Hubo empleo de violencia, amenaza y/o fuerza?",
    callEmergency_V: "¿Llamó al 911? ¿Solicitó ayuda de alguien? En caso afirmativo, aporte por favor los datos de dicha/as persona/as",
    documentation_V: "¿En el interior del vehículo había documentación y/o elementos de valor cuando fue robada? Mencione cuáles.",
    witnesses_V: "¿Posee testigos del hecho? En caso afirmativo, aporte sus datos de contacto y nombre completo",
    cameras_V: "¿En el lugar de ocurrencia hay cámaras de seguridad públicas o privadas? Mencione dónde y si fueron solicitadas",
    carUse_V: "¿Qué uso le daba al vehículo?",
    occupation_V: "Ocupación",
    name_V: "Apellido y Nombre",
    typeNumberDocument_V: "Tipo y numero de documento",
    homeAddress_V: "Domicilio real",
    workAddress_V: "Domicilio laboral",
    mail_V: "E-mail",
    phoneNumber_V: "Teléfono celular",
    delayReasons_V: "Si se demoró en realizar la denuncia policial y/o la del siniestro ante esta aseguradora, indique por favor los motivos de dicha demora.",
    insuredOtherCompanies_V: "¿El vehículo estuvo asegurado en otras compañías? ¿Cuáles?",
    kilometres_V: "¿Qué kilometraje aproximadamente recorrido tenía al momento del hecho?",
    video_V: "¿Posee video del hecho? En caso afirmativo, apórtelo",
    dateLeftVehicle: "Fecha y hora de cuándo dejó el vehículo",
    dateLookVehicle: "Fecha y hora de cuándo lo fue a buscar",
    placeAfterLeavingVehicle: "¿Donde se dirigió después de dejar el vehículo?",
    callEmergency: "¿Llamó al 911? ¿Solicitó ayuda de alguien en la vía pública?",
    inquiriesInArea: "A) ¿Realizó averiguaciones por la zona? ¿De qué tipo?",
    dateSatelliteRecovery: "B) Si la unidad contaba con recuperación satelital, ¿Qué día y hora exacta dio aviso activando dicho sistema?",
    recoveryMechanism: "C) ¿Tenía el conductor conocimiento que la unidad estaba equipada con este mecanismo de recuperación?",
    recoveryMechanismActivation: "D) ¿Conocía el conductor el procedimiento que debía llevar a cabo para activarlo?",
    parkedPublicRoad: "¿Estaba estacionado en la vía pública?",
    alarm: "¿El vehículo poseía alarma y/u otro dispositivo de seguridad?",
    consultedTrafficControlCompany: "¿Consultó con la empresa de control de tránsito (grúas) o en la playa municipal?",
    securityMeasures: "¿Con qué medidas de seguridad fue dejado?",
    cameras: "¿En el lugar de ocurrencia hay cámaras de seguridad públicas o privadas? Mencione dónde y si fueron solicitadas",
    keys: "¿Tiene en su poder las llaves del vehículo? ¿Posee original y copia?",
    witness: "¿Hubo algún testigo de lo sucedido que haya omitido en la denuncia policial? En caso afirmativo, aporte por favor nombre completo y teléfono de contacto y/o dirección.",
    carUse: "¿Qué uso le daba al vehículo?",
    documentation: "¿En el interior del vehículo había documentación y/o elementos de valor cuando fue robada? Menciona cuáles.",
    carStore: "¿Dónde guardaba habitualmente el vehículo?",
    occupation: "Ocupación",
    name: "Apellido y Nombre",
    typeNumberDocument: "Tipo y numero de documento",
    homeAddress: "Domicilio real",
    workAddress: "Domicilio laboral",
    mail: "E-mail",
    phoneNumber: "Teléfono celular",
    delayReasons: "Si se demoró en realizar la denuncia policial y/o la del siniestro ante esta aseguradora, indique por favor los motivos de dicha demora.",
    insuredOtherCompanies: "¿El vehículo estuvo asegurado en otras compañías? ¿Cuáles?",
    kilometres: "¿Qué kilometraje aproximadamente recorrido tenía al momento del hecho?",
    video: "¿Posee video del hecho? En caso afirmativo, apórtelo"
};

export default diccionaryStealingForm