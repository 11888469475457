import React from 'react';
import {
    Grid,
    SvgIcon,
    Typography,
    Card,
    LinearProgress
} from '@material-ui/core';
import moment from 'moment';
import { cardComplaintsStyle } from './style';
import { general } from '../../../../utils/icons';
import { withStyles } from '@material-ui/styles';
import { INSURED_CLIENT } from '../../../../utils/urls';

const CardComplaints = ({
    sinister,
    total,
    position,
    handleDetailSinister
}) => {
    const index = position && position;
    const statusMatch = {
        PENDIENTE: '#219653',
        VENCIDA: '#EB5757'
    };
    const classes = cardComplaintsStyle(
        statusMatch[sinister?.estado || 'PENDIENTE']
    );
    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            borderRadius: 5,
            marginBottom: 10
        },
        colorPrimary: {
            backgroundColor: '#C8B4F3'
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#6716F5'
        }
    }))(LinearProgress);

    return (
        <Card className={classes.root}>
            <div className={classes.header}>
                <Grid container justifyContent="space-between">
                    <div className={classes.status}>
                        <SvgIcon>{general.oval}</SvgIcon>
                        <span>{sinister?.estado || `Estado de denuncia`}</span>
                    </div>
                    <div className={classes.info}>
                        <Typography className={classes.dueDate}>
                            {`Fecha de siniestro: ${moment(
                                sinister?.fecha_incurrido
                            ).format('DD/MM/YYYY')}`}
                        </Typography>
                        <div className={classes.pagination}>
                            {index}/{total}
                        </div>
                    </div>
                </Grid>
            </div>
            <Grid container alignItems="center" className={classes.cardStatus}>
                <Grid item container xs={3} justifyContent="center">
                    <SvgIcon
                        className={classes.icon}
                        viewBox="0 0 70 70"
                        fill="none"
                    >
                        {general.sinister}
                    </SvgIcon>
                </Grid>
                <Grid item xs={8} className={classes.dataComplaint}>
                    <Typography className={classes.policy}>
                        Póliza: {sinister?.nro_poliza || `25522184744-1`}
                    </Typography>
                    <Typography
                        className={classes.sinister}
                        onClick={handleDetailSinister}
                    >
                        # {sinister && sinister.nro_siniestro}
                    </Typography>
                    <BorderLinearProgress variant="determinate" value={50} />
                    <span className={classes.present}>
                        {`Presentado: $${sinister?.presentado || '500,00'}`}
                    </span>
                    <span className={classes.payment}>
                        {`Pagado: $${sinister?.pagado || '50,00'}`}
                    </span>
                    <Typography className={classes.dateRegister}>
                        {`Fecha de denuncia: ${moment(
                            sinister?.fecha_incurrido
                        ).format('DD/MM/YYYY')}`}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
};

export default CardComplaints;
