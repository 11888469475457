import React, { useEffect, useState, Fragment } from 'react';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormControl,
    FormHelperText,
    Input,
    InputAdornment,
    IconButton,
    InputLabel,
    Grid,
    Typography,
    Button,
    CircularProgress
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import imgDocument from '../../assets/images/uploadDocument.png';
import { Field, change, reset } from 'redux-form';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import {
    CloudUpload,
    Visibility,
    VisibilityOff,
    Publish,
    Delete
} from '@material-ui/icons';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { green, red } from '@material-ui/core/colors';
import { uploadFiles, replaceFile } from '../../actions/uploadFiles';
import { makeStyles } from '@material-ui/core/styles';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import NumberFormat from 'react-number-format';
import { fileManagementUpload } from '../../actions/fileManagementActions';

export const useCommonStyles = makeStyles((theme) => ({
    root: { marginBottom: '5%' },
    uploadIcons: {
        display: 'flex'
    },
    uploadIconsBorder: {
        display: 'flex',
        border: '1px dashed',
        borderRadius: 18
    },
    uploadIcon: {
        textAlign: 'center',
        display: 'inline-block'
    },
    noMargin: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 5,
        marginBottom: 0
    },
    wrapper: { position: 'relative', display: 'grid', textAlign: 'center' },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700]
        }
    },
    buttonFail: {
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700]
        }
    },
    buttonFail: {
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700]
        }
    },
    buttonNormal: {
        backgroundColor: 'none'
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '65%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    required: {
        '&::after': { content: '" *"', color: 'red' },
        fontSize: 16
    },
    textHelper: {
        fontSize: 10
    },
    iconDelete: {
        position: 'absolute',
        right: 10,
        top: 37,
        color: 'rgb(220, 0, 78)',
        backgroundColor: '#FFF',
        '&:hover': {
            color: '#FFF',
            backgroundColor: 'rgb(220, 0, 78, 0.7)'
        }
    },
    buttonUpload: {
        padding: 0,
        width: '100%',
        padding: 10,
        backgroundColor: '#F4F4FA',
        boxShadow: '0px 0px',
        borderRadius: 18,
        position: 'relative',
        '& .img-icon': {
            display: 'flex',
            padding: 10,
            borderRadius: 50
        },
        '& .img-icon-upload': {
            display: 'flex'
        },
        '&:hover': {
            backgroundColor: '#F4F4FA'
        }
    },
    buttonBorderUpload: {
        padding: 0,
        width: '100%',
        padding: 10,
        boxShadow: '0px 0px',
        borderRadius: 18,
        backgroundColor: 'white',
        '& .img-icon': {
            display: 'flex',
            padding: 10,
            borderRadius: 50
        },
        '& .img-icon-upload': {
            display: 'flex'
        },
        '&:hover': {
            backgroundColor: '#F4F4FA'
        }
    },
    label: {
        fontSize: 10,
        fontWeight: 500,
        textTransform: 'uppercase'
    },
    text: {
        fontSize: 14,
        paddingTop: 5
    }
}));

export const renderFile = ({
    input,
    label,
    name,
    uploadImage,
    getFileName,
    custom: { tabIndex, placeholder, variant, size },
    meta: { touched, error }
}) => {
    const handleChange = (e) => {
        const file = e.target.files[0];
        getFileName(file.name);
        uploadImage(file);
    };

    return (
        <Grid style={{ position: 'relative' }}>
            <Button variant="contained" component="label">
                Seleccionar archivo
                <input
                    hidden
                    name={name}
                    type="file"
                    className="formInput"
                    label={label}
                    error={touched ? error : undefined}
                    helpertext={touched ? error : ''}
                    onChange={handleChange}
                />
            </Button>
        </Grid>
    );
};

export const UploadFile = ({
    title,
    name,
    handle,
    uploadSettings,
    footerDescription
}) => {
    const classes = useCommonStyles();
    const [fileName, setFileName] = useState('');
    return (
        <FormControl style={{ width: '100%', marginBottom: '5%' }}>
            <label id="demo-mutiple-checkbox-label">{title}</label>
            <Field
                type="file"
                name={name}
                className="formInput"
                fullWidth
                component={renderFile}
                uploadImage={(e) => handle(e, name)}
                getFileName={setFileName}
                uploadSettings={uploadSettings}
                mandatory={false}
                custom={{ tabIndex: 5 }}
            />
            <label>{fileName}</label>
            <p
                className={`MuiFormLabel-root MuiInputLabel-shrink ${classes.noMargin}`}
            >
                {footerDescription}
            </p>
        </FormControl>
    );
};

export const UploadFileAsync = ({
    label,
    input,
    fileEditable,
    btnStyle,
    disabled,
    hiddenTitle,
    input: { name },
    handle,
    mandatory = false,
    footerDescription,
    uploadSettings,
    showTrashIcon = true,
    custom: { tabIndex, placeholder, variant, size },
    meta: { touched, error }
}) => {
    const classes = useCommonStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [deleteFile, setDeleteFile] = useState(false);
    const [btnTitle, setBtnTitle] = useState('Subir archivo');
    const timer = React.useRef();
    const token = useSelector((state) => state.auth.token);
    const uploadedFiles = useSelector((selector) => selector.uploadedFiles);
    const config = useSelector((state) => state.userConfig.data);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
        [classes.buttonFail]: fail,
        [classes.buttonNormal]: deleteFile
    });

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {
        const isAlreadyUploaded = uploadedFiles.data.find((item) => {
            label = label.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            return item.name.includes(label.replace(/ /g, '-'));
        });

        //todo: refactor passing the requestAddForm property and they want store in a file property named files or a handle change after upload file
        if (uploadedFiles.data.length && isAlreadyUploaded) {
            const fileInformation = uploadedFiles.data.filter(
                (file) => file.originalName === name
            );
            dispatch(change('requestAddForm', name, fileInformation));
            dispatch(change('requestAddForm', 'files', uploadedFiles.data));
        }
        //todo: luego del guardar debo resetear el statado de redux de los archivos
        //todo: si se le da a guardar y un archivo aun esta subiendo, este debe esperar a que termine
    }, [uploadedFiles]);

    const handleButtonClick = async (e) => {
        const errorMsg = ['Extension no permitida', 'Excede limite de carga'];

        if (e.target.files.length > 0) {
            setBtnTitle('cargando');
            const file = e.target.files[0];
            setBtnTitle(file.name);

            const data = {
                requestID: uploadSettings.requestID,
                insuredName: uploadSettings.insuredName,
                repositoryCode: uploadSettings.repositoryCode,
                permanentlySave: uploadSettings.permanentlySave,
                fileName: e.target.name,
                originalName: e.target.id,
                files: file
            };

            if (!loading) {
                setSuccess(false);
                setLoading(true);
            }

            const indexFileReplaced = uploadedFiles.data.findIndex((item) => {
                label = label.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                return item.name.includes(label.replace(/ /g, '-'));
            });

            if (indexFileReplaced < 0) {
                try {
                    await dispatch(uploadFiles(token, data));
                } catch (err) {
                    let msg = 0;

                    if (err.includes('extesion')) {
                        msg = 0;
                    } else if (err.includes('limit')) {
                        msg = 1;
                    } else {
                        msg = err;
                    }

                    const errorMessage =
                        typeof msg === 'number' ? errorMsg[msg] : msg;

                    setLoading(false);
                    setFail(true);
                    setBtnTitle(errorMessage);
                    throw errorMessage;
                }
            } else {
                const urlFileToReplace =
                    uploadedFiles.data[indexFileReplaced].idTempStored ||
                    uploadedFiles.data[indexFileReplaced].url.split('.com/')[1];
                data.permanentlySave = !uploadedFiles.data[indexFileReplaced]
                    .idTempStored
                    ? true
                    : false;

                await dispatch(replaceFile(token, urlFileToReplace));
                uploadedFiles.data.splice(indexFileReplaced, 1);

                try {
                    await dispatch(uploadFiles(token, data));
                } catch (err) {
                    let msg = 0;

                    if (err.includes('extesion')) {
                        msg = 0;
                    } else if (err.includes('limit')) {
                        msg = 1;
                    } else {
                        msg = err;
                    }

                    const errorMessage =
                        typeof msg === 'number' ? errorMsg[msg] : msg;

                    setLoading(false);
                    setFail(true);
                    setBtnTitle(errorMessage);
                    throw errorMessage;
                }
            }

            if (!uploadedFiles.error) {
                setSuccess(true);
            } else {
                setFail(true);
                setBtnTitle('Error!');
            }
            setLoading(false);
        }
    };

    const restartFileQueue = async (e) => {
        e.target.value = null;
    };

    const handleShowFile = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    const hasFile = (inputName) => {
        const { data } = uploadedFiles;
        const name = inputName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const indexFileDelete = data.findIndex((file) =>
            file.name.includes(name.replace(/ /g, '-'))
        );
        return indexFileDelete >= 0;
    };

    const handleDeleteFile = async (inputName) => {
        const { data } = uploadedFiles;
        let urlToDelete = '';

        const indexFileDelete = data.findIndex((file) =>
            file.originalName.includes(inputName)
        );

        if (indexFileDelete >= 0) {
            if (
                data[indexFileDelete].idTempStored &&
                data[indexFileDelete].url
            ) {
                urlToDelete = data[indexFileDelete].idTempStored;
            } else if (
                !data[indexFileDelete].idTempStored &&
                data[indexFileDelete].url
            ) {
                urlToDelete = data[indexFileDelete].url.split('.com/')[1];
            } else {
                urlToDelete = undefined;
            }

            if (urlToDelete) {
                setLoading(true);
                setBtnTitle('Eliminando');

                await dispatch(replaceFile(token, urlToDelete));

                setBtnTitle('SUBIR ARCHIVO');
                setSuccess(false);
                setDeleteFile(true);

                uploadedFiles.data.splice(indexFileDelete, 1);
                const dataInputName = uploadedFiles.data.filter(
                    (file) => file.originalName === inputName
                );

                dispatch(change('requestAddForm', inputName, dataInputName));
                dispatch(change('requestAddForm', 'files', uploadedFiles.data));
                setLoading(false);
            }
            return;
        }
    };

    return (
        <Grid>
            <Grid className={classes.wrapper}>
                {!hiddenTitle && (
                    <Typography
                        className={mandatory ? classes.required : ''}
                        variant="caption"
                        display="block"
                        align="left"
                        gutterBottom
                    >
                        {label}
                    </Typography>
                )}
                <div className={classes.uploadIcons}>
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className={classes.buttonUpload}
                        disabled={disabled}
                        style={{
                            color: config.theme
                                ? config.theme.primary.main
                                : 'rgb(175, 138, 252)'
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid
                                className="img-icon"
                                style={{
                                    backgroundColor: config.theme
                                        ? config.theme.secondary.variant5
                                        : 'rgb(175, 138, 252)'
                                }}
                            >
                                <img src={imgDocument} />
                            </Grid>
                            <Grid>{btnTitle}</Grid>
                            <Grid className="img-icon-upload">
                                {!hasFile(label) && <CloudUpload />}
                            </Grid>
                        </Grid>
                        <input
                            hidden
                            id={name}
                            name={label}
                            type="file"
                            className="formInput"
                            helperText={touched && !!error ? error : false}
                            error={touched && !!error}
                            label={btnTitle}
                            onChange={handleButtonClick}
                            disabled={disabled}
                            onClick={restartFileQueue}
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                        />
                    </Button>
                    {hasFile(label) && showTrashIcon && (
                        <IconButton
                            disabled={disabled}
                            onClick={() => handleDeleteFile(name)}
                            className={classes.iconDelete}
                            color="secondary"
                            aria-label="delete"
                            component="span"
                        >
                            <Delete className={classes.icon} />
                        </IconButton>
                    )}
                    {fileEditable && (
                        <IconButton
                            onClick={() => handleShowFile(fileEditable)}
                            color="primary"
                            aria-label="view_doc"
                            component="span"
                        >
                            <Visibility />
                        </IconButton>
                    )}
                    {loading && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    )}
                </div>
            </Grid>

            {touched && !!error ? (
                <FormHelperText error={true}>{error}</FormHelperText>
            ) : (
                <p
                    className={`MuiFormLabel-root MuiInputLabel-shrink ${classes.noMargin} ${classes.textHelper}`}
                >
                    {footerDescription}
                </p>
            )}
        </Grid>
    );
};

export const CustomUploadFileAsync = ({
    label,
    input,
    fileEditable,
    disabled,
    hiddenTitle,
    input: { name },
    mandatory = false,
    footerDescription,
    meta: { touched, error },
    settings = {},
    url = 'documentManagement/uploadFiles',
    maxSize = 500, // kb
    fileTypes = ".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx",
    temporaryIdentityNumber = null
}) => {
    const classes = useCommonStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [btnTitle, setBtnTitle] = useState('Subir archivo');
    const timer = React.useRef();
    const token = useSelector((state) => state.auth.token);
    const { data: uploadedFiles} = useSelector((selector) => selector.fileManagement);
    const config = useSelector((state) => state.userConfig.data);
    const [showMessageError, setShowMessageError] = useState(false);
    const [CustomMessageError, setCustomMessageError] = useState('');
    const support = useSelector((state) => state.form.support?.values);


    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const validateFileType = (data, fileTypes) => {
        const validations = fileTypes.split(",");
        const validatePointCount = data.fileName.split(".").length
        const fileExtension = `.${data.fileName.split(".")[validatePointCount-1]}`;
        let arr = validations.filter((item) => item.includes(fileExtension));
        return arr.length > 0 ? true : false;
    };

    const handleButtonClick = async (e) => {
    const errorMsg = [
      `El tipo del archivo no es permitido`,
      `El peso del archivo es superior a ${maxSize}kb`,
      `Error de red al subir el archivo`,
    ];

    if (e.target.files.length > 0) {
        const file = e.target.files[0];
        if (validateNameAlreadyUploaded(file.name)) {
            setCustomMessageError('El archivo ya fue seleccionado')
            setShowMessageError(true)
            return
        }
        setBtnTitle("cargando");
        
        const data = {
            ...settings,
            fileName: file.name,
            originalName: e.target.id,
            files: file,
            temporaryIdentityNumber
        };

        if (mandatory) {
            data['fieldName'] = label
        }

        setBtnTitle(file.name);

        if (!loading) {
            setSuccess(false);
            setLoading(true);
        }

        try {
            if (file.size / 1024 > maxSize) {
                throw `limite`;
            }
            if (fileTypes && !validateFileType(data, fileTypes)) {
                throw `tipo`;
            }
            await dispatch(fileManagementUpload(token, data, url));
            dispatch(change('support', label, data.fileName));
            dispatch(change('supportUploaded', label, data.fileName));
            setCustomMessageError('')
            setShowMessageError(false)
        } catch (err) {
            let msg = 0;

            if (err?.message !== undefined && err?.message === 'Network Error') {
                msg = 2;
            } else {
                if (err.includes("tipo")) {
                    msg = 0;
                } else if (err.includes("limite")) {
                    msg = 1;
                } else {
                    msg = err;
                }
            }
            const errorMessage = typeof msg === "number" ? errorMsg[msg] : msg;
            setLoading(false);
            setFail(true);
            setShowMessageError(true)
            setBtnTitle(errorMessage);
            setCustomMessageError(errorMessage)
            dispatch(reset('support'));
            setBtnTitle(('Subir archivo'));
            throw errorMessage;
        }
        if (!uploadedFiles.error) {
            setSuccess(true);
        } else {
            setShowMessageError(true)
            setFail(true);
            setCustomMessageError("Error!")
            setBtnTitle("Error!");
        }
        setLoading(false);
        }
    };

    const restartFileQueue = async (e) => {
        e.target.value = null;
        setShowMessageError(false)
        setCustomMessageError('')
    };

    const handleShowFile = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    const validateNameAlreadyUploaded = (name) => {
        let toReturn = false;
        if (support !== undefined) {
            const filesArr = Object.values(support);
            toReturn = filesArr.includes(name)
        }
        return toReturn
    }

    return (
        <Grid>
            <Grid className={classes.wrapper}>
                {!hiddenTitle && (
                    <Typography
                        className={mandatory ? classes.required : ''}
                        variant="caption"
                        display="block"
                        align="left"
                        gutterBottom
                        style={{fontSize: 16}}
                    >
                        {label}
                    </Typography>
                )}
                <div className={classes.uploadIcons}>
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className={classes.buttonUpload}
                        disabled={disabled}
                        style={{
                            color: config.theme
                                ? config.theme.primary.main
                                : 'rgb(175, 138, 252)'
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid
                                className="img-icon"
                                style={{
                                    backgroundColor: config.theme
                                        ? config.theme.secondary.variant5
                                        : 'rgb(175, 138, 252)'
                                }}
                            >
                                <img src={imgDocument} />
                            </Grid>
                            <Grid>{btnTitle}</Grid>
                            <Grid className="img-icon-upload">
                                {<CloudUpload />}
                            </Grid>
                        </Grid>
                        <input
                            hidden
                            id={name}
                            name={label}
                            type="file"
                            className="formInput"
                            helperText={touched && !!error ? error : false}
                            error={touched && !!error}
                            label={btnTitle}
                            onChange={handleButtonClick}
                            disabled={disabled}
                            onClick={restartFileQueue}
                            accept={fileTypes}
                        />
                    </Button>
                    {fileEditable && (
                        <IconButton
                            onClick={() => handleShowFile(fileEditable)}
                            color="primary"
                            aria-label="view_doc"
                            component="span"
                        >
                            <Visibility />
                        </IconButton>
                    )}
                    {loading && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    )}
                </div>
            </Grid>
            {
               showMessageError && (
                    <Typography
                        // className={classes.required}
                        variant="caption"
                        display="block"
                        align="left"
                        gutterBottom
                        style={{color: 'red'}}
                    >
                        {CustomMessageError}
                    </Typography>
               ) 
            }
        </Grid>
    );
};

export const UploadFileBorderAsync = ({
    label,
    input,
    fileEditable,
    btnStyle,
    disabled,
    hiddenTitle,
    input: { name },
    handle,
    mandatory = false,
    footerDescription,
    uploadSettings,
    showTrashIcon = true,
    custom: { tabIndex, placeholder, variant, size },
    meta: { touched, error }
}) => {
    const classes = useCommonStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [deleteFile, setDeleteFile] = useState(false);
    const [btnTitle, setBtnTitle] = useState('Subir archivo');
    const timer = React.useRef();
    const token = useSelector((state) => state.auth.token);
    const uploadedFiles = useSelector((selector) => selector.uploadedFiles);
    const config = useSelector((state) => state.userConfig.data);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
        [classes.buttonFail]: fail,
        [classes.buttonNormal]: deleteFile
    });

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {
        const isAlreadyUploaded = uploadedFiles.data.find((item) => {
            label = label.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            return item.name.includes(label.replace(/ /g, '-'));
        });

        //todo: refactor passing the requestAddForm property and they want store in a file property named files or a handle change after upload file
        if (uploadedFiles.data.length && isAlreadyUploaded) {
            dispatch(change('requestAddForm', name, uploadedFiles.data));
            dispatch(change('requestAddForm', 'files', uploadedFiles.data));
        }
        //todo: luego del guardar debo resetear el statado de redux de los archivos
        //todo: si se le da a guardar y un archivo aun esta subiendo, este debe esperar a que termine
    }, [uploadedFiles]);

    const handleButtonClick = async (e) => {
        if (e.target.files.length > 0) {
            setBtnTitle('cargando');
            const file = e.target.files[0];
            setBtnTitle(file.name);

            const data = {
                requestID: uploadSettings.requestID,
                insuredName: uploadSettings.insuredName,
                repositoryCode: uploadSettings.repositoryCode,
                permanentlySave: uploadSettings.permanentlySave,
                fileName: e.target.name,
                originalName: e.target.id,
                files: file
            };

            if (!loading) {
                setSuccess(false);
                setLoading(true);
            }

            const indexFileReplaced = uploadedFiles.data.findIndex((item) => {
                label = label.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                return item.name.includes(label.replace(/ /g, '-'));
            });

            if (indexFileReplaced < 0) {
                await dispatch(uploadFiles(token, data));
            } else {
                const urlFileToReplace =
                    uploadedFiles.data[indexFileReplaced].idTempStored ||
                    uploadedFiles.data[indexFileReplaced].url.split('.com/')[1];
                data.permanentlySave = !uploadedFiles.data[indexFileReplaced]
                    .idTempStored
                    ? true
                    : false;

                await dispatch(replaceFile(token, urlFileToReplace));
                uploadedFiles.data.splice(indexFileReplaced, 1);
                await dispatch(uploadFiles(token, data));
            }

            if (!uploadedFiles.error) {
                setSuccess(true);
            } else {
                setFail(true);
                setBtnTitle('Error!');
            }
            setLoading(false);
        }
    };

    const handleShowFile = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    const hasFile = (inputName) => {
        const { data } = uploadedFiles;
        const name = inputName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const indexFileDelete = data.findIndex((file) =>
            file.name.includes(name.replace(/ /g, '-'))
        );
        return indexFileDelete >= 0;
    };

    const handleDeleteFile = async (inputName) => {
        const { data } = uploadedFiles;
        let urlToDelete = '';

        const name = inputName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const indexFileDelete = data.findIndex((file) =>
            file.name.includes(name.replace(/ /g, '-'))
        );

        if (indexFileDelete >= 0) {
            if (
                data[indexFileDelete].idTempStored &&
                data[indexFileDelete].url
            ) {
                urlToDelete = data[indexFileDelete].idTempStored;
            } else if (
                !data[indexFileDelete].idTempStored &&
                data[indexFileDelete].url
            ) {
                urlToDelete = data[indexFileDelete].url.split('.com/')[1];
            } else {
                urlToDelete = undefined;
            }

            if (urlToDelete) {
                setLoading(true);
                setBtnTitle('Eliminando');

                await dispatch(replaceFile(token, urlToDelete));

                setBtnTitle('SUBIR ARCHIVO');
                setSuccess(false);
                setDeleteFile(true);

                uploadedFiles.data.splice(indexFileDelete, 1);
                dispatch(
                    change('requestAddForm', inputName, uploadedFiles.data)
                );
                dispatch(change('requestAddForm', 'files', uploadedFiles.data));
                setLoading(false);
            }
            return;
        }
    };

    return (
        <Grid>
            <Grid className={classes.wrapper}>
                {!hiddenTitle && (
                    <Typography
                        className={mandatory ? classes.required : ''}
                        variant="caption"
                        display="block"
                        align="left"
                        gutterBottom
                    >
                        {label}
                    </Typography>
                )}
                <div className={classes.uploadIconsBorder}>
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className={classes.buttonBorderUpload}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid
                                className="img-icon"
                                style={{
                                    backgroundColor: config.theme
                                        ? config.theme.secondary.variant5
                                        : 'rgb(175, 138, 252)'
                                }}
                            >
                                <img src={imgDocument} />
                            </Grid>
                            <Grid
                                style={{
                                    color: config.theme
                                        ? config.theme.primary.main
                                        : 'rgb(175, 138, 252)'
                                }}
                            >
                                {btnTitle}
                            </Grid>
                            <Grid className="img-icon-upload">
                                <CloudUpload
                                    style={{
                                        color: config.theme
                                            ? config.theme.primary.main
                                            : 'rgb(175, 138, 252)'
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <input
                            hidden
                            id={name}
                            name={label}
                            type="file"
                            className="formInput"
                            helperText={touched && !!error ? error : false}
                            error={touched && !!error}
                            label={btnTitle}
                            onChange={handleButtonClick}
                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                        />
                    </Button>
                    {hasFile(label) && showTrashIcon && (
                        <IconButton
                            disabled={disabled}
                            onClick={() => handleDeleteFile(label)}
                            className={classes.iconDelete}
                            color="secondary"
                            aria-label="delete"
                            component="span"
                        >
                            <Delete className={classes.icon} />
                        </IconButton>
                    )}
                    {fileEditable && (
                        <IconButton
                            onClick={() => handleShowFile(fileEditable)}
                            color="primary"
                            aria-label="view_doc"
                            component="span"
                        >
                            <Visibility />
                        </IconButton>
                    )}
                    {loading && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    )}
                </div>
            </Grid>

            {touched && !!error ? (
                <FormHelperText error={true}>{error}</FormHelperText>
            ) : (
                <p
                    className={`MuiFormLabel-root MuiInputLabel-shrink ${classes.noMargin}`}
                >
                    {footerDescription}
                </p>
            )}
        </Grid>
    );
};

export const UploadFileIconAsync = ({
    aditionalDetails,
    label,
    input,
    btnStyle,
    disabled,
    hiddenTitle,
    input: { name },
    handle,
    mandatory = false,
    footerDescription,
    uploadSettings,
    custom: { tabIndex, placeholder, variant, size },
    meta: { touched, error },
    expectedFiles = {},
    handleUploadedFileTableComponent = function (arg) {
        return;
    }
}) => {
    const classes = useCommonStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [fail, setFail] = useState(false);
    const [btnTitle, setBtnTitle] = useState('Subir archivo');
    const timer = React.useRef();
    const token = useSelector((state) => state.auth.token);
    const uploadedFiles = useSelector((selector) => selector.uploadedFiles);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: expectedFiles[name],
        [classes.buttonFail]: fail
    });

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {
        const isAlreadyUploaded = uploadedFiles.data.find((item) =>
            item.name.includes(label.replace(/ /g, '-'))
        );

        //todo: refactor passing the requestAddForm property and they want store in a file property named files or a handle change after upload file
        if (uploadedFiles.data.length && isAlreadyUploaded) {
            dispatch(change('requestAddForm', name, uploadedFiles.data));
            dispatch(change('requestAddForm', 'files', uploadedFiles.data));
        }
        //todo: luego del guardar debo resetear el statado de redux de los archivos
        //todo: si se le da a guardar y un archivo aun esta subiendo, este debe esperar a que termine
    }, [uploadedFiles]);

    const handleButtonClick = async (e) => {
        setBtnTitle('cargando');
        const file = e.target.files[0];
        setBtnTitle(file.name);

        const data = {
            requestID: uploadSettings.requestID,
            insuredName: uploadSettings.insuredName,
            repositoryCode: uploadSettings.repositoryCode,
            fileName: e.target.name,
            originalName: e.target.getAttribute('originalname'),
            aditionalDetails,
            files: file
        };

        if (!loading) {
            setLoading(true);
        }

        console.log('uploadedFiles.data', uploadedFiles.data);

        // await dispatch(uploadFiles(token, data));

        const indexFileReplaced = uploadedFiles.data.findIndex((item) => {
            return item.originalName === name;
        });

        if (indexFileReplaced < 0) {
            data.permanentlySave = false;
            await dispatch(uploadFiles(token, data));
        } else {
            const urlFileToReplace =
                uploadedFiles.data[indexFileReplaced].idTempStored ||
                uploadedFiles.data[indexFileReplaced].url.split('.com/')[1];
            data.permanentlySave = !uploadedFiles.data[indexFileReplaced]
                .idTempStored
                ? true
                : false;

            await dispatch(replaceFile(token, urlFileToReplace));
            uploadedFiles.data.splice(indexFileReplaced, 1);
            await dispatch(uploadFiles(token, data));
        }

        if (!uploadedFiles.error) {
            handleUploadedFileTableComponent({
                ...expectedFiles,
                [name]: true
            });
        } else {
            setFail(true);
            setBtnTitle('Error!');
        }
        setLoading(false);
    };

    const restartFileQueue = async (e) => {
        e.target.value = null;
    };

    return (
        <Grid className={classes.uploadIcon}>
            <Fragment>
                {!hiddenTitle && (
                    <Typography
                        className={mandatory ? classes.required : ''}
                        variant="caption"
                        display="block"
                        gutterBottom
                    >
                        {label}
                    </Typography>
                )}

                <label htmlFor={`icon-button-file-${label}`}>
                    <IconButton
                        aria-label="upload picture"
                        component="span"
                        className={buttonClassname}
                        disabled={loading || disabled}
                    >
                        <Fragment>
                            <Publish className={buttonClassname} />
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                />
                            )}
                        </Fragment>
                    </IconButton>
                </label>

                <input
                    id={`icon-button-file-${label}`}
                    hidden
                    originalname={name}
                    name={label}
                    type="file"
                    className="formInput"
                    helperText={touched && !!error ? error : false}
                    error={touched && !!error}
                    label={btnTitle}
                    onChange={handleButtonClick}
                    onClick={restartFileQueue}
                    accept=".doc,.docx,.jpg,.jpeg,.png,.pdf"
                />
            </Fragment>
            {touched && !!error ? (
                <FormHelperText error={true}>{error}</FormHelperText>
            ) : (
                <p
                    className={`MuiFormLabel-root MuiInputLabel-shrink ${classes.noMargin}`}
                >
                    {footerDescription}
                </p>
            )}
        </Grid>
    );
};

export const renderTextField = ({
    input,
    label,
    multiline,
    rows,
    classes,
    custom: { tabIndex, placeholder, size },
    variant,
    meta: { touched, error },
    disabled
}) => {
    return (
        <Fragment>
            <TextField
                {...input}
                className={`formInput ${classes}`}
                label={label}
                error={touched && !!error}
                helperText={touched && !!error ? error : false}
                placeholder={placeholder}
                inputProps={{ tabIndex }}
                multiline={multiline ? multiline : false}
                rows={rows ? rows : 1}
                fullWidth
                disabled={disabled}
                variant={variant ? variant : 'standard'}
                size={size ? size : 'small'}
            />
        </Fragment>
    );
};

export const RenderPasswordField = ({
    input,
    label,
    handleChange,
    defaultValue,
    custom: { tabIndex, placeholder, variant, size },
    meta: { touched, error }
}) => {
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false
    });

    const handleChangeInside = (prop) => (event) => {
        handleChange();
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <FormControl>
            <InputLabel htmlFor="standard-adornment-password">
                {label}
            </InputLabel>
            <Input
                {...input}
                id="standard-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                defaultValue={defaultValue}
                value={values.password}
                onChange={handleChangeInside('password')}
                helperText={touched && !!error ? error : false}
                placeholder={placeholder}
                inputProps={{ tabIndex }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {values.showPassword ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            )}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText error></FormHelperText>
        </FormControl>
    );
};

export const renderNumberField = ({
    input,
    label,
    disabled = false,
    meta: { touched, error }
}) => {
    return (
        <TextField
            type="number"
            className="formInput"
            label={label}
            disabled={disabled}
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            fullWidth
            {...input}
        />
    );
};

export const renderDecimalField = ({
    input,
    label,
    disabled,
    custom: { tabIndex, placeholder, variant, size },
    meta: { touched, error }
}) => {
    return (
        <Grid style={{ position: 'relative' }}>
            <NumberFormat
                {...input}
                label={label}
                customInput={TextField}
                error={!disabled && touched && !!error}
                helperText={!disabled && touched && !!error ? error : false}
                disabled={disabled}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                value={input.value}
                style={{
                    marginTop: 5,
                    width: '100%'
                }}
                isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === '' || floatValue <= 999999999;
                }}
            />
        </Grid>
    );
};

export const renderSelectField = ({
    input,
    label,
    children,
    disabled = false,
    defaultValue,
    custom: { tabIndex, placeholder, variant, size },
    meta: { touched, error }
}) => {
    return (
        <TextField
            select
            className="formInput"
            label={label}
            disabled={disabled}
            children={children}
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            inputProps={{ tabIndex }}
            variant={variant || 'standard'}
            size={size || 'small'}
            fullWidth
            value={defaultValue}
            {...input}
        />
    );
};

export const RenderSearchSelectField = ({
    input: { onBlur, value, ...otherInputProps },
    options,
    name,
    custom: { tabIndex, placeholder, variant, size }
}) => {
    const [valueSelected, setValue] = useState(value);
    const handleChange = (event, newValue, previousValue, val) => {
        setValue(newValue);
        otherInputProps.onChange(event, newValue, previousValue, val);
    };
    return (
        <>
            <SelectSearch
                options={options}
                search
                name={name}
                filterOptions={fuzzySearch}
                placeholder={placeholder}
                value={valueSelected}
                onChange={handleChange}
            />
            <ArrowDropDown className="input-icon-down" />
        </>
    );
};

export const renderMultiSelectField = ({
    input,
    label,
    children,
    custom: { tabIndex, placeholder, variant, size },
    meta: { touched, error }
}) => {
    return (
        <TextField
            select
            className="formInput"
            label={label}
            children={children}
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            inputProps={{ tabIndex }}
            variant={variant ? variant : 'standard'}
            size={size ? size : 'small'}
            fullWidth
            SelectProps={{
                multiple: true
            }}
            {...input}
            value={input.value === '' ? [] : input.value}
        />
    );
};

export const renderMultiSelectCheckboxField = ({
    input,
    label,
    getName = null,
    children,
    custom: {
        tabIndex,
        placeholder = 'Seleccione para agregar',
        variant,
        size
    },
    meta: { touched, error }
}) => {
    return (
        <TextField
            select
            className="formInput"
            label={label}
            children={children}
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            inputProps={{ tabIndex }}
            variant={variant ? variant : 'standard'}
            size={size ? size : 'small'}
            fullWidth
            SelectProps={{
                multiple: true,
                renderValue: (selected) => {
                    if (selected.length === 0) {
                        return <em>{placeholder}</em>;
                    }
                    return getName
                        ? getName(selected).join(', ')
                        : selected.join(', ');
                }
            }}
            {...input}
            value={input.value === '' ? [] : input.value}
        />
    );
};

export const renderDateField = ({
    input: { onBlur, value, ...otherInputProps },
    label,
    dateValue,
    minDate,
    maxDate,
    custom: { tabIndex },
    meta: { touched, error },
    disabled,
    ...props
}) => {
    const handleChange = (date) => {
        Date.parse(date)
            ? otherInputProps.onChange(date)
            : otherInputProps.onChange(null);
    };
    return (
        <KeyboardDatePicker
            autoOk
            variant="inline"
            format="dd/MM/yyyy"
            className="formInput dateFormInput"
            label={label}
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            inputProps={{ tabIndex }}
            fullWidth
            value={dateValue ? new Date(dateValue) : null}
            onBlur={() => onBlur(value ? new Date(value).toISOString() : null)}
            onChange={handleChange}
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
        />
    );
};

export const renderTimeField = ({
    input: { onBlur, value, ...otherInputProps },
    label,
    dateValue,
    custom: { tabIndex },
    meta: { touched, error },
    disabled,
    ...props
}) => {
    const handleChange = (date) => {
        Date.parse(date)
            ? otherInputProps.onChange(date)
            : otherInputProps.onChange(null);
    };
    return (
        <KeyboardTimePicker
            mask="__:__ _M"
            className="formInput dateFormInput"
            label={label}
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            inputProps={{ tabIndex }}
            fullWidth
            value={dateValue ? new Date(dateValue) : null}
            onChange={handleChange}
            disabled={disabled}
        />
    );
};

export const renderMonthDateField = ({
    input: { onBlur, value, ...otherInputProps },
    label,
    dateValue,
    minDate,
    maxDate,
    custom: { tabIndex },
    meta: { touched, error },
    disabled,
    ...props
}) => {
    const handleChange = (date) => {
        Date.parse(date)
            ? otherInputProps.onChange(date)
            : otherInputProps.onChange(null);
    };

    return (
        <KeyboardDatePicker
            views={['month', 'year']}
            autoOk
            variant="inline"
            format="MM/yy"
            className="formInput dateFormInput"
            label={label}
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            inputProps={{ tabIndex }}
            fullWidth
            value={dateValue ? new Date(dateValue) : null}
            onBlur={() => onBlur(value ? new Date(value).toISOString() : null)}
            onChange={handleChange}
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
        />
    );
};

export const renderDateTimeField = ({
    input,
    label,
    defaultValue = undefined,
    custom: { tabIndex },
    meta: { touched, error }
}) => {
    return (
        <TextField
            type="datetime-local"
            className="formInput"
            label={label}
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            inputProps={{ tabIndex, value: defaultValue }}
            fullWidth
            {...input}
            InputLabelProps={{
                shrink: true
            }}
        />
    );
};

export const renderCheckbox = ({ input, label, disabled = false }) => (
    <FormControlLabel
        control={
            <Checkbox
                color="primary"
                disabled={disabled}
                checked={!!input.value}
                onChange={input.onChange}
            />
        }
        label={label}
    />
);

export const renderRadioGroup = ({
    input,
    custom: { options },
    meta: { touched, error },
    ...rest
}) => (
    <FormControl>
        <RadioGroup value={input.value} onChange={input.onChange} {...rest}>
            {options.map((option) => (
                <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio color="primary" />}
                    label={option.label}
                />
            ))}
        </RadioGroup>
        {touched && !!error && (
            <FormHelperText error={true}>{error}</FormHelperText>
        )}
    </FormControl>
);

export const RenderTextFieldOnlyText = ({
    label,
    value,
    styleValue,
    styleLabel
}) => {
    const classes = useCommonStyles();
    const config = useSelector((state) => state.userConfig.data);

    return value ? (
        <Grid>
            <Typography
                className={classes.label}
                style={{
                    color:
                        Object.keys(config).length !== 0 &&
                        config.theme.primary.main,
                    ...styleLabel
                }}
            >
                {label}
            </Typography>
            <Typography
                className={classes.text}
                style={{
                    color:
                        Object.keys(config).length !== 0 &&
                        config.theme.typography.main,
                    ...styleValue
                }}
            >
                {value}
            </Typography>
        </Grid>
    ) : (
        ''
    );
};

export const RenderTextFieldDisabled = ({ label, value }) => (
    <TextField
        label={label}
        value={value || ''}
        className="formInput"
        margin="normal"
        disabled
        fullWidth
    />
);

export const RenderCheckboxDisabled = ({ label, value }) => (
    <FormControlLabel
        control={<Checkbox color="primary" checked={value} />}
        label={label}
        disabled
    />
);

export const RenderRadioGroupDisabled = ({ label, value, options }) => (
    <FormControl>
        <RadioGroup value={value}>
            {options.map((option) => (
                <FormControlLabel
                    disabled
                    key={option.value}
                    value={option.value}
                    control={<Radio color="primary" />}
                    label={option.label}
                />
            ))}
        </RadioGroup>
    </FormControl>
);
