import axios from "axios";
import jwt_decode from "jwt-decode";
import { createAsyncAction } from "redux-promise-middleware-actions";
import {
  authUrlBase,
  authUrlApi,
  validateResponse,
  exceptionCodeResponse,
} from "../utils/urls";
import { getDataToken, getToken, headers } from "../utils/index";
import { GET_USER_BY_ID, SISE_AUTH } from "../actions/types";

export const loginUserAPI = createAsyncAction("POST_LOGIN", async (bodyReq) => {
  const headers = { "Content-Type": "application/json" };
  const body = JSON.stringify(bodyReq);
  const res = await fetch(`${authUrlApi}/login`, {
    method: "POST",
    headers,
    body,
  })
    .then((response) => validateResponse(response))
    .catch((error) => {
      throw exceptionCodeResponse(error);
    });
  return res;
});

export const getDataUserLoggedIn = () => {
  const token = getToken();
  const userData = getDataToken();

  const headers = { Authorization: `Bearer ${token}` };
  const response = axios.get(`${authUrlApi}/usersInfo/${userData.user._id}`, {
    headers,
  });
  return response;
};

export const getUserLogged = createAsyncAction(GET_USER_BY_ID, async () => {
  const token = getToken();
  const userData = getDataToken();

  const headers = { Authorization: `Bearer ${token}` };
  const response = axios.get(`${authUrlApi}/users/${userData.user._id}`, {
    headers,
  });
  return response;
});

export const updateDataUserInfo = async (data) => {
  try {
    const response = await axios.put(
      `${authUrlApi}/usersInfo/update`,
      data,
      headers()
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const authUser = createAsyncAction(SISE_AUTH, async () => {
  const res = await fetch(`${authUrlBase}sise/token`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.text();
    })
    .then((token) => {
      localStorage.setItem("query-execute:token", token);
      return token;
    })
    .catch((error) => {
      throw exceptionCodeResponse(error);
    });
  return res;
});
