import {
    GET_BANKS,
    GET_BANKS_FULFILLED,
    GET_BANKS_REJECTED,
    GET_BANKS_PENDING
  } from "../actions/types";
  
  const initialState = {
    data: [],
    loading: false,
    error: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_BANKS_PENDING:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case GET_BANKS_FULFILLED:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case GET_BANKS_REJECTED:
        return {
          ...state,
          data: [],
          loading: false,
          error: true,
        };
      case GET_BANKS:
        return {
          ...state,
          data: [],
        };
      default:
        return state;
    }
  }
  