import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import accountTypeReducer from "./accountTypeReducer";
import agentBankReducer from "./agentBankReducer";
import agentDocumentReducer from "./agentDocumentReducer";
import authReducer from "./authReducer";
import balanceInquiryReducer from "./balanceInquiryReducer";
import bankReducer from "./bankReducer";
import bonusSummaryReducer from "./bonusSummaryReducer";
import configurationReducer from "./configurationReducer";
import modalReducer from "./modalReducer";
import notificationReducer from "./notificationReducer";
import qSiseDashboardReducer from "./qSiseDashboardReducer";
import qSiseSinisterReducer from "./qSiseSinisterReducer";
import uploadFilesReducer from "./uploadFilesReducer";
import userConfigReducer from "./userConfigReducer";
import userEventsReducer from "./userEventsReducer";
import userInfoReducer from "./userInfoReducer";
import medicalNetworkServicesReducer from "./medicalNetworkServicesReducer";
import medicalNetworkReducer from "./medicalNetworkReducer";
import menuOptionsReducer from "./menuOptionsReducer";
//import proofOfPaymentReducer from './proofOfPaymentReducer';
import requestWFReducer from "./requestWFReducer";
import workflowReducer from "./workflowReducer";
import workflowActionsReducer from "./workflowActionsReducer";
import workflowStatesReducer from "./workflowStatesReducer";
import commentRequestWFReducer from "./commentRequestWFReducer";
import requestWorkflowGroupReducer from "./requestWorkflowGroupReducer";
import awardReducer from "./awardReducer";
import emergencyReducer from "./emergencyReducer";
import endorsementReducer from "./endorsementReducer";
import requestWFSingleReducer from "./requestWFSingleReducer";
import workflowTypesReducer from "./workflowTypesReducer";
import massiveCollectiveRequestReducer from "./massiveCollectiveRequestReducer";
import filtersReducer from "./filtersReducer";
//import collectiveRequestReducer from './collectiveRequestReducer';
import paginateReducer from "./paginateReducer";
import trackingRequestWFReducer from "./trackingRequestWFReducer";
import quoteReducer from "./quoteReducer";
import yearReducer from "./yearReducer";
import brandReducer from "./brandReducer";
import modelReducer from "./modelReducer";
import versionReducer from "./versionReducer";
import accesorieReducer from "./accesorieReducer";
import insuranceReducer from "./insuranceReducer";
import riskTypeReducer from "./riskTypeReducer";
import agentListReducer from "./quotationAgentReducer";
import leadReducer from "./leadReducer";
import leadCommentReducer from "./leadCommentReducer";
import stateListReducer from "./stateListReducer";
import postalCodeReducer from "./postalCodeReducer";
import policyReducer from "./policyReducer";
import ocupationsReducer from "./ocupationsReducer";
import fiscalConditionReducer from "./fiscalConditionReducer";
import actionsReducer from "./actionsReducer";
import {
  bankListReducer,
  cardListReducer,
  paymentMethodListReducer,
  updatePaymentMethodReducer,
} from "./changePaymentMethodReducer";
import {
  reasonsReducer,
  generateCancelReducer,
} from "./reasonCancellationReducer";
import { listDocumentsTypeReducer } from "./listDocumentsReducer";
import { AUTOS, GASTOS_MEDICOS, HOGAR, SALUD, VIDA } from "../utils/risksTypes";
import { createNamedWrapperQuoteReducer } from "../utils/reducerWrappers";
import { reducer as newsReducer } from "news-list";
import { filesSinisterReducer } from "./filesSinisterReducer";
import documentsProposalReducer from "./documentsProposalReducer";
import filesProposalProduct from "./filesProposalProductReducer";
import coinReducer from "./coinReducer";
import faqReducer from "./faqReducer";
import paymentMethodsReducer from "./paymentMethodsReducer";
import userReducer from "./userReducer";
import itemsPolicyReducer from "./itemsPolicyReducer";
import claimCausesReducer from "./claimCausesReducer";
import medicalNetworkTypesReducer from "./medicalNetworkTypesReducer";
import fileManagementReducer from "./fileManagementReducer.js";
import sinisterReducer from "./sinisterReducer";
import paymentLinksMethodsReducer from "./paymentLinksMethodsReducer"
import { attendanceAvailableReducer, getAttendanceByInsuredReducer } from "./attendanceReducer";
import orangeReducer from "./orangeReducer";

export default combineReducers({
  risktype: riskTypeReducer,
  claimCauses: claimCausesReducer,
  items: itemsPolicyReducer,
  auth: authReducer,
  userEvents: userEventsReducer,
  userInfo: userInfoReducer,
  news: newsReducer,
  agentBankInfo: agentBankReducer,
  agentDocumentInfo: agentDocumentReducer,
  notification: notificationReducer,
  balanceInquiry: balanceInquiryReducer,
  modalReducer: modalReducer,
  uploadedFiles: uploadFilesReducer,
  form: formReducer,
  qSiseDashboard: qSiseDashboardReducer,
  qSiseSinister: qSiseSinisterReducer,
  banks: bankReducer,
  configuration: configurationReducer,
  accountTypes: accountTypeReducer,
  userConfig: userConfigReducer,
  user: userReducer,
  bonusSummary: bonusSummaryReducer,
  medicalNetworkServices: medicalNetworkServicesReducer,
  medicalNetwork: medicalNetworkReducer,
  menuOptions: menuOptionsReducer,
  faq: faqReducer,
  paymentMethods: paymentMethodsReducer,
  listDocumentsType: listDocumentsTypeReducer,
  filesSinister: filesSinisterReducer,
  requestWF: requestWFReducer,
  collectiveRequests: massiveCollectiveRequestReducer,
  commentRequestWF: commentRequestWFReducer,
  trackingRequestWF: trackingRequestWFReducer,
  workflows: workflowReducer,
  workflowAvailableActions: workflowActionsReducer,
  workflowStates: workflowStatesReducer,
  workflowTypes: workflowTypesReducer,
  requestWorkflowGroup: requestWorkflowGroupReducer,
  qSiseDashboard: qSiseDashboardReducer,
  filter: filtersReducer,
  awards: awardReducer,
  banks: bankReducer,
  emergencies: emergencyReducer,
  endorsement: endorsementReducer,
  requestWFSingle: requestWFSingleReducer,
  configuration: configurationReducer,
  medicalNetworkServices: medicalNetworkServicesReducer,
  paginate: paginateReducer,
  userConfig: userConfigReducer,
  bonusSummary: bonusSummaryReducer,
  quoteAllPlans: quoteReducer,
  quote: createNamedWrapperQuoteReducer(quoteReducer, AUTOS),
  quoteHome: createNamedWrapperQuoteReducer(quoteReducer, HOGAR),
  quoteHealth: createNamedWrapperQuoteReducer(quoteReducer, SALUD),
  quoteLife: createNamedWrapperQuoteReducer(quoteReducer, VIDA),
  quoteMedicalExpenses: createNamedWrapperQuoteReducer(
    quoteReducer,
    GASTOS_MEDICOS
  ),
  insurance: insuranceReducer,
  uiData: combineReducers({
    year: yearReducer,
    brand: brandReducer,
    model: modelReducer,
    version: versionReducer,
    accesorie: accesorieReducer,
    profession: ocupationsReducer,
  }),
  agentList: agentListReducer,
  stateList: stateListReducer,
  lead: leadReducer,
  leadComment: leadCommentReducer,
  postalCode: postalCodeReducer,
  policy: policyReducer,
  fiscalCondition: fiscalConditionReducer,
  bankList: bankListReducer,
  cardList: cardListReducer,
  paymentMethodsList: paymentMethodListReducer,
  changePaymentMethod: updatePaymentMethodReducer,
  reasonsCancellation: reasonsReducer,
  saveGenerateCancel: generateCancelReducer,
  listDocumentsType: listDocumentsTypeReducer,
  filesSinister: filesSinisterReducer,
  documentsProposal: documentsProposalReducer,
  filesProposalProduct: filesProposalProduct,
  coins: coinReducer,
  actions: actionsReducer,
  medicalNetworkTypes: medicalNetworkTypesReducer,
  fileManagement: fileManagementReducer,
  sinister: sinisterReducer,
  paymentLinksPaymentProgess: paymentLinksMethodsReducer,
  attendanceAvailableByinsured: attendanceAvailableReducer,
  attendanceByInsured: getAttendanceByInsuredReducer,
  orange: orangeReducer
});
