import React, { useState, useEffect }  from 'react';
import { PropTypes } from 'prop-types';
import { Button, CircularProgress, Grid, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Warning, Close, Search } from '@material-ui/icons';
import GenericModal from '../modal/genericModal';
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { createAndGetPaymentLink, setIsPaymentLinkInPaymentProcess } from "../../actions/paymentsLinks";
import { getToken } from "../../utils/index";
import { useStyles } from "../../components/cardDetails/styles";

const PaymentLinkBTN = ({ selected }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMSG, setErrorMSG] = useState("");
  const existPendingPaymentLink = localStorage.getItem('persist:pendingPaymentLink') !== "false"

  const coinFormat = ({ value, currency = "USD" }) =>{
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
        maximumFractionDigits: 3  
      }).format(value)
  }

  const sumarValores = (objetos, propiedad) => {
    return objetos.reduce((sumatoria, objeto) => sumatoria + objeto[propiedad], 0);
  }

  const handleClickPendingPaymentLink = async ()  => {
    localStorage.setItem("openModalPendingPaymentLink", true ); 
    window.dispatchEvent(new CustomEvent('storageOpenModalPendingPaymentLink', { detail: { openModalPendingPaymentLink: true } }));
  }

  const handleClick = async ()  => {
    const token = getToken(); 
    const expiration_date_from = moment().format( 'YYYY-MM-DDTHH:mm:ss.000Z'); 
    const expiration_date_to = moment().add(1, 'days').format( 'YYYY-MM-DDTHH:mm:ss.000Z');
    setLoading(true);
    const codMoneda = selected[0]["cod_moneda"];
    const ramo = selected[0].descripcion_ramo; //falta agregar este campo tambien desde el SP
    const endoso = selected[0]["Endoso"];
    const poliza = selected[0]["nro_pol"];
    const poliza_endoso = `${poliza}∪${endoso}`;
    const codAsegPagador = selected[0]["Codigo_Asegurado"];
    const gettingTotalImport = sumarValores(selected, "Importe");
    const totalImport = Number(gettingTotalImport).toFixed(3);
    const totalReward = Number(gettingTotalImport).toFixed(3);

    const items = selected.map(item=>({
        title: `Ramo: ${item.descripcion_ramo}, Poliza: ${item.nro_pol}, Cuota: ${item.Cuota}, Tomador: ${item.Pagador}, Premio: ${coinFormat({ value: parseFloat(item.Importe) })}`,
        unit_price: item.Importe,//Number(item.importe_comision).toFixed(3),
        quantity: 1
    }))

    const quotas = selected.map(item=>({
        ramo: item.descripcion_ramo,
        nroRamo: item.cod_ramo,
        "Poliza / Endoso": `${item.nro_pol}∪${item.Endoso}`,
        nroPol: item.nro_pol,
        nroEndo: item.Endoso,
        codMoneda: item.cod_moneda,
        nroCuota: item.Cuota,
        codAsegPagador: item.Codigo_Asegurado,
        importe_premio: item.Importe,
        nombre_tomador: item.Pagador
    }))

    const paymentLinkData = {
        title: "Pago",
        description: `pago de cuotas por parte de ${selected[0].Pagador}`,
        user: selected[0].Pagador,
        userCode: codAsegPagador,
        external_reference: uuidv4(),
        items,
        email: "testLinkPayment_insured@yopmail.com",
        expiration_date_from,
        expiration_date_to, //add 24 hours    
        totalImport,    
        totalReward,
        prefix: ramo,
        quotas,
        quotasStr: poliza,
        redirectToMercadoPago: true
    }

    try{      
      const result = await createAndGetPaymentLink(token, paymentLinkData);

      setLoading(false);

      if(result && result.paymentLink){
        //localStorage.setItem("isPaymentLinkInPaymentProcess", true ); 
        window.dispatchEvent(new CustomEvent('storage', { detail: { isPaymentLinkInPaymentProcess:true } }));

        setTimeout(() => {
          window.open(result.paymentLink, "_blank");
        }, 1000);

      }else{
        
        const msj = result && result.rejectedQuotas && result.rejectedQuotas.length && result.rejectedQuotas[0] && result.rejectedQuotas[0].msj ? result.rejectedQuotas[0].msj : ""
        setShowError(true)
        setErrorMSG(`Ocurrio un problema tratando de pagar. ${msj}`)
      }
    }catch(err){
      
      setLoading(false);
      setShowError(true)
      setErrorMSG(err.data.data)

    }

} 
    return (
      <>
        <PopupAlert />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{ marginTop: "3%", marginBottom: "3%", textAlign: "-webkit-center"}}
        >
          <Grid item xs={12}>
            {
              !loading 
              ? selected && selected.length 
                ? <Button onClick={handleClick} className={classes.BtnPaymentLink}> Pagar </Button> : ""
              : <CircularProgress />  
            }
          </Grid>

          <Grid item xs={12}>
            {
              existPendingPaymentLink ? <Button onClick={handleClickPendingPaymentLink} className={classes.BtnPaymentLink}> Pagos pendientes </Button> : ""
            }
          </Grid>

          <GenericModal
              open={errorMSG}
              handleCancel={() => {
                setShowError(false)
                setErrorMSG("")
              }}
              title='Error al intentar pagar'
          >
            <p>{errorMSG}</p>
            <Grid style={{ textAlign: "center" }} >
              <Button 
                onClick={() => {
                  setShowError(false)
                  setErrorMSG("")
                }}
                className={classes.BtnPaymentLink}> 
                Cerrar 
              </Button>
            </Grid>
          </GenericModal>
        </Grid>
        {/* <div>
          <Alert severity="warning">Algunas cuotas pueden demorar hasta 1 hora en verificarse luego de haberlas pagado.</Alert>
        </div> */}
        
      </>
    );
}


const PopupAlert = () => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    {open && <Alert
      severity="warning"
      icon={<Warning />}
      action={
        <IconButton
          aria-label="Cerrar"
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          <Close fontSize="inherit" />
        </IconButton>
      }
      onClose={handleClose}
    >
      Por favor, habilite las ventanas emergentes en la configuración del navegador para el buen funcionamiento al momento de generar el pago.
    </Alert>}
    </>
  );
};

PaymentLinkBTN.propTypes = {
    selected: PropTypes.any
}

export default PaymentLinkBTN;