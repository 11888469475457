import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { AddLocation } from "@material-ui/icons";
import { MedicalCenterIcon, ProfessionalDoctorIcon } from "../../assets/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    card: {
      padding: "12px",
    },
    avatar: {
      width: 57,
      height: 57,
      cursor: "pointer",
      backgroundColor: theme.palette.primary.dark,
      "& .MuiSvgIcon-root": {
        width: 33,
        height: 33,
      },
    },
    title: {
      fontSize: 14,
      letterSpacing: 0.1,
      color: theme.palette.secondary.main,
      marginBottom: ".1rem",
      cursor: "pointer",
    },
    subheader: {
      fontSize: 8,
      letterSpacing: 0.25,
      textTransform: "uppercase",
      color: "#4B4B4B",
    },
    subtitle: {
      fontSize: 9,
      letterSpacing: "0.01071em",
      color: "#828282",
      textTransform: "none",
    },
  })
);

const getIconByType = (t) => {
  switch (t.toLowerCase()) {
    case "clínica":
      return <MedicalCenterIcon />;
    case "sucursal":
      return <MedicalCenterIcon />;
    case "profesional":
      return <ProfessionalDoctorIcon />;
    default:
      return <AddLocation />;
  }
};

const NetworkCard = ({
  index,
  style,
  data: { items, handleOptionsToggle, handleSelect, onChangeIndex, isMobile },
}) => {
  const classes = useStyles();
  const cardAction = () => {
    handleOptionsToggle(true);
    handleSelect(items[index]);

    isMobile && onChangeIndex(1);
  };
  const handleServicesName = () => {
    const str = items[index].services.map((ser) => ser.name).join(", ");

    return str.length > 100 ? `${str.substring(0, 100).trimEnd()} ...` : str;
  };

  return (
    <Box style={style} className={classes.root} onClick={() => cardAction()}>
      <Card className={classes.card}>
        <CardHeader
          classes={{ title: classes.title, subheader: classes.subheader }}
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              {getIconByType(items[index]?.type?.icon)}
            </Avatar>
          }
          title={`${items[index].name}`}
          subheader={
            <>
              <span>Servicios - {handleServicesName()}</span>

              <br />
              <span className={classes.subtitle}>
                {items[index].adress.street}
              </span>
            </>
          }
        />
      </Card>
    </Box>
  );
};

export default React.memo(NetworkCard);
