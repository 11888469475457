import {
  POST_UPLOAD_FILE_MANAGEMENT_PENDING,
  POST_UPLOAD_FILE_MANAGEMENT_FULFILLED,
  POST_UPLOAD_FILE_MANAGEMENT_REJECTED,
  POST_CHANGE_TEMPORALY_FILE_MANAGEMENT_FULFILLED,
  POST_CHANGE_TEMPORALY_FILE_MANAGEMENT_PENDING,
  POST_CHANGE_TEMPORALY_FILE_MANAGEMENT_REJECTED,
  RESET_UPLOAD_FILES,
  TEMPORARY_NUMBER_PENDING,
  TEMPORARY_NUMBER_FULFILLED,
  TEMPORARY_NUMBER_REJECTED
} from "../actions/types";

const initialState = {
  data: [],
  persistentsFiles: [],
  temporaryIdentityNumber: 0,
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POST_UPLOAD_FILE_MANAGEMENT_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case POST_UPLOAD_FILE_MANAGEMENT_FULFILLED:
      return {
        ...state,
        data:
          state.data.length > 0
            ? [...state.data, action.payload.data]
            : [action.payload.data],
        loading: false,
        error: false,
      };
    case POST_UPLOAD_FILE_MANAGEMENT_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    case POST_CHANGE_TEMPORALY_FILE_MANAGEMENT_FULFILLED:
      return {
        ...state,
        persistentsFiles:
          state.persistentsFiles.length > 0
            ? [...state.persistentsFiles, action.payload.data]
            : [action.payload.data],
        loading: false,
        error: false,
      };
    case POST_CHANGE_TEMPORALY_FILE_MANAGEMENT_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case POST_CHANGE_TEMPORALY_FILE_MANAGEMENT_REJECTED:
      return {
        ...state,
        persistentsFiles: [],
        loading: false,
        error: true,
      };
    case RESET_UPLOAD_FILES:
      return {
        ...state,
        data: []
      };
    case TEMPORARY_NUMBER_PENDING:
      return {
        ...state,
        temporaryIdentityNumber: 0
      };
    case TEMPORARY_NUMBER_FULFILLED:
      return {
        ...state,
        temporaryIdentityNumber: action.payload
      };
    case TEMPORARY_NUMBER_REJECTED:
      return {
        ...state,
        temporaryIdentityNumber: 0
      };
    default:
      return state;
  }
}
