import React from 'react'
import { useStyles } from './style';
import { Box, Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import { CheckCircleOutline, FlipCameraAndroid, GetApp, InsertDriveFileOutlined, SendOutlined } from '@material-ui/icons';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ActivityTracker = () => {
  const classes = useStyles();
  const activities = [
    {
      type: 'accepted',
      description: 'La solicitud  EIV-026 ha sido aceptada',
      status: 'success',
      date: ''
    },
    {
      type: 'accepted',
      description: 'Renovaste el seguro de Auto Juana',
      status: 'default',
      date: 'Hace 4 hs'
    },
    {
      type: 'file',
      description: 'Se creó tu solicitud EIV-026',
      status: 'default',
      date: 'Hace 4 hs'
    },
    {
      type: 'file',
      description: 'Pagaste cuota Noviembre Poliza #4562213',
      status: 'default',
      date: 'Hace 55 días. 15/02/2021 12:35'
    },
    {
      type: 'pending',
      description: 'La denuncia EIV-026 está siendo revisada por nuestros agentes. Esto pueder demorar hasta 10 días hábiles. Por favor chequea tu correo electronico y notificaciones. Si luego plazo mencionado no recibes noticias por favor comunicate con nosotros.',
      status: 'default',
      date: 'Hace 55 días. 15/02/2021 12:35'
    },
    {
      type: 'sent',
      description: 'Enviaste solicitud para denuncia Auto Juana. Codigo de solicitud EIV-026',
      status: 'default',
      date: 'Hace 55 días. 15/02/2021 12:35'
    },
  ]

  const getIconByType = (type) => {
    switch(type) {
      case 'accepted': return <CheckCircleOutline />;
      case 'file': return <InsertDriveFileOutlined />;
      case 'pending': return <FlipCameraAndroid />;
      case 'sent': return <SendOutlined />;
      default: return null;
    }
  }
  return (
    <div style={{ marginTop: 60 }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.title}>
            Última actividad
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper classes={{ root: classes.activityContainer }} square>
            <Tabs
              value={0}
              indicatorColor="primary"
              textColor="primary"
              aria-label="Activity Tabs"
            >
              <Tab label="Tracking" />
            </Tabs>
          </Paper>
          <TabPanel value={0} index={0}>
            <div className={classes.activityList}>
              {activities.map((act, i) => (
                <div key={`activity__${i}`} className={classes.activityItem}>
                  <div>
                    <i>{getIconByType(act.type)}</i>
                    {act.type === 'file' && <span>PDF <GetApp /></span>}
                    <Typography>
                      {act.description}
                    </Typography>
                  </div>
                  <div>
                    <Typography>
                      {act.date}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  )
}

export default ActivityTracker