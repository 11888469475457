import { createAsyncAction } from 'redux-promise-middleware-actions';
import {
    urlBaseApi,
    exceptionCodeResponse,
} from '../utils/urls';
import { GET_PAYMENT_LINKS } from "../actions/types";

export const getPaymentLinksAPI = createAsyncAction(
        GET_PAYMENT_LINKS,
        async (token, userCodes) => {

    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    const status = ['activo']

    const body = JSON.stringify({userCodes, status});

    const res = await fetch(`${urlBaseApi}paymentLink/byUserCodesAndStatus`, {
        method: 'POST',
        headers,
        body,
    })
        .then((response) => {
            return validateJSONResponse(response);
        })
        .catch((error) => {
            throw exceptionCodeResponse({
                error: true,
                data: error
            })
        });

    return res;

})

export const getPaymentLinksAPI_ = async (token, userCodes) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    const status = ['activo']

    const body = JSON.stringify({userCodes, status});

    const res = await fetch(`${urlBaseApi}paymentLink/byUserCodesAndStatus`, {
        method: 'POST',
        headers,
        body,
    })
        .then((response) => {
            return validateJSONResponse(response);
        })
        .catch((error) => {
            throw exceptionCodeResponse({
                error: true,
                data: error
            })
        });

    return res;
}

export const createAndGetPaymentLinkAPI = async (token, bodyReq, dispatch) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    const body = JSON.stringify(bodyReq);

    const res = await fetch(`${urlBaseApi}paymentLink/create`, {
        method: 'POST',
        headers,
        body,
    })
        .then((response) => {
            return validateJSONResponse(response);
        })
        .catch((error) => {
            throw exceptionCodeResponse({
                error: true,
                data: error
            })
        });

    return res;

}

export const validateJSONResponse = async (response) => {
    if (response.ok) {
        return response.json();
    } else {
        const errMsg = await response.json(); 

        throw exceptionCodeResponse({
            error: true,
            data: errMsg.error
        })
    }
};