import React from "react";
import moment from "moment";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { Typography, CircularProgress } from "@material-ui/core";
import { CancelPresentation, RoomOutlined } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

const VehicleLocationCard = ({
  classes,
  policy,
  isLoaded,
  refetching = true,
}) => {
  const statusColors = {
    28: "orange",
    37: "#121212",
    36: "#219653",
  };

  const getLabelByStatus = (status) => {
    switch (status) {
      case "28":
        return "FUERA DE ALCANCE GPS";
      case "37":
      case "41":
      case "21":
        return "VEHICULO DETENIDO";
      case "36":
        return "VIAJE EN CURSO";
      default:
        return null;
    }
  };

  const isOnGoing = (status) => {
    return status == 36;
  };

  const isStopped = (status) => {
    return status == 37 || status == 41 || status == 21;
  };

  const goToMapDetails = (coords) => {
    window.open(
      `https://www.google.com/maps/@${coords.lat},${coords.lon},12z`,
      "_blank"
    );
  };

  const fetchingPlaceholder = (
    <div className={classes.fetchingPlaceholder}>
      <Skeleton
        animation="wave"
        width="100%"
        height={350}
        style={{
          transform: "scale(1)",
          borderRadius: "0px 0px 15px 15px",
          backgroundColor: "rgba(0, 0, 0, 0.005)",
        }}
      />
      <p>Cargando...</p>
    </div>
  );

  return (
    <div
      className={`${classes.orangeCard} ${refetching ? "refetching" : ""}`}
      style={{
        flex: 1,
        maxWidth: "100%",
        height: "100%",
      }}
    >
      <div className={classes.orangeCardHeader}>
        <div style={{ width: "60%" }}>
          <RoomOutlined color="primary" />
          <Typography>Ubicacion actual</Typography>
        </div>
        {refetching ? (
          <CircularProgress size={16} />
        ) : (
          <div>
            <Typography
              style={{
                color: isOnGoing(policy.status[0].code)
                  ? "#219653"
                  : statusColors[policy.status[0].code],
              }}
            >
              {getLabelByStatus(policy.status[0].code)}
            </Typography>
            <span
              style={{
                borderColor: isOnGoing(policy.status[0].code)
                  ? "#219653"
                  : statusColors[policy.status[0].code],
              }}
            />
          </div>
        )}
      </div>
      {isLoaded ? (
        <>
          <div
            className={classes.orangeMap}
            onClick={() =>
              !isStopped(policy.status[0].code)
                ? goToMapDetails(policy.travel.location)
                : null
            }
          >
            <MapContainer
              center={[policy.travel.location.lat, policy.travel.location.lon]}
              zoom={16}
              scrollWheelZoom={false}
              dragging={false}
              attributionControl={false}
              zoomControl={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker
                position={[
                  policy.travel.location.lat,
                  policy.travel.location.lon,
                ]}
              />
            </MapContainer>
            {/* <img onClick={() => goToMapDetails(policy.travel.location)} src={MapPlaceholder} alt="Map placeholder" className={!isStopped(policy.status[0].code) ? 'disabled' : ''} /> */}
            {!isStopped(policy.status[0].code) && (
              <div className="map-overlay">
                <CancelPresentation />
                <Typography>
                  Solo puedes ver tu ubicacion actual mientras el auto este
                  detenido
                </Typography>
              </div>
            )}
          </div>
          <div className={classes.orangeMapFooter}>
            <Typography>{policy.travel.location.address}</Typography>
            <span>
              Actualizado{" "}
              {moment(policy.travel.location.last_updated).format(
                "DD/MM/YYYY, hh:mm A"
              )}
            </span>
          </div>
        </>
      ) : (
        fetchingPlaceholder
      )}
    </div>
  );
};

export default VehicleLocationCard;
