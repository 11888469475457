const initialState = {
  filterList: [],
};

const filterNewRules = (rules, newRule) => {
  if (!newRule) {
    return rules;
  }

  if (!rules.length) {
    return [newRule];
  }

  const typeTarget = newRule.type;
  const filteredRules = rules.filter((item) => item.type !== typeTarget);

  const mergedFilter = [...filteredRules, newRule];

  return mergedFilter;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_FILTER_LIST":
      const filterList = filterNewRules(state.filterList, action.payload);

      return {
        ...state,
        filterList,
      };
    case "DELETE_FILTER_LIST":
      const newFilterList = state.filterList.filter((el) => {
        return el !== action.payload;
      });
      return {
        ...state,
        filterList: newFilterList,
      };
    case "RESET_FILTER_LIST":
      return {
        filterList: [],
      };
    default:
      return state;
  }
}
