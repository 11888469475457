import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  activityContainer: {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15
  },
  title: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '36px',
    letterSpacing: -1,
    color: '#333333',
    fontFamily: 'Work Sans',
    marginBottom: 32
  },
  activityList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: 436,
    overflow: 'auto',
    marginBottom: 40,
    '& > div:first-child': {
      backgroundColor: '#E2F5EA !important'
    },
    '& > div:nth-child(odd)': {
      backgroundColor: '#fff'
    },
    '& > div:nth-child(even)': {
      backgroundColor: '#F7F7F7'
    },
  },
  activityItem: {
    padding: '20px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div:first-child': {
      display: 'flex',
      alignItems: 'center',
      gap: 12,
      '& span': {
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        gap: 8,
        borderRadius: 10,
        padding: '5px 8px',
        backgroundColor: theme.palette.variants && theme.palette.variants.variant1,
        color: theme.palette.primary && theme.palette.primary.main,
        fontSize: 14,
        letterSpacing: 0.1,
        fontWeight: 500,
        lineHeight: '24px'
      },
      '& p': {
        fontSize: 13,
        lineHeight: '18px',
        letterSpacing: 0.25,
        fontWeight: 400,
        color: '#000',
        maxWidth: 820
      }
    },
    '& > div:last-child': {
      '& p': {
        fontSize: 10,
        lineHeight: '18px',
        letterSpacing: 0.25,
        fontWeight: 400,
        color: '#828282'
      }
    }
  }
}));