import {
  GET_VERSIONS_PENDING,
  GET_VERSIONS_FULFILLED,
  GET_VERSIONS_REJECTED,
  PATCH_VERSIONS_ERROR,
} from '../actions/types';
import { dataVersion } from '../utils/dataSet';

const initialState = {
  data: dataVersion,
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VERSIONS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_VERSIONS_FULFILLED:
      return {
        ...state,
        data: { ...state.data, content: [...action.payload] },
        loading: false,
        error: false,
      };
    case GET_VERSIONS_REJECTED:
      return {
        ...state,
        data: dataVersion,
        loading: false,
        error: true,
      };
    case PATCH_VERSIONS_ERROR:
      return {
        ...state,
        error: false,
      };
    default:
      return state;
  }
}
