import { createAsyncAction } from 'redux-promise-middleware-actions';
import {
    urlBaseApi,
    validateJSONResponse,
    exceptionCodeResponse
} from '../utils/urls';
import { GET_AGENT_LIST, GET_STATE_LIST, SEND_MAIL } from '../actions/types';

export const getPolicyListAPI = createAsyncAction(
    GET_AGENT_LIST,
    async (token, query) => {
        const options = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(query)
        };
        const res = await fetch(`${urlBaseApi}/policy/policyList`, options)
            .then((response) => validateJSONResponse(response))
            .catch((error) => {
                throw exceptionCodeResponse(error);
            });
        return res;
    }
);
