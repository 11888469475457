import React, { useState } from "react";
import { Drawer, IconButton, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useStyles } from "./styles";
import { Close } from "@material-ui/icons";
import GetApp from "@material-ui/icons/GetApp";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { useDispatch, useSelector } from "react-redux";
import { postGenerateSinisterPdf } from "../../actions/sinisterActions";
import { showError } from "../../actions/notificationActions";
import { navigate } from "@reach/router";

/**
 * @name DownloadDrawer
 * @description Drawer to show ID sinister and download option
 * @param openDrawer bool
 * @returns
 */
const DownloadDrawer = ({ openDrawer, setOpen, sinisterData = 0 }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [disableDownloadButton, setDisableDownloadButton] = useState(false);

  const downloadPdf = async () => {
    setDisableDownloadButton(true);
    try {
      await dispatch(
        postGenerateSinisterPdf(token, {
          idStro: sinisterData?.mensaje["Id. Siniestro"],
        })
      );
      setDisableDownloadButton(false);
    } catch (error) {
      // console.log('Error Sinister downloadPdf', error);
      dispatch(showError("Error al generar PDF, intente nuevamente."));
      setDisableDownloadButton(false);
    }
  };

  const closeDrawer = () => {
    setOpen(false);
    setTimeout(() => {
      navigate("/dashboard");
    }, 500);
  };

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={() => closeDrawer()}
      classes={{
        paper: classes.DownloadDrawer,
      }}
    >
      <header className={classes.headerDrawer}>
        <div className={classes.titleContainer}>
          <span>Siniestro creado con éxito!</span>
        </div>
        <IconButton aria-label="close details" onClick={() => closeDrawer()}>
          <Close style={{ color: "#fff" }} />
        </IconButton>
      </header>
      <div className={classes.DownloadDrawerArea}>
        <div className={classes.DownloadDrawerContent}>
          <div className={classes.DownloadDrawerTitle}>
            <span>
              Se generó el siniestro número:{" "}
              {sinisterData?.mensaje &&
              sinisterData?.mensaje["Ejercicio"] !== undefined
                ? sinisterData?.mensaje["Ejercicio"]
                : ""}
              {"-"}
              {sinisterData?.mensaje &&
              sinisterData?.mensaje["Nro. Siniestro"] !== undefined
                ? sinisterData?.mensaje["Nro. Siniestro"]
                : ""}
            </span>
          </div>
        </div>
        <div className={classes.DownloadDrawerContainerButton}>
          <IconButton
            onClick={downloadPdf}
            aria-label="download"
            className={classes.DownloadDrawerButton}
          >
            <Typography
              variant="body2"
              color="primary"
              style={{ margin: "5px" }}
            >
              Descargar PDF
            </Typography>
            <CloudDownloadIcon color="primary" fontSize="small" ml={2} />
          </IconButton>
          <Button
            onClick={() => closeDrawer()}
            aria-label="download"
            className={classes.DownloadDrawerButton}
          >
            <Typography
              variant="body2"
              color="primary"
              style={{ margin: "5px" }}
            >
              Cerrar
            </Typography>
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default DownloadDrawer;
