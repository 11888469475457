import {
  postSinisterInsuredAPI,
  getSinisterPolicyDetailAPI,
  getSinisterPolicyCoverageAPI,
  getSinisterCollisionTypesAPI,
  getFileSinisterByCodRamTecAPI,
  getSinisterPolicyItemsAPI,
  downloadSinisterPdfAPI
} from "../sevices/sinisterService";

export const postSinister = (token, body) => async (dispatch) => {
  return await dispatch(postSinisterInsuredAPI(token, body, dispatch));
};

export const getSinisterPolicyDetail =
  (token, codigo_usuario, cod_ramo, nro_pol, fecha_ocurrencia) => async (dispatch) => {
    await dispatch(
      getSinisterPolicyDetailAPI(token, codigo_usuario, cod_ramo, nro_pol, fecha_ocurrencia)
    );
  };

export const getSinisterPolicyCoverage =
  (token, nro_pol, nro_endo, cod_ramo, cod_causa, cod_item) =>
  async (dispatch) => {
    await dispatch(
      getSinisterPolicyCoverageAPI(
        token,
        nro_pol,
        nro_endo,
        cod_ramo,
        cod_causa,
        cod_item
      )
    );
  };

export const getSinisterCollisionTypes = (token) => async (dispatch) => {
  await dispatch(getSinisterCollisionTypesAPI(token));
};

export const getFileSinisterByCodRamTec =
  (cod_ram_tec) => async (dispatch) => {
    await dispatch(getFileSinisterByCodRamTecAPI(cod_ram_tec));
  };

export const getSinisterPolicyItems =
  (token, cod_ramo, nro_pol, fecha_ocurrencia) => async (dispatch) => {
    return await dispatch(
      getSinisterPolicyItemsAPI(token, cod_ramo, nro_pol, fecha_ocurrencia)
    );
};

export const postGenerateSinisterPdf = (token, body) => async (dispatch) => {
  return await dispatch(downloadSinisterPdfAPI(token, body));
};