import {
    GET_FAQS,
    GET_FAQS_FULFILLED,
    GET_FAQS_REJECTED,
    GET_FAQS_PENDING
  } from "../actions/types";
  
  const initialState = {
    data: {},
    loading: false,
    error: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_FAQS_PENDING:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case GET_FAQS_FULFILLED:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case GET_FAQS_REJECTED:
        return {
          ...state,
          data: {},
          loading: false,
          error: true,
        };
      case GET_FAQS:
        return {
          ...state,
          data: {},
        };
      default:
        return state;
    }
  }
  