import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@reach/router";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {
  postLogin,
  patchErrorLogin,
  setAuthToken,
} from "../actions/authActions";
import { useInputValue } from "../hooks/useInputValue";
import LoadingModal from "../components/modal/loadingModal";
import MessageFailure from "../components/messages/messageFailure";
import { authUrlBase } from "../utils/urls";
import { saveToken } from "../utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = ({ location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const user = useInputValue("");
  const password = useInputValue("");

  const token = new URLSearchParams(location.search).get("token");

  if (!auth.token && token) {
    localStorage.clear();
    dispatch(setAuthToken(token));
  }

  useEffect(() => {
    if (auth.token) {
      saveToken(auth.token);
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token]);

  const handleSubmitUser = (event) => {
    event.preventDefault();
    if (user.value && password.value) {
      const credential = { email: user.value, password: password.value };
      dispatch(postLogin(credential));
    }
  };

  const handleErrorLogin = () => {
    dispatch(patchErrorLogin());
  };

  if (!token) {
    return window.location.assign(
      `${authUrlBase}login/insured?redirect=${window.location.origin}`
    );
  }

  return (
    <Grid container justifyContent="center" className="appContainer">
      {auth.loading && <LoadingModal />}
      {auth.error && <MessageFailure close={handleErrorLogin} />}
    </Grid>
  );
};

export default Login;
