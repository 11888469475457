import React, { useEffect, useState, Fragment } from 'react';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    CircularProgress
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import NumberFormat from 'react-number-format';
import '../styles.css'

export const renderTextField = ({
    input,
    label,
    multiline,
    rows, 
    classes,
    custom: { tabIndex, placeholder, size },
    variant,
    meta: { touched, error },
    endAdornment,
    errorInput
}) => {
    return (
        <Fragment>
            <TextField
                {...input}
                className={`formInput ${classes}`}
                label={label}
                error={touched && !!error}
                helperText={touched && !!error ? error : false}
                placeholder={placeholder}
                inputProps={{ tabIndex }}
                autoComplete="none"
                InputProps={{
                    endAdornment: endAdornment
                }}
                multiline={multiline ? multiline : false}
                rows={rows ? rows : 1}
                fullWidth
                variant={variant ? variant : 'standard'}
                size={size ? size : 'small'}
            />

            <p className="error-dynamic-input">{errorInput}</p>
        </Fragment>
    );
};

export const RenderSearchSelectField = ({
    input: { onBlur, value, ...otherInputProps },
    name,
    custom: { tabIndex, placeholder, variant, size },
    fieldData,
    defaultValue,
    handleChangeSelect,
    errorInput
}) => {
    return (
        <>
            <div className="loading-select-search">
                <CircularProgress />
            </div>
        </>
    );
};

export const RenderSearchSelectFieldSimple = ({
    input: { onBlur, name, ...otherInputProps },
    custom: { tabIndex, placeholder, variant, size },
    options,
    defaultValue,
    printOptions,
    multiple,
    handleChangeSelect,
    limitedOptions,
    disabled,
    nothingText = 'No se consiguieron coincidencias'
  }) => {
    const [myValue, setSetMyValue] = useState('');
    const handleSelect = (item,algo) => {
      setSetMyValue(item);
      handleChangeSelect(item);
    }
    useEffect(() => {
      if (defaultValue && options) {
        setSetMyValue(defaultValue || '');
      }
    }, [defaultValue, options])
  
    const fuzzyOptions = (options) => {
      let sortedOptions = [...options]
      if(myValue){
        sortedOptions.sort((optionA,optionB) => {
          if ( optionA.value === myValue ) {
            return -1
          }else if(optionB.value === myValue) {
            return 1
          }
          return 0
        })
      }
      const filter = fuzzySearch(sortedOptions);
      const filterFunction = limitedOptions ? (q) => filter(q).slice(0, limitedOptions) : filter
  
      return filterFunction
    }
  
  
    return (
      <>
        <TextField
          type="text"
          className="formInput"
          style={{ display: 'none' }}
          name={name}
          {...otherInputProps}
          value={myValue}
        />
        <SelectSearch
          options={options || []}
          search
          filterOptions={fuzzyOptions}
          placeholder={placeholder}
          printOptions={printOptions}
          onChange={handleSelect}
          value={myValue}
          disabled={disabled}
          emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>{nothingText}</div>}
          multiple={multiple}
        />
        {
          printOptions ?
            <></> :
            <ArrowDropDown className="input-icon-down" />
        }
      </>
    )
  }

export const RenderDecimalField = ({
    input,
    label,
    disabled,
    custom: { tabIndex, placeholder, variant, size },
    meta: { touched, error },
    handleChange,
    defaultValue,
    errorInput
}) => {
    const [valor, setValor] = useState(defaultValue);
    useEffect(() => {
        setValor(defaultValue);
    }, [defaultValue]);
    return (
        <Fragment>
            <NumberFormat
                {...input}
                label={label}
                customInput={TextField}
                error={!disabled && touched && !!error}
                helperText={!disabled && touched && !!error ? error : false}
                disabled={disabled}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                style={{
                    marginTop: -3,
                    width: '100%'
                }}
                value={valor}
                onChange={(e) => {
                    setValor(e.target.value);
                    if (handleChange) {
                        handleChange(e.target.name, e.target.value);
                    }
                }}
                isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === '' || floatValue <= 999999999;
                }}
            />
            <p className="error-dynamic-input">{errorInput}</p>
        </Fragment>
    );
};

export const RenderTextFieldDisabled = ({
    input,
    label,
    multiline,
    rows,
    classes,
    value,
    custom: { tabIndex, placeholder, size },
    variant,
    meta: { touched, error },
    endAdornment,
    errorInput
}) => (
    <Fragment>
        <TextField
            {...input}
            className={`formInput disabled-quote-input`}
            label={label}
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            placeholder={placeholder}
            inputProps={{ tabIndex }}
            InputProps={{
                endAdornment: endAdornment
            }}
            multiline={multiline ? multiline : false}
            rows={rows ? rows : 1}
            fullWidth
            variant={variant ? variant : 'standard'}
            size={size ? size : 'small'}
            disabled
        />
        <p className="error-dynamic-input">{errorInput}</p>
    </Fragment>
);

export const RenderTextFieldRow = ({
    input,
    label,
    multiline,
    rows,
    classes,
    custom: { tabIndex, placeholder, size },
    variant,
    meta: { touched, error },
    endAdornment,
    handleChange,
    defaultValue,
    errorInput,
    disabled = false
}) => {
    const [valor, setValor] = useState(defaultValue);
    useEffect(() => {
        setValor(defaultValue);
    }, [defaultValue]);
    return (
        <Fragment>
            <TextField
                {...input}
                className={`formInput ${classes} ${
                    disabled && 'disabled-quote-input'
                }`}
                label={label}
                error={touched && !!error}
                helperText={touched && !!error ? error : false}
                placeholder={placeholder}
                inputProps={{ tabIndex }}
                InputProps={{
                    endAdornment: endAdornment
                }}
                multiline={multiline ? multiline : false}
                rows={rows ? rows : 1}
                fullWidth
                variant={variant ? variant : 'standard'}
                size={size ? size : 'small'}
                value={valor}
                onChange={(e) => {
                    setValor(e.target.value);
                    if (handleChange) {
                        handleChange(e.target.name, e.target.value);
                    }
                }}
                disabled={disabled}
            />
            <p className="error-dynamic-input">{errorInput}</p>
        </Fragment>
    );
};

export const renderCheckbox = ({
    input,
    name,
    label,
    disabled = false,
    defaultValue
}) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    color="primary"
                    name={input.name}
                    disabled={disabled}
                    checked={defaultValue || false}
                    onChange={input.onChange}
                />
            }
            label={label}
        />
    );
};
