import {
    GET_CLAIM_CAUSES_REJECTED,
    GET_CLAIM_CAUSES_PENDING,
    GET_CLAIM_CAUSES,
    GET_CLAIM_CAUSES_FULFILLED
} from '../actions/types';

const initialState = {
    data: {},
    loading: false,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CLAIM_CAUSES_PENDING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case GET_CLAIM_CAUSES_FULFILLED:
            return {
                ...state,
                data: { ...state.data, ...action.payload },
                loading: false,
                error: false
            };
        case GET_CLAIM_CAUSES_REJECTED:
            return {
                ...state,
                data: [],
                loading: false,
                error: true
            };
        case GET_CLAIM_CAUSES:
            return {
                ...state,
                selected: action.payload
            };
        default:
            return state;
    }
}
