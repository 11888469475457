import React from 'react';
import { Grid } from '@material-ui/core';
import { News } from 'news-list';
import { useSelector } from 'react-redux';
import { quotationUrlBase } from '../../utils/urls';

const NewsMain = () => {
    const token = useSelector((state) => state.auth.token);
    const query = { query: 'visibility=/1757/i' };
    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 64 }}>
                <News token={token} url={quotationUrlBase} query={query} />
            </Grid>
        </Grid>
    );
};

export default NewsMain;
