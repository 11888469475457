import {
    SET_IS_PAYMENT_LINK_IN_PAYMENT_PROCESS,
    GET_PAYMENT_LINKS_PENDING,
    GET_PAYMENT_LINKS_REJECTED,
    GET_PAYMENT_LINKS_FULFILLED,
} from '../actions/types';

const initialState = {
    data: [],
    filteredData: [],
    loading: false,
    error: false,
    isPaymentInprocess: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PAYMENT_LINKS_PENDING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case GET_PAYMENT_LINKS_FULFILLED:
            return {
                ...state,
                data: action.payload,
                filteredData: action.payload,
                loading: false,
                error: false
            };
        case GET_PAYMENT_LINKS_REJECTED:
            return {
                ...state,
                data: [],
                loading: false,
                error: true
            };
        case SET_IS_PAYMENT_LINK_IN_PAYMENT_PROCESS:
            return {
                ...state,
                isPaymentInprocess: action.payload,
            };
        default:
            return state;
    }
}
