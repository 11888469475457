import React, { useState } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
    renderTextField,
    renderNumberField,
    CustomUploadFileAsync
} from '../../form/renderInputsForm';
import { renderPhoneField } from '../../inputValidator/renderInputsForm';
import { useStyles } from '../styles';

const ViolenceForm = () =>{
    const classes = useStyles();
    const [disableSave, setDisableSave] = useState(false);
    const [settingField, setSettingField] = useState({
        identifierType: "sinesterVideo",
        branch: null,
        isTemporal: true,
      });

    return(<>
        <Field 
            name="callEmergency_V" 
            label="¿Llamó al 911? ¿Solicitó ayuda de alguien? En caso afirmativo, aporte por favor los datos de dicha/as persona/as" 
            component={renderTextField}
            custom={{ tabIndex: 0 }}
            rows={4}
            multiline
        />
        <Field 
            name="documentation_V" 
            label="¿En el interior del vehículo había documentación y/o elementos de valor cuando fue robada? Mencione cuáles." 
            component={renderTextField}
            custom={{ tabIndex: 1 }}
            rows={4}
            multiline
        />
        <Field 
            name="witnesses_V"  
            label="¿Posee testigos del hecho? En caso afirmativo, aporte sus datos de contacto y nombre completo" 
            component={renderTextField}
            custom={{ tabIndex: 2 }}
            rows={4}
            multiline
        />
        <Field 
            name="cameras_V" 
            label="¿En el lugar de ocurrencia hay cámaras de seguridad públicas o privadas? Mencione dónde y si fueron solicitadas" 
            component={renderTextField}
            custom={{ tabIndex: 4 }}
            rows={4}
            multiline
        />
        <Field 
            name="carUse_V" 
            label="¿Qué uso le daba al vehículo?" 
            component={renderTextField}
            custom={{ tabIndex: 5 }}
            rows={4}
            multiline
        />
        <Grid item xs={12} md={12}> <p>Datos del asegurado: </p> </Grid>
        <Grid item xs={12} md={6} style={{ padding: 8 }}>
            <Field
                name="occupation_V"
                label="Ocupación"
                component={renderTextField}
                custom={{ tabIndex: 6 }}
                classes={classes.fontSizeLabel}
            />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 8 }}>
            <Field
                name="name_V"
                label="Apellido y Nombre"
                component={renderTextField}
                custom={{ tabIndex: 7 }}
                classes={classes.fontSizeLabel}
            />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 8 }}>
            <Field
                name="typeNumberDocument_V"
                label="Tipo y numero de documento"
                component={renderTextField}
                custom={{ tabIndex: 8 }}
                classes={classes.fontSizeLabel}
            />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 8 }}>
            <Field
                name="homeAddress_V"
                label="Domicilio real"
                component={renderTextField}
                custom={{ tabIndex: 9 }}
                classes={classes.fontSizeLabel}
            />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 8 }}>            
            <Field
                name="workAddress_V"
                label="Domicilio laboral"
                component={renderTextField}
                custom={{ tabIndex: 10 }}
                classes={classes.fontSizeLabel}
            />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 8 }}>
            <Field
                name="mail_V"
                label="E-mail"
                component={renderTextField}
                custom={{ tabIndex: 11 }}
                classes={classes.fontSizeLabel}
            />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 8 }}>
            <Field
                name="phoneNumber_V"
                label="Teléfono celular"
                component={renderPhoneField}
                custom={{ tabIndex: 12 }}
                classes={classes.fontSizeLabel}
            />
        </Grid>
        <Field 
            name="delayReasons_V" 
            label="Si se demoró en realizar la denuncia policial y/o la del siniestro ante esta aseguradora, indique por favor los motivos de dicha demora." 
            component={renderTextField}
            custom={{ tabIndex: 13 }}
            rows={4}
            multiline
        />
        <Field 
            name="insuredOtherCompanies_V" 
            label="¿El vehículo estuvo asegurado en otras compañías? ¿Cuáles?" 
            component={renderTextField}
            custom={{ tabIndex: 15 }}
            rows={4}
            multiline
        />
        <Field
            name="kilometres_V"
            label="¿Qué kilometraje aproximadamente recorrido tenía al momento del hecho?"
            component={renderNumberField}
            custom={{ tabIndex: 14 }}
            classes={classes.fontSizeLabel}
        />
        <Grid container style={{marginBottom:"3%", marginTop:"3%"}}>
            <div className={classes.note}>
                <p className={classes.noteHeading}>Nota:</p>
                <p>
                    Si cuenta con algún archivo con formato de video que aporte información para el Siniestro, le agradecemos lo envíe como un anexo al correo electrónico de{' '}
                    <span className={classes.email}>robototal@libraseguros.com.ar</span> indicando en el asunto el Número de Siniestro y nombre del Tomador.
                </p>
            </div>
        </Grid>
        {/* <Grid container style={{marginBottom:"3%", marginTop:"3%"}}>
            <Field
                name={"video_V"}
                label={"¿Posee video del hecho? En caso afirmativo, apórtelo" }
                custom={{ tabIndex: 1 }}
                component={CustomUploadFileAsync}
                settings={settingField}
                disabled={disableSave}
                fileTypes={".mp4, .webm, .ogg, .avi, .mpeg, .mpg, .mov, .wmv"}
                maxSize={20000}
                required
                footerDescription={
                    "Tamaño de video permitido 20MB."
                }
                helperText="Campo es requerido"
            />
        </Grid> */}
    </>)
}

export default ViolenceForm;