// export const urlBase = "http://localhost:8886/";
// export const quotationUrlBase = "http://localhost:8088/";
// export const authUrlBase = "http://localhost:8090/";
// export const eventsUrlBase = "http://localhost:8089/";
// export const insuredApi = "http://localhost:8091/"
// export const urlBaseFront = window.location.origin;

export const urlBase =
  (window._env_ ? window._env_.REACT_APP_AGENT_API_URL : false) ||
  process.env.REACT_APP_AGENT_API_URL;
export const urlBaseApi = `${urlBase}v1/`;

export const urlList =
  (window._env_ ? window._env_.REACT_APP_LISTS_URL : false) ||
  process.env.REACT_APP_LISTS_URL;

export const quotationUrlBase =
  (window._env_ ? window._env_.REACT_APP_COTIZADOR_API_URL : false) ||
  process.env.REACT_APP_COTIZADOR_API_URL;

export const quotationUrlApi = `${quotationUrlBase}v1/`;

export const quotationUrlV2Api = `${quotationUrlBase}v2/`;

export const authUrlBase =
  (window._env_ ? window._env_.REACT_APP_AUTH_API_URL : false) ||
  process.env.REACT_APP_AUTH_API_URL;
export const authUrlApi = `${authUrlBase}v1`;

export const eventsUrlBase =
  (window._env_ ? window._env_.REACT_APP_EVENTS_API_URL : false) ||
  process.env.REACT_APP_EVENTS_API_URL;
export const eventsUrlApi = `${eventsUrlBase}v1/`;

export const insuredApi =
  (window._env_ ? window._env_.REACT_APP_INSURED_API_URL : false) ||
  process.env.REACT_APP_INSURED_API_URL;
export const insuredUrlApi = `${insuredApi}v1/`;

export const inspectionApiUrl =
  (window._env_ ? window._env_.REACT_APP_INSPECTION_API_URL : false) ||
  process.env.REACT_APP_INSPECTION_API_URL;
export const EVENTS_MODULES = ["AUTH"];

export const SISE_URL =
  (window._env_ ? window._env_.REACT_APP_SISE_URL : false) ||
  process.env.REACT_APP_SISE_URL;

// ID SISE QUERY
export const ID_SISE_AGENT_PORTFOLIO =
  (window._env_ ? window._env_.REACT_APP_ID_SISE_AGENT_PORTFOLIO : false) ||
  process.env.REACT_APP_ID_SISE_AGENT_PORTFOLIO;

export const ID_SISE_AWARD_LIST =
  (window._env_ ? window._env_.REACT_APP_ID_SISE_AWARD_LIST : false) ||
  process.env.REACT_APP_ID_SISE_AWARD_LIST;

export const ID_SISE_BLACKBERRY_LIST =
  (window._env_ ? window._env_.REACT_APP_ID_SISE_BLACKBERRY_LIST : false) ||
  process.env.REACT_APP_ID_SISE_BLACKBERRY_LIST;

export const ID_SISE_COMMISSION_LIST =
  (window._env_ ? window._env_.REACT_APP_ID_SISE_COMMISSION_LIST : false) ||
  process.env.REACT_APP_ID_SISE_COMMISSION_LIST;

export const ID_SISE_SINISTER_LIST =
  (window._env_ ? window._env_.REACT_APP_ID_SISE_SINISTER_LIST : false) ||
  process.env.REACT_APP_ID_SISE_SINISTER_LIST;

export const ID_Q_SISE_SINISTER =
  (window._env_ ? window._env_.REACT_APP_ID_Q_SISE_SINISTER : false) ||
  process.env.REACT_APP_ID_Q_SISE_SINISTER;

export const ID_SISE_DASHBOARD_AGENT =
  (window._env_ ? window._env_.REACT_APP_ID_SISE_DASHBOARD_AGENT : false) ||
  process.env.REACT_APP_ID_SISE_DASHBOARD_AGENT;

export const ID_SISE_DASHBOARD_PRODUCTION_MONTH =
  (window._env_
    ? window._env_.REACT_APP_ID_SISE_DASHBOARD_PRODUCTION_MONTH
    : false) || process.env.REACT_APP_ID_SISE_DASHBOARD_PRODUCTION_MONTH;

export const ID_SISE_DASHBOARD_BONUS_SUMMARY =
  (window._env_
    ? window._env_.REACT_APP_ID_SISE_DASHBOARD_BONUS_SUMMARY
    : false) || process.env.REACT_APP_ID_SISE_DASHBOARD_BONUS_SUMMARY;

export const ID_Q_SISE_CARTERA =
  (window._env_ ? window._env_.REACT_APP_ID_Q_SISE_CARTERA : false) ||
  process.env.REACT_APP_ID_Q_SISE_CARTERA;

export const ID_Q_SISE_FEES_PAYMENTS =
  (window._env_ ? window._env_.REACT_APP_ID_Q_SISE_FEES_PAYMENTS : false) ||
  process.env.REACT_APP_ID_Q_SISE_FEES_PAYMENTS;

export const ID_Q_SISE_ATTENDANCES_INSURED =
  (window._env_
    ? window._env_.REACT_APP_ID_Q_SISE_ATTENDANCES_INSURED
    : false) || process.env.REACT_APP_ID_Q_SISE_ATTENDANCES_INSURED;

export const ID_Q_SISE_DETAILS_SINISTER =
  (window._env_ ? window._env_.REACT_APP_ID_Q_SISE_DETAILS_SINISTER : false) ||
  process.env.REACT_APP_ID_Q_SISE_DETAILS_SINISTER;

export const ID_SISE_REFUND_LIST =
  (window._env_ ? window._env_.REACT_APP_ID_SISE_REFUND_LIST : false) ||
  process.env.REACT_APP_ID_SISE_REFUND_LIST;

export const ID_Q_SISE_REFUND =
  (window._env_ ? window._env_.REACT_APP_ID_Q_SISE_REFUND : false) ||
  process.env.REACT_APP_ID_Q_SISE_REFUND;

export const repositoryCode =
  (window._env_ ? window._env_.REACT_APP_REPOSITORYCODE : false) ||
  Number(process.env.REACT_APP_REPOSITORYCODE);

export const INSURED_CLIENT =
  (window._env_ ? window._env_.REACT_APP_INSURED_CLIENT : null) ||
  process.env.REACT_APP_INSURED_CLIENT;

export const MEDICAL_NETWORK_QUERY =
  (window._env_ ? window._env_.REACT_APP_MEDICAL_NETWORK_QUERY : null) ||
  process.env.REACT_APP_MEDICAL_NETWORK_QUERY;

export const SHOW_TECHNICAL_INSURANCE_RISK_TARGET_IDS =
    (window._env_ ? window._env_.REACT_APP_SHOW_TECHNICAL_INSURANCE_RISK_TARGET_IDS : "[33]") ||
    process.env.REACT_APP_SHOW_TECHNICAL_INSURANCE_RISK_TARGET_IDS;
  
export const SHOW_COLISSION_TYPE_RISK_TARGET_IDS =
    (window._env_ ? window._env_.REACT_APP_SHOW_COLISSION_TYPE_RISK_TARGET_IDS : "[4,24]") ||
    process.env.REACT_APP_SHOW_COLISSION_TYPE_RISK_TARGET_IDS;
  
export const SINISTER_CAUSE_STEALING_ID =
    (window._env_ ? window._env_.REACT_APP_SINISTER_CAUSE_STEALING_ID : "6") ||
    process.env.REACT_APP_SINISTER_CAUSE_STEALING_ID;
  
export const SINISTER_CAUSE_PARTIAL_DAMAGE_WINDSHIELD_GLASS_ID =
    (window._env_ ? window._env_.REACT_APP_SINISTER_CAUSE_PARTIAL_DAMAGE_WINDSHIELD_GLASS_ID : "10") ||
    process.env.REACT_APP_SINISTER_CAUSE_PARTIAL_DAMAGE_WINDSHIELD_GLASS_ID;

    
export const validateResponse = (response) => {
  if (response.ok) {
    return response.json();
  } else {
    throw exceptionCodeResponse();
  }
};

export const validateFileResponse = async (response) => {
  if (response.ok) {
    return await response.blob();
  } else {
    throw exceptionCodeResponse();
  }
};

export const validateResponseWithError = async (response) => {
  if (response.ok) {
    return response.json();
  } else {
    const error = await response.json().then((text) => text.error);
    throw exceptionCodeResponse(error);
  }
};

export const validateJSONResponse = (response) => {
  if (response.ok) {
    return response.json();
  } else {
    throw exceptionCodeResponse();
  }
};

export const exceptionCodeResponse = (error = "error") => {
  return error;
};

export const googleMapsKey =
  (window._env_ ? window._env_.REACT_APP_GOOGLE_MAPS_KEY : false) ||
  process.env.REACT_APP_GOOGLE_MAPS_KEY;
