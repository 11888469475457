import axios from 'axios';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { getDataToken, getToken } from '../utils/index';
import { showSuccess, showError } from '../actions/notificationActions';
import { exceptionCodeResponse, authUrlApi } from '../utils/urls';

export const getDataUserLoggedInAPI = createAsyncAction('GET_USER_INFO_LOGGED', async (token = null, dispatch) => {
    const userData = getDataToken(token)
    const headers = { Authorization: `Bearer ${token}` };
    const response = axios.get(`${authUrlApi}/usersInfo/${userData.user._id}`, { headers })
        .then(({data}) => {
            //dispatch(showSuccess());
            return data
        })
        .catch((error) => exceptionCodeResponse(error))
    return response
})

export const updateDataUserInfo = createAsyncAction('UPDATE_USER_INFO', async (body, dispatch) => {
    const token = getToken()
    const headers = { Authorization: `Bearer ${token}` };
    const response = axios.put(`${authUrlApi}/usersInfo/update`, body, { headers })
        .then(({ data }) => {
            // dispatch(showSuccess());
            return data
        })
        .catch((error) => exceptionCodeResponse(error))
    return response
})

export const setPasswordUserAPI = createAsyncAction('SET_PASSWORD_USER', async (token, data, dispatch) => {
    try {
        const dataToken = getDataToken();
        const headers = { Authorization: `Bearer ${token}` };
        data._id = dataToken.user._id;
        const response = await axios.put(`${authUrlApi}/users/update`, data, { headers })
        dispatch(showSuccess('Contraseña cambiada con exito'));
        return response;
    } catch (error) {
        dispatch(showError('Las contraseña actual es incorrecta'));
        exceptionCodeResponse(error);
    }
})
