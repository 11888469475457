import {
    getDataUserLoggedInAPI,
    updateDataUserInfo,
    setPasswordUserAPI
} from '../sevices/userInfoService';

export const getDataUserLoggeIn = (token) => async (dispatch) => {
    await dispatch(getDataUserLoggedInAPI(token, dispatch))
};

export const updateUserInfo = (data) => async (dispatch) => {
    await dispatch(updateDataUserInfo(data, dispatch))
};

export const setPasswordUser = (token, data) => async (dispatch) => {
    await dispatch(setPasswordUserAPI(token, data, dispatch))
}
