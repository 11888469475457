import { getSiseDasboardAPI } from '../sevices/qSiseDashboardService';
import {GET_Q_SISE_DASHBOARD_REJECTED, GET_REQUEST_WORKFLOW_GROUP_PENDING} from './types'

export const getQsiseDashboardPending = () => {
	return {
		type: GET_REQUEST_WORKFLOW_GROUP_PENDING
	};
};

export const getQsiseDashboard = (token) => async (dispatch) => {
    await dispatch(getSiseDasboardAPI(token));
};

export const getQsiseDashboardRejected = () => {
	return {
		type: GET_Q_SISE_DASHBOARD_REJECTED
	};
};