import {
    GET_BONUS_SUMMARY_REJECTED,
    GET_BONUS_SUMMARY_PENDING,
    GET_BONUS_SUMMARY,
    GET_BONUS_SUMMARY_FULFILLED,
} from '../actions/types';

  const initialState = {
    data: {},
    loading: false,
    error: false
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_BONUS_SUMMARY_PENDING:
        return {
          ...state,
          loading: true,
          error: false
        };
      case GET_BONUS_SUMMARY_FULFILLED:
        return {
          ...state,
          data: {...state.data, ...action.payload},
          loading: false,
          error: false
      };
      case GET_BONUS_SUMMARY_REJECTED:
        return {
          ...state,
          data: [],
          loading: false,
          error: true
        };
      case GET_BONUS_SUMMARY:
        return {
          ...state,
          selected: action.payload
        };
      default:
        return state;
    }
  }