import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useStyles } from './styles';
import { CircularProgress, Typography } from '@material-ui/core';
import { navigate } from '@reach/router';

import { ButtonEdit } from './ExtraActionsSinister';
import DetailPolicyForm from './DetailPolicyForm';
import DetailGeneralForm from './DetailsGeneralForm.js';
import VehicleDriverForm from './VehicleDriverForm';
import DetailsSupportForm from './DetailsSupportForm';
import ThirdDataForm from './ThirdDataForm';
import StealingForm from './Stealing/StealingForm';

import { useSelector, useDispatch } from 'react-redux';
import { postSinister } from '../../actions/sinisterActions';
import moment from "moment";
import MessageError from '../messages/messageError';
import MessageSuccess from '../messages/messageSuccess';
import { getDataToken, jsonToHtml } from '../../utils';
import { fileManagementChangeTemporalyToComplete } from '../../actions/fileManagementActions';
import DownloadDrawer from './DownloadDrawer';
import { showError } from '../../actions/notificationActions';
import diccionaryStealingForm from './Stealing/StealingFormDictionary'


function AdvanceLineSinister() {
    const [actualStep, setActualStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [stepsData, setStepsData] = useState();
    const [policy, setPolicy] = useState(null);
    const [coberturas, setCoberturas] = useState([]);
    const classes = useStyles();
    const refTitle = useRef();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const dataToken = getDataToken(token);
    const [openDrawer, setOpenDrawer] = useState(false)
    const [sinisterResponse, setSinisterResponse] = useState(false)
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)

    const detailpolicy = useSelector((state) => state.form.detailpolicy?.values);
    const detailgeneral = useSelector((state) => state.form.detailgeneral?.values);
    const vehicledriver = useSelector((state) => state.form.vehicledriver?.values);
    const thirdData = useSelector((state) => state.form.thirdData?.values);
    const stealingForm = useSelector((state) => state.form.stealingForm?.values);
    const support = useSelector((state) => state.form.support?.values);
    const { data: uploadedFiles} = useSelector((selector) => selector.fileManagement);

    const isActiveStealingForm = localStorage.getItem('isActiveStealingForm') == "true";


    useEffect(() => {
        setStepsData(stepsOtherPolicies);
    }, []);

    useEffect(() => {
        if (refTitle.current) {
            refTitle.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [actualStep]);

    useEffect(() => {
        if (policy?.codigo_ramo === 4 || policy?.codigo_ramo === 24) {
            setStepsData(stepsAutoMoto);
        } else {
            setStepsData(stepsOtherPolicies);
        }
    }, [policy, isActiveStealingForm]);


    const stepsOtherPolicies = [
        {
            name: 'Detalles del Siniestro.',
            component: 'general'
        },
        {
            name: 'Lugar y Forma de Ocurrencia',
            component: 'policy'
        },
        ...(isActiveStealingForm
            ? [{
                name: 'Cuestionario por siniestro de Robo/hurto total',
                component: 'stealingForm'
            }]
            : []),
        {
            name: 'Conductor del Vehículo Asegurado',
            component: 'driver'
        },
        {
            name: 'Soportes',
            component: 'support'
        }
    ];

    const stepsAutoMoto = [
        {
            name: 'Detalles del Siniestro.',
            component: 'general'
        },
        {
            name: 'Lugar y Forma de Ocurrencia',
            component: 'policy'
        },
        ...(isActiveStealingForm
            ? [{
                name: 'Cuestionario por siniestro de Robo/hurto total',
                component: 'stealingForm'
            }]
            : []),
        {
            name: 'Conductor del Vehículo Asegurado',
            component: 'driver'
        },
        {
            name: 'Datos de terceros',
            component: 'thirdData'
        },
        {
            name: 'Soportes',
            component: 'support'
        }
    ];

    const findSubSiniestros = () => {
        let overArr = []
        let arrDefault = []
        arrDefault = [
            {
                "stroId": 1,
                "itemId": detailpolicy.items.nro_item,
                "indCobId": detailgeneral.cod_ind_cob,
                "codMoneda": 0,
                "sn_Asegurado": -1,
                "conductor": {
                    "nomyApe": `${vehicledriver?.name || ''}`,
                    "teléfono": `${vehicledriver?.phoneNumber || ''}`,
                    "númeroLicencia": `${vehicledriver?.license || ''}`,
                    "genero": "",
                    "fechaVencimientoLicencia": new Date (moment( vehicledriver?.endLicense !== undefined ? new Date (vehicledriver?.endLicense) : new Date()).format("YYYY-MM-DD")),
                    "antiguedadLicencia": 0,
                    "códigoOcupación": 0
                }
            }
        ]
        if (thirdData !== undefined && thirdData.list.length > 0) {
            let count = 2;
            overArr = thirdData.list.map((item, index) => {
                count += index
                return {
                    "stroId": count,
                    "itemId": detailpolicy.items.nro_item,
                    "indCobId": detailgeneral.cod_ind_cob,
                    "codMoneda": 0,
                    "sn_Asegurado": 0,
                    "tercero": {
                        "nombre": item.clientName,
                        "apellido": item.clientLastName,
                        "nroDoc": item.document,
                        "tipoDoc": item.documentType,
                        "email": item.email,
                        "teléfono": item.phone,
                        "pais": detailgeneral.country,
                        "provincia": detailgeneral.province || 1,
                        "localidad": 0,
                        "calle": detailgeneral.street,
                        "nroCalle": detailgeneral.number,
                        "codPostal": detailgeneral.postalCode
                    },
                    "conductor": {
                            "nomyApe": `${vehicledriver?.name.split(' ').filter(item => item != '').join(' ') || ''}`,
                            "teléfono": `${vehicledriver?.phoneNumber || ''}`,
                            "númeroLicencia": `${vehicledriver?.license || ''}`,
                            "genero": "",
                            "fechaVencimientoLicencia": new Date (moment(vehicledriver?.endLicense !== undefined ? 
                                new Date (vehicledriver?.endLicense) :
                                new Date()
                            ).format("YYYY-MM-DD")),
                            "antiguedadLicencia": 0,
                            "códigoOcupación": 0
                        },
                    "generatorEvent": 0
                }
            })
        }
        return [...arrDefault, ...overArr]
    }

    const buildDescription = () => {
        if (thirdData !== undefined && thirdData.list.length > 0) {
            let overArr = []
            thirdData.list.forEach((item, index) => {
                let register = `~ Tercero ${index}: `
                    if (policy?.codigo_ramo == 4 || policy?.codigo_ramo == 24) {
                        register += ` Marca: ${thirdData.list[index].brand} Modelo: ${thirdData.list[index].model} Patente: ${thirdData.list[index].patente}`
                    }
                    register +=  ` Observacion: ${thirdData.list[index].observations}`
                overArr.push(register)
                })
                return overArr.join(', ')
            }
        return ''
    }

    const giveExtraData = () => {
        let extraData = ''
        if (vehicledriver?.typeLicense) {
            extraData +=` ~ tipoLicenciaConductor : ${vehicledriver?.typeLicense || ''}`
        }
        return extraData
    }

    const buildObj = async () => {
        if (disableSubmitButton) return 
        setDisableSubmitButton(true)
        try {
            let body = {
                "policyId": policy.newPolicyIdPv,
                "incidenceDate": `${moment(detailpolicy.incidenceDate).format("YYYY-MM-DD")} ${detailpolicy.timeSinister.split(' ')[0]}`, 
                "registrationDate": moment(detailgeneral.registerDate, "MM/DD/YYYY").format("YYYY-MM-DD"),
                "noticeDate": moment(new Date()).format("YYYY-MM-DD"), 
                "fiscalYear": moment(new Date()).format("YYYY"), 
                "causeId": detailgeneral.sinisterCause,
                "inspectionDate": moment(new Date()).format("YYYY-MM-DD"), 
                "description": `${detailgeneral.descriptionSinister} ${giveExtraData()} ${buildDescription()}`, 
                "email": detailpolicy?.email,
                "technicalInsuranceType": detailpolicy?.technicalInsuranceType,
                "measure": detailgeneral?.measure,
                "specificDetailCause": detailgeneral?.specificDetailCause,
                "ubicacion": {
                    "pais": detailgeneral.country,
                    "provincia": detailgeneral.province || 1,
                    "localidad": detailgeneral.municipio || 1,
                    "calle": detailgeneral.street,
                    "nroCalle": detailgeneral.number,
                    "codPostal": detailgeneral.postalCode,
                    "nroPiso": 0,
                    "nroDpto": "",
                    "latitud": 0,
                    "longitud": 0
                },
                "codigo_Usuario": dataToken?.user?.insuredCode,
                "DynamicViews": [
                    { 
                        "IdView": 10004,
                        "IdProcess": policy.newPolicyIdPv,
                        "IsReal": 1,
                        "DynamicViewFields": [
                            {
                                "ColumnName": "tipo_colision",
                                "Value": detailgeneral?.colissionType
                            },...(stealingForm
                                ? [{
                                    ColumnName: "anexo_robo",
                                    Value: jsonToHtml(stealingForm,diccionaryStealingForm)
                                }]
                                : [])
                        ]
                    },
                    {
                        "IdView": 10020,
                        "IdProcess":  policy.newPolicyIdPv,
                        "IsReal": 1,
                        "DynamicViewFields": [
                            ...(detailgeneral?.specificDetailCause 
                                ? [{
                                    "ColumnName": "specificDetailCause",
                                    "Value": detailgeneral?.specificDetailCause
                                }]
                                : []),
                            ...(detailpolicy?.email 
                                ? [{
                                    "ColumnName": "email",
                                    "Value": detailpolicy?.email
                                }]
                                : []),
                            ...(detailpolicy?.technicalInsuranceType 
                                ? [{
                                    "ColumnName": "technicalInsuranceType",
                                    "Value": detailpolicy?.technicalInsuranceType
                                }]
                                : []),
                            ...(detailgeneral?.measure 
                                ? [{
                                    "ColumnName": "measure",
                                    "Value": detailgeneral?.measure
                                }]
                                : []),
                        ]

                    }
                ],
                "subSiniestro" : findSubSiniestros()
            }
            const sinisterData = await dispatch(
                postSinister(token, body)
            );
            if ( !sinisterData?.value.esValido ) {
                setTimeout(() => {
                    window.location.reload()
                }, 500);
            }
            try {
                if (uploadedFiles.length > 0) {
                    const urlEditFile = 'documentManagement/changeTemporyToComplete';
                    const element = uploadedFiles[0];
                    const data = {
                        identifierType: 'siniestros',
                        identifierNumber: element.identifierNumber,
                        newIdentifierNumber: sinisterData.value.mensaje['Id. Siniestro']
                    }
                    await dispatch(
                        fileManagementChangeTemporalyToComplete(token, data, urlEditFile)
                    );
                }
            } catch (error) {
                dispatch(showError('Ocurrio un error en la carga de los archivos.'));
                // console.log('El catch del cambio de archivo => ', error);
            }
            setSinisterResponse(sinisterData.value)
            setOpenDrawer(true)
        } catch (error) {
            // console.log('Error building data', error);
            setDisableSubmitButton(false)
        }
    }

    const handleClickStep = async (step) => {
        setActualStep((actualStep) =>
            actualStep < stepsData.length ? actualStep + 1 : actualStep
        );
        if (step.step === stepsData[stepsData.length-1].component) {
           await buildObj()
        }
    };

    const handleClickEdit = (editStep) => {
        setActualStep(editStep);
    };

    const components = {
        policy: (last) => (
            <DetailGeneralForm
                handleStep={handleClickStep}
                coberturas={coberturas}
                policy={policy}
            />
        ),
        general: (last) => 
            <DetailPolicyForm
                handleStep={handleClickStep}
                setPolicy={setPolicy}
                setCoberturas={setCoberturas}
            />,
        driver: (last) => <VehicleDriverForm handleStep={handleClickStep} />,
        thirdData: (last) => <ThirdDataForm handleStep={handleClickStep} policy={policy}/>,
        support: (last) => <DetailsSupportForm handleStep={handleClickStep} policy={policy} last={last} disableSubmit={disableSubmitButton}/>,
        stealingForm: (last) => (
            <StealingForm
                handleStep={handleClickStep}
                setPolicy={setPolicy}
                setCoberturas={setCoberturas}
            />
        ),    
    };

    return (
        <>
            <ul className={classes.outline}>
                {stepsData?.slice(0, actualStep).map((step, i) => (
                    <li key={i} className={classes.liContainer} ref={refTitle}>
                        <div className={classes.circle} color={'#F4F4FA'}>
                            {i + 1}
                        </div>
                        <div className={classes.titleStep}>
                            {step.name}
                            <ButtonEdit
                                onClick={() => {
                                    handleClickEdit(i + 1);
                                }}
                            />
                        </div>
                        {i < stepsData?.length - 1 && (
                            <hr className={classes.line} />
                        )}
                        <div
                            className={
                                i + 1 != actualStep || loading
                                    ? classes.stepActionsContainerDisabled
                                    : classes.stepActionsContainer
                            }
                        >
                            {components[step.component]((i+1) < stepsData.length ?  false : true )}
                        </div>
                    </li>
                ))}
            </ul>
            <MessageSuccess/>
            <MessageError/>
            <DownloadDrawer openDrawer={openDrawer} setOpen={setOpenDrawer} sinisterData={sinisterResponse}/>
        </>
    );
}

export default AdvanceLineSinister;
