import { createAndGetPaymentLinkAPI, getPaymentLinksAPI, getPaymentLinksAPI_ } from "../sevices/paymentLinkService";
  
export const createAndGetPaymentLink = async (token, data) => {
  return await createAndGetPaymentLinkAPI(token, data);
};

export const getPaymentLinks = (token, userCode) => async (dispatch) => {
  dispatch(getPaymentLinksAPI(token, userCode));
};

export const getPaymentLinks_ = async (token, userCode) => {
  return await getPaymentLinksAPI_(token, userCode);
};

export const setIsPaymentLinkInPaymentProcess = (value)  => async (dispatch) => {
  dispatch({ type: "SET_IS_PAYMENT_LINK_IN_PAYMENT_PROCESS", payload: value });
};
