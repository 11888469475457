import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./style";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import MUICarousel from "react-material-ui-carousel";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import Logo from "../../../assets/images/transparentOrangeLogo.png";
import MapPlaceholder from "../../../assets/images/map-placeholder.webp";
import {
  Button,
  Drawer,
  IconButton,
  Typography,
  Hidden,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  CalendarTodayOutlined,
  CancelPresentation,
  ChevronLeft,
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Launch,
  MotorcycleOutlined,
  Phone,
  RoomOutlined,
} from "@material-ui/icons";
import { general } from "../../../utils/icons";
import StyledProgress from "../../styledProgress";
import { getTrips, getTripsByPeriod } from "../../../sevices/orangeTimeService";
import moment from "moment";
import Carousel from "react-grid-carousel";
import { Skeleton } from "@material-ui/lab";
import jwtDecode from "jwt-decode";
import TripReportCard from "../cards/orangeTime/tripReportCard";
import VehicleLocationCard from "../cards/orangeTime/vehicleLocationCard";
import TripHistoryCard from "../cards/orangeTime/tripHistoryCard";

const OrangeTime = ({ user, token }) => {
  const orangeProvider = useSelector((state) => state.orange);
  const [open, setOpen] = useState(false);
  const [openTravels, setOpenTravels] = useState(false);
  const [selected, setSelected] = useState(null);
  const [policies, setPolicies] = useState([]);
  const [history, setHistory] = useState(null);
  const [period, setPeriod] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useStyles({
    count: policies.length == 1 ? 3 : policies.length,
  });

  const isLoading = orangeProvider.loading;
  const isRefetching = orangeProvider.refetching;

  const hourToMinutes = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    return Number(seconds) / 100 + Number(minutes) + Number(hours) * 60;
  };

  const getPercentage = (v1, v2) => {
    const formattedV1 = hourToMinutes(v1);
    const formattedV2 = hourToMinutes(v2);

    if (formattedV1 > formattedV2) {
      return 100;
    }

    const result = Math.floor((formattedV1 / formattedV2) * 100);
    return result;
  };

  useEffect(() => {
    if (orangeProvider.data) {
      const data = jwtDecode(orangeProvider.data);
      setPolicies(
        data.map((d) => ({
          patent: d.patente,
          startDate: d.fecha_vigencia_desde_actual,
          endDate: d.fecha_vigencia_hasta_actual,
          isCar: d.cod_unif !== 12,
          status: [
            {
              code: 0,
            },
          ],
          travel: {},
        }))
      );
    }
  }, [orangeProvider.data]);

  useEffect(() => {
    if (orangeProvider.trips.length > 0) {
      setPolicies(orangeProvider.trips.slice());
    }
  }, [orangeProvider.trips]);

  /* useEffect(() => {
    let interval = null;
    if (policies.length === 0) {
      handleGetPolicies();
      interval = setInterval(() => {
        handleGetPolicies();
      }, 60000);
    }

    if (policies.length < 0)
      clearInterval(interval);

    return () => clearInterval(interval);

  }, [policies]); */

  /* useEffect(() => {
    handleGetPolicies();
  }, []); */

  const handleSelect = (selected) => {
    setOpen(true);
    setSelected(selected);
  };

  const handleClose = () => {
    setOpen(false);
    setSelected(null);
  };

  const handleOpenTravels = (currentHistory) => {
    setHistory(currentHistory);
    setOpenTravels(true);
  };

  const handleCloseTravels = () => {
    setHistory(null);
    setPeriod(0);
    setOpenTravels(false);
  };

  const handleFetchTravelsByPeriod = async (patent, start, end) => {
    setOpenTravels(true);
    const $history = await getTripsByPeriod(patent, start, end);
    setHistory($history);
  };

  const statusColors = {
    28: "orange",
    37: "#121212",
    36: "#219653",
  };

  const getLabelByStatus = (status) => {
    switch (status) {
      case "28":
        return "FUERA DE ALCANCE GPS";
      case "37":
      case "41":
      case "21":
        return "VEHICULO DETENIDO";
      case "36":
        return "VIAJE EN CURSO";
      default:
        return null;
    }
  };

  const getFormattedDuration = (duration) => {
    const [hour, minutes] = duration.split(":");

    if (minutes === "00") {
      return (
        <span>
          {`${hour}:${minutes}`}
          <small>hs</small>
        </span>
      );
    }

    return (
      <span>
        {`${hour}`}
        <small>hs</small>
        {`${minutes}`}
        <small>min</small>
      </span>
    );
  };

  const getTotalDuration = (travels) => {
    const total = travels.reduce(function (a, b) {
      return a + b.durationMs;
    }, 0);
    const totalDuration = moment.duration(total);
    const format =
      Math.floor(totalDuration.asHours()) + moment.utc(total).format(":mm:ss");
    const [hour, minutes, seconds] = format.split(":");
    return `${hour}hs ${minutes}min ${seconds}seg`;
  };

  const isOnGoing = (status) => {
    return status == 36;
  };

  const isStopped = (status) => {
    return status == 37 || status == 41 || status == 21;
  };

  const goToMapDetails = (coords) => {
    window.open(
      `https://www.google.com/maps/@${coords.lat},${coords.lon},12z`,
      "_blank"
    );
  };

  const getPeriod = (date, count) => {
    return moment(date).clone().subtract(count, "month");
  };

  const handleCancelDialog = () => setOpenDialog(false);

  const handleCall = () => {
    window.open("tel:+5491126499551", "_blank");
  };

  const handlePeriodChange = (evt) => {
    const $selected = selected ? selected : policies[0];
    if ($selected) {
      const $period = $selected.policy.periods[evt.target.value];
      console.log("Period: ", $period);
      setHistory(null);
      setPeriod(evt.target.value);
      handleFetchTravelsByPeriod(
        $selected.patent,
        getPeriod($period.fecha_cierre, 1).toDate(),
        moment($period.fecha_cierre).toDate()
      );
    }
  };

  const breakpoints = [
    {
      breakpoint: 1900,
      cols: 4,
    },
    {
      breakpoint: 1200,
      cols: 3,
    },
    {
      breakpoint: 992,
      cols: 1,
    },
  ];

  console.log("POLICIES", policies);

  const isMetadataLoaded = policies.every((p) => p.policy !== undefined);

  return isLoading ? (
    <div className={classes.orangeContainer}>
      <div className={classes.orangeTopbar}>
        <div>
          <img src={Logo} alt="Orange" />
        </div>
      </div>
      <div className={classes.orangeList}>
        <Skeleton width="30%" height={398} style={{ transform: "scale(1)" }} />
        <Skeleton width="30%" height={398} style={{ transform: "scale(1)" }} />
        <Skeleton width="30%" height={398} style={{ transform: "scale(1)" }} />
      </div>
    </div>
  ) : (
    <>
      {policies.length > 0 && (
        <div className={classes.orangeContainer}>
          <div className={classes.orangeTopbar}>
            <div>
              <img src={Logo} alt="Orange" />
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenDialog(true)}
              >
                {general.satellite} SOLICITAR RASTREO POR ROBO <Launch />
              </Button>
            </div>
          </div>
          {user && (
            <div className={classes.orangeList}>
              {policies.length > 4 ? (
                <>
                  <Carousel cols={1} rows={1} gap={8} mobileBreakpoint={992}>
                    {policies.map((policy, i) => (
                      <Carousel.Item key={`orange_policy_${i}`}>
                        <TripReportCard
                          classes={classes}
                          policy={policy}
                          handleSelect={handleSelect}
                          isLoaded={
                            orangeProvider.trips.length > 0 && isMetadataLoaded
                          }
                          refetching={isRefetching}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </>
              ) : (
                <>
                  <Hidden mdUp>
                    <Carousel
                      cols={1}
                      rows={1}
                      gap={8}
                      responsiveLayout={breakpoints}
                      mobileBreakpoint={992}
                    >
                      {policies.map((policy, i) => (
                        <Carousel.Item key={`orange_policy_${i}`}>
                          <TripReportCard
                            classes={classes}
                            policy={policy}
                            handleSelect={handleSelect}
                            isLoaded={
                              orangeProvider.trips.length > 0 &&
                              isMetadataLoaded
                            }
                            refetching={isRefetching}
                          />
                        </Carousel.Item>
                      ))}
                      {policies.length === 1 && (
                        <Carousel.Item>
                          <VehicleLocationCard
                            classes={classes}
                            policy={policies[0]}
                            isLoaded={
                              orangeProvider.trips.length > 0 &&
                              isMetadataLoaded
                            }
                            refetching={isRefetching}
                          />
                        </Carousel.Item>
                      )}
                      {policies.length === 1 && (
                        <Carousel.Item>
                          <TripHistoryCard
                            classes={classes}
                            policy={policies[0]}
                            handleOpenTravels={handleOpenTravels}
                            isLoaded={
                              orangeProvider.trips.length > 0 &&
                              isMetadataLoaded
                            }
                            refetching={isRefetching}
                          />
                        </Carousel.Item>
                      )}
                    </Carousel>
                  </Hidden>
                  <Hidden smDown>
                    {policies.map((policy, i) => (
                      <TripReportCard
                        key={`orange_policy_${i}`}
                        classes={classes}
                        policy={policy}
                        handleSelect={handleSelect}
                        isLoaded={
                          orangeProvider.trips.length > 0 && isMetadataLoaded
                        }
                        refetching={isRefetching}
                      />
                    ))}
                  </Hidden>
                </>
              )}
              {policies.length === 1 && (
                <>
                  <Hidden smDown>
                    <VehicleLocationCard
                      classes={classes}
                      policy={policies[0]}
                      isLoaded={
                        orangeProvider.trips.length > 0 && isMetadataLoaded
                      }
                      refetching={isRefetching}
                    />
                    <TripHistoryCard
                      classes={classes}
                      policy={policies[0]}
                      handleOpenTravels={handleOpenTravels}
                      isLoaded={
                        orangeProvider.trips.length > 0 && isMetadataLoaded
                      }
                      refetching={isRefetching}
                    />
                  </Hidden>
                </>
              )}
            </div>
          )}
          <Drawer
            ModalProps={{
              disableBackdropClick: true,
              hideBackdrop: true,
            }}
            anchor="right"
            open={open}
            classes={{
              paper: classes.orangeMenu,
            }}
            onClose={handleClose}
          >
            {selected && (
              <>
                <div className={classes.orangeMenuHeader}>
                  <Typography>{selected.patent}</Typography>
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </div>
                <div className={classes.orangeMenuBody}>
                  <div className={`${classes.orangeCard} details`}>
                    <div className={classes.orangeCardHeader}>
                      <div style={{ width: "50%" }}>
                        <Typography>Consumo Actual</Typography>
                      </div>
                      <div>
                        <Typography
                          style={{
                            color: isOnGoing(selected.status[0].code)
                              ? "#219653"
                              : statusColors[selected.status[0].code],
                          }}
                        >
                          {getLabelByStatus(selected.status[0].code)}
                        </Typography>
                        <span
                          style={{
                            borderColor: isOnGoing(selected.status[0].code)
                              ? "#219653"
                              : statusColors[selected.status[0].code],
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.orangeCardBody}>
                      <div>
                        <CalendarTodayOutlined
                          color="primary"
                          style={{ fontSize: 12.5 }}
                        />
                        <Typography>
                          Periodo{" "}
                          {moment(selected.startDate).format("DD/MM/YYYY")} al{" "}
                          {moment(selected.endDate).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.orangeCardDuration}>
                      <Typography>
                        Tiempo de uso <br />
                        <span>{selected.travel.strDuration}</span> <br />
                        de 80 hs
                      </Typography>
                      <span />
                      <Typography>
                        Ahorro <br />
                        <span>
                          {new Intl.NumberFormat("es-AR", {
                            style: "currency",
                            currency: "ARS",
                            maximumFractionDigits: 0,
                          }).format(selected.travel.savings)}
                        </span>{" "}
                        <br />
                        de{" "}
                        {new Intl.NumberFormat("es-AR", {
                          style: "currency",
                          currency: "ARS",
                          maximumFractionDigits: 0,
                        }).format(selected.travel.maxConsumption)}
                      </Typography>
                    </div>
                    <Typography
                      style={{
                        marginLeft: 26,
                        marginBottom: 14,
                        fontSize: 11,
                        lineHeight: "14px",
                        color: "#4B4B4B",
                      }}
                    >
                      Ultimo viaje{" "}
                      {moment(selected.travel.last_updated).format(
                        "DD/MM/YYYY, hh:mm A"
                      )}
                    </Typography>
                  </div>
                  <div className={`${classes.orangeCard} details`}>
                    <div className={classes.orangeCardHeader}>
                      <div style={{ width: "60%" }}>
                        <Typography>Períodos anteriores</Typography>
                      </div>
                    </div>
                    <div className={classes.orangeTable}>
                      <div>
                        <span>Cierre</span>
                        <span>Costo</span>
                        <span>Ahorro</span>
                      </div>
                      {selected.policy.periods.map((p, i) => (
                        <div>
                          <span>
                            {moment(p.fecha_cierre).format("DD/MM/YYYY")}
                          </span>
                          <span>{p.costo}</span>
                          <span>{p.ahorro}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={`${classes.orangeCard} details`}>
                    <div className={classes.orangeCardHeader}>
                      <div style={{ width: "60%" }}>
                        {general.car}
                        <Typography>Chequea tus viajes</Typography>
                      </div>
                      <div>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleOpenTravels(selected)}
                        >
                          ver más
                        </Button>
                      </div>
                    </div>
                    <div className={classes.orangeTable}>
                      <div>
                        <span>
                          <CalendarTodayOutlined
                            color="primary"
                            style={{ fontSize: 12.5 }}
                          />{" "}
                          Inicio del viaje
                        </span>
                        <span>Consumo</span>
                      </div>
                      {Object.keys(selected.history)
                        .filter((_, i) => i < 1)
                        .map((h, i) => (
                          <Fragment key={`key__${h}__${i}`}>
                            <div className={classes.orangeRow}>
                              <span>{h}</span>
                              <span>
                                {getTotalDuration(selected.history[h])}
                              </span>
                            </div>
                            {selected.history[h]
                              .filter((_, i) => i < 2)
                              .map((travels, i) => (
                                <div key={`key__${h}__${travels.time}__${i}`}>
                                  <span>{travels.time}</span>
                                  <span>{travels.strDuration}</span>
                                </div>
                              ))}
                          </Fragment>
                        ))}
                    </div>
                  </div>
                  <div className={`${classes.orangeCard} details`}>
                    <div className={classes.orangeCardHeader}>
                      <div style={{ width: "60%" }}>
                        <RoomOutlined color="primary" />
                        <Typography>Ubicacion actual</Typography>
                      </div>
                      <div>
                        <Typography
                          style={{
                            color: isOnGoing(selected.status[0].code)
                              ? "#219653"
                              : statusColors[selected.status[0].code],
                          }}
                        >
                          {getLabelByStatus(selected.status[0].code)}
                        </Typography>
                        <span
                          style={{
                            borderColor: isOnGoing(selected.status[0].code)
                              ? "#219653"
                              : statusColors[selected.status[0].code],
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={classes.orangeMap}
                      onClick={() =>
                        !isStopped(selected.status[0].code)
                          ? goToMapDetails(selected.travel.location)
                          : null
                      }
                    >
                      <MapContainer
                        className={
                          !isStopped(selected.status[0].code) ? "disabled" : ""
                        }
                        center={[
                          selected.travel.location.lat,
                          selected.travel.location.lon,
                        ]}
                        zoom={16}
                        scrollWheelZoom={false}
                        dragging={false}
                        attributionControl={false}
                        zoomControl={false}
                      >
                        <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker
                          position={[
                            selected.travel.location.lat,
                            selected.travel.location.lon,
                          ]}
                        />
                      </MapContainer>
                      {/* <img onClick={() => goToMapDetails(selected.travel.location)} src={MapPlaceholder} alt="Map placeholder" className={!isStopped(selected.status[0].code) ? 'disabled' : ''} /> */}
                      {!isStopped(selected.status[0].code) && (
                        <div className="map-overlay">
                          <CancelPresentation />
                          <Typography>
                            Solo puedes ver tu ubicacion actual mientras el auto
                            este detenido
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className={classes.orangeMapFooter}>
                      <Typography>
                        {isStopped(selected.status[0].code) &&
                          selected.travel.location.address}
                      </Typography>
                      <span>
                        Actualizado{" "}
                        {moment(selected.travel.location.last_updated).format(
                          "DD/MM/YYYY, hh:mm A"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Drawer>
          <Drawer
            ModalProps={{
              disableBackdropClick: true,
              hideBackdrop: true,
            }}
            anchor="right"
            open={openTravels}
            classes={{
              paper: classes.orangeMenu,
            }}
            onClose={handleCloseTravels}
          >
            <div className={classes.orangeMenuHeader}>
              <IconButton onClick={handleCloseTravels}>
                <ChevronLeft />
              </IconButton>
              <Typography style={{ flex: 1 }}>Viajes por período</Typography>
              <IconButton onClick={handleCloseTravels}>
                <Close />
              </IconButton>
            </div>
            {history ? (
              <>
                <div className={classes.orangeMenuBody}>
                  <div className={`${classes.orangeCard} details`}>
                    <div className={classes.orangeCardHeader}>
                      <div style={{ width: "50%" }}>
                        <Typography>Consumo Actual</Typography>
                      </div>
                    </div>
                    <div
                      className={classes.orangeCardBody}
                      style={{ backgroundColor: "#F1F1F1", margin: "0px 8px" }}
                    >
                      <div>
                        <CalendarTodayOutlined
                          color="primary"
                          style={{ fontSize: 12.5 }}
                        />
                        <Select value={period} onChange={handlePeriodChange}>
                          {(selected
                            ? selected
                            : policies[0]
                          ).policy.periods.map((p, i) => (
                            <MenuItem key={`period_menu_item__${i}`} value={i}>
                              {getPeriod(p.fecha_cierre, 1).format(
                                "DD/MM/YYYY"
                              )}{" "}
                              al {moment(p.fecha_cierre).format("DD/MM/YYYY")}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <Typography>
                      Periodo {moment(history.startDate).format('DD/MM/YYYY')} al {moment(history.endDate).format('DD/MM/YYYY')}
                    </Typography> */}
                      </div>
                    </div>
                    <div className={classes.orangeCardDuration}>
                      <Typography>
                        <span>{history.total_travels}</span> <br />
                        Viajes
                      </Typography>
                      <span />
                      <Typography>
                        <span>
                          {getFormattedDuration(history.travel.strDuration)}
                        </span>{" "}
                        <br />
                        Consumo
                      </Typography>
                    </div>
                  </div>
                  <div className={`${classes.orangeCard} details`}>
                    <div className={classes.orangeCardHeader}>
                      <div style={{ width: "50%" }}>
                        <Typography>
                          <CalendarTodayOutlined
                            color="primary"
                            style={{ fontSize: 12.5 }}
                          />{" "}
                          Inicio del viaje
                        </Typography>
                      </div>
                      <div style={{ width: "50%", justifyContent: "center" }}>
                        <Typography style={{ fontSize: 14 }}>
                          Consumo
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.orangeTable}>
                      <div style={{ padding: 0 }} />
                      {Object.keys(history.history).map((h, i) => (
                        <Fragment key={`key__${h}__${i}`}>
                          <div className={classes.orangeRow}>
                            <span>{h}</span>
                            <span>{getTotalDuration(history.history[h])}</span>
                          </div>
                          {history.history[h].map((travels, i) => (
                            <div key={`key__${h}__${travels.time}__${i}`}>
                              <span>{travels.time}</span>
                              <span>{travels.strDuration}</span>
                            </div>
                          ))}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <CircularProgress color="primary" />
              </div>
            )}
          </Drawer>
          <Dialog open={openDialog} onClose={handleCancelDialog}>
            <DialogTitle>
              Rastro por robo{" "}
              <IconButton
                aria-label="close"
                className={classes.dialogCloseBtn}
                onClick={handleCancelDialog}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography>
                  Para solicitar Rastreo Satelital, llame al:
                  <div className={classes.orangeTopbar}>
                    <div
                      style={{
                        marginTop: "1rem",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={handleCall}
                        variant="contained"
                        color="primary"
                      >
                        <Phone /> 11-2222-2121
                      </Button>
                    </div>
                  </div>
                </Typography>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default OrangeTime;
