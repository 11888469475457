import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { NewReader } from 'news-list';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { quotationUrlBase } from '../../utils/urls';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '1rem',
        borderRadius: 15,
        backgroundColor: '#fff'
    }
}));

const NewsShow = ({ slug }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div style={{ marginBottom: 16, padding: 16 }}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Link to="/news">Noticias</Link>
                    <Typography color="textPrimary">{slug}</Typography>
                </Breadcrumbs>
            </div>
            <NewReader slug={slug} url={quotationUrlBase} />
        </div>
    );
};

export default NewsShow;
