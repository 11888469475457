import {
    uploadFilesAPI,
    replaceFilesAPI,
    saveTemporalFilesAPI,
} from "../sevices/uploadFileService";
import { RESET_UPLOAD_FILES, RESET_UPLOAD_TEMPORAL_FILES, SET_PREVIOUS_FILES, SET_FILTERED_UPLOAD_FILES } from "./types";

/*export const uploadFile = () => async (dispatch) => {
  await dispatch(uploadFileAPI());
};*/

export const uploadFiles = (token, data) => async (dispatch) => {
    await dispatch(uploadFilesAPI(token, data, dispatch));
};

export const replaceFile = (token, data) => async (dispatch) => {
    await dispatch(replaceFilesAPI(token, data, dispatch));
};

export const saveTemporalFiles = async (token, data) => {
    return await saveTemporalFilesAPI(token, data);
};

export const setPreviousUploadFile = (data) => (dispatch) => {
    dispatch({ type: SET_PREVIOUS_FILES, payload: data });
};

export const resetUploadFile = () => (dispatch) => {
    dispatch({ type: RESET_UPLOAD_FILES });
};

export const resetAllUploadTemporalFiles = () => (dispatch) => {
    dispatch({ type: RESET_UPLOAD_TEMPORAL_FILES });
};

export const setFilteredUploadFiles = (data) => (dispatch) => {
    dispatch({ type: SET_FILTERED_UPLOAD_FILES, payload: data });
};
