import { getPaymentMethodsAPI } from "../sevices/paymentMethodsService";
import {
  RESET_PAYMENT_METHODS,
} from "../actions/types";

export const getPaymentMethods = (token) => async (dispatch) => {
  dispatch(getPaymentMethodsAPI(token));
};


export const resetPaymentMethods = () => (dispatch) => {
  dispatch({ type: RESET_PAYMENT_METHODS });
};
