import React, { useState, useEffect } from "react";
import { Button,IconButton } from '@material-ui/core'
import NewRowForm from "./newRowForm";
import AddedRowForm,{ CustomMenu } from "./addedRowForm";
import { DeleteOutline, Check, Add, CloudUploadOutlined, KeyboardArrowDown } from '@material-ui/icons'
import { Person } from "../../assets/icons";
import { useStyles } from './styles';
import './styles.css'

export default function InputList({
    items = [], 
    disabled = false,
    setItems,
    label = "Elementos",
    widthField = 4,
    fields = [],
    add = true,
    Icon = Person,
    customHandleEdit = false
}){
    const classes = useStyles();
    const [newRow, setNewRow] = useState(false);
    const [formNewRow, setFormNewRow] = useState({});
    const [errorsInputs,setErrorsInput] = useState({});
    const [editItem,setEditItem] = useState(-1);

    const handleClickNewItem = () => {
        setEditItem(-1);
        setNewRow(false);
        setFormNewRow({});
        setNewRow(true);
    }

    const mailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    const phoneRe =  /^[+][0-9,$]*$/;

    const validatePhone = (value) => {
        let error = '';
        if(!value){
            error = 'Requerido';
        }else if(!phoneRe.test(value)){
            error = 'Debe iniciar con + seguido de números';
        }else if(value.length < 9 || value.length > 13){
            error = 'Debe tener entre 8 y 14 numeros';
        }
        return error;
    }

    const validateMail = (value) => {
        let error = '';
        if(!value){
            error = 'Requerido'
        }else if(!mailRe.test(value)){
            error = 'Formato de correo inválido'
        }
        return error;
    }

    const validations = {
        'phone number': validatePhone,
        'mail': validateMail
    }

    const handleAddNewRow = () => {
        const errors = {};
        fields.forEach(field => {
            if(!(formNewRow.hasOwnProperty(field.name) && !field.isOptional)){
                errors[field.name] = 'Requerido'
            }else if(field.validation){
                const errorField = validations[field.validation](formNewRow[field.name])
                if(errorField){
                    errors[field.name] =  errorField;
                }
            }
        })
        setErrorsInput(errors)
        if(Object.keys(errors).length > 0){
            return
        }
        setItems([...items,formNewRow]);
        setFormNewRow({});
        setNewRow(false);
    }

    const deleteItem = (index) => {
        const result = items.filter((x,i)=> i != index);
        setItems(result);
    }

    const handleEditItem = (index) => {
        const result = items.map((item,i) => {
            if(i===index){
                return formNewRow
            }
            return item
        })
        setItems(result);
        setEditItem(-1);
        setFormNewRow({});
    }

    return(
        <>
            <label className={classes.styledLabel}>
                <KeyboardArrowDown/>
                {label} ({items.length})
            </label>
            {
                items.map((item,i) => (
                    editItem !== i ?
                    <div key={i} className={classes.featureObjectContainer}>
                        <div className={classes.circleIconObject}>
                            <div>
                                <Icon/>
                            </div>
                        </div>
                        <div className={classes.infoRowObject}>
                            <AddedRowForm
                                fields={fields}
                                formRow={item}
                                widthField = {widthField}
                            />
                        </div>
                        {
                            !disabled && 
                            <div className={classes.actionsIncos}>
                                <CustomMenu
                                    handleDelete={()=>{
                                        deleteItem(i);
                                    }}
                                    handleUpdate={!customHandleEdit ? ()=>  {
                                        setNewRow(false);
                                        setFormNewRow(item)
                                        setEditItem(i)
                                    } : () => customHandleEdit(item, i)}
                                />
                            </div>
                        }

                    </div>
                    :
                    <div className={classes.featureObjectContainer} style={{backgroundColor:'white'}}>
                        <div className={classes.circleIconObject}>
                            <div>
                                <Icon/>
                            </div>
                        </div>
                        <div className={classes.infoRowObject}>
                            <NewRowForm
                                fields = {fields}
                                closeNewRow = {()=>{
                                    setNewRow(false)
                                }}
                                errorsInputs = {errorsInputs}
                                formRow = {formNewRow}
                                setFormRow = {setFormNewRow}
                                widthField = {widthField}
                            />
                        </div>
                        <div className={classes.actionsIncos}>        
                            <IconButton
                                onClick={()=>{
                                    setEditItem(-1);
                                }}
                                style={{
                                    backgroundColor:'#F4F4FA'
                                }}
                            >

                                <DeleteOutline />

                            </IconButton>
                            
                            <IconButton
                                onClick={()=>{
                                    handleEditItem(i)
                                }}
                                style={{
                                    backgroundColor:'#F4F4FA'
                                }}
                            >

                                <Check/>

                            </IconButton>
                        </div>
                    </div>
                ))
            }
            {
                newRow && 
                <div className={classes.featureObjectContainer} style={{backgroundColor:'white'}}>
                    <div className={classes.circleIconObject}>
                        <div>
                            <Icon/>
                        </div>
                    </div>
                    <div className={classes.infoRowObject}>
                        <NewRowForm
                            fields = {fields}
                            closeNewRow = {()=>{
                                setNewRow(false)
                            }}
                            errorsInputs = {errorsInputs}
                            formRow = {formNewRow}
                            setFormRow = {setFormNewRow}
                            widthField = {widthField}
                        />
                    </div>
                    <div className={classes.actionsIncos}>        
                        <IconButton
                            onClick={()=>{
                                setNewRow(false);
                            }}
                            style={{
                                backgroundColor:'#F4F4FA'
                            }}
                        >

                            <DeleteOutline />

                        </IconButton>
                        
                        <IconButton
                            onClick={handleAddNewRow}
                            style={{
                                backgroundColor:'#F4F4FA'
                            }}
                        >

                            <Check/>

                        </IconButton>
                    </div>
                </div>
            }
            {(!disabled && !newRow && add)  && 
                <Button 
                    variant="outlined" 
                    color="primary"  
                    endIcon={<Add className={classes.addIcon}/>} 
                    startIcon={<CloudUploadOutlined/>}
                    className={classes.addFeatureObject}
                    onClick={handleClickNewItem}
                >
                    Agregar
                </Button>
            }
        </>
    )
}