import {
  UPLOAD_FILES_PENDING,
  UPLOAD_FILES_FULFILLED,
  UPLOAD_FILES_REJECTED,
  SAVE_TEMPORAL_FILES_PENDING,
  SAVE_TEMPORAL_FILES_FULFILLED,
  SAVE_TEMPORAL_FILES_REJECTED,
  SET_PREVIOUS_FILES,
  RESET_UPLOAD_FILES,
  RESET_UPLOAD_TEMPORAL_FILES,
  SET_FILTERED_UPLOAD_FILES
} from "../actions/types";

const initialState = {
  data: [],
  isTempFilesSaved: false,
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILES_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case UPLOAD_FILES_FULFILLED:
      return {
        ...state,
        data:
          state.data.length > 0
            ? [...state.data, action.payload.data]
            : [action.payload.data],
        loading: false,
        error: false,
      };
    case UPLOAD_FILES_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };

    case SAVE_TEMPORAL_FILES_PENDING:
      return {
        ...state,
        loading: true,
        isTempFilesSaved: false,
        error: false,
      };
    case SAVE_TEMPORAL_FILES_FULFILLED:
      return {
        ...state,
        data: action.payload,
        isTempFilesSaved: true,
        loading: false,
        error: false,
      };
    case SAVE_TEMPORAL_FILES_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        isTempFilesSaved: false,
        error: true,
      };
    case SET_PREVIOUS_FILES:
      return {
        ...state,
        data: action.payload,
        isTempFilesSaved: true,
        loading: false,
        error: false,
      };
    case RESET_UPLOAD_FILES:
      return {
        ...state,
        data: [],
        isTempFilesSaved: false,
        //data: state.data,
      };
    case RESET_UPLOAD_TEMPORAL_FILES:
      return {
        ...state,
        data: state.data.filter(file => !file.idTempStored),
        isTempFilesSaved: false,
      }

    case SET_FILTERED_UPLOAD_FILES: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    }
    default:
      return state;
  }
}
