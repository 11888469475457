import {
  POST_SINISTER,
  POST_SINISTER_FULFILLED,
  POST_SINISTER_REJECTED,
  POST_SINISTER_PENDING,
  GET_SINISTER_POLICY_DETAIL,
  GET_SINISTER_POLICY_DETAIL_FULFILLED,
  GET_SINISTER_POLICY_DETAIL_REJECTED,
  GET_SINISTER_POLICY_DETAIL_PENDING,
  GET_SINISTER_POLICY_COVERAGE,
  GET_SINISTER_POLICY_COVERAGE_FULFILLED,
  GET_SINISTER_POLICY_COVERAGE_PENDING,
  GET_SINISTER_POLICY_COVERAGE_REJECTED,
  GET_SINISTER_COLLISION_TYPES,
  GET_SINISTER_COLLISION_TYPES_FULFILLED,
  GET_SINISTER_COLLISION_TYPES_PENDING,
  GET_SINISTER_COLLISION_TYPES_REJECTED,
  GET_FILES_SINISTER_PENDING,
  GET_FILES_SINISTER_FULFILLED,
  GET_FILES_SINISTER_REJECTED,
  GET_FILES_SINISTER,
  GET_SINISTER_POLICY_ITEMS,
  GET_SINISTER_POLICY_ITEMS_FULFILLED,
  GET_SINISTER_POLICY_ITEMS_PENDING,
  GET_SINISTER_POLICY_ITEMS_REJECTED,
  GET_PDF_SINISTER,
  GET_PDF_SINISTER_REJECTED,
  GET_PDF_SINISTER_FULFILLED,
  GET_PDF_SINISTER_PENDING
} from "../actions/types";

const initialState = {
  data: [],
  details: [],
  policyCoverage: {},
  collisionTypes: [],
  fileSinister: [],
  sinisterItems: [],
  loading: false,
  error: false,
  pdf: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POST_SINISTER_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case POST_SINISTER_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case POST_SINISTER_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    case POST_SINISTER:
      return {
        ...state,
        data: [],
      };
    case GET_SINISTER_POLICY_DETAIL_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_SINISTER_POLICY_DETAIL_FULFILLED:
      return {
        ...state,
        details: action.payload,
        loading: false,
        error: false,
      };
    case GET_SINISTER_POLICY_DETAIL_REJECTED:
      return {
        ...state,
        details: [],
        loading: false,
        error: true,
      };
    case GET_SINISTER_POLICY_DETAIL:
      return {
        ...state,
        details: [],
      };
    case GET_SINISTER_POLICY_COVERAGE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_SINISTER_POLICY_COVERAGE_FULFILLED:
      return {
        ...state,
        policyCoverage: action.payload,
        loading: false,
        error: false,
      };
    case GET_SINISTER_POLICY_COVERAGE_REJECTED:
      return {
        ...state,
        policyCoverage: {},
        loading: false,
        error: true,
      };
    case GET_SINISTER_POLICY_COVERAGE:
      return {
        ...state,
        policyCoverage: {},
      };
    case GET_SINISTER_COLLISION_TYPES_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_SINISTER_COLLISION_TYPES_FULFILLED:
      return {
        ...state,
        collisionTypes: action.payload,
        loading: false,
        error: false,
      };
    case GET_SINISTER_COLLISION_TYPES_REJECTED:
      return {
        ...state,
        collisionTypes: [],
        loading: false,
        error: true,
      };
    case GET_SINISTER_COLLISION_TYPES:
      return {
        ...state,
        collisionTypes: [],
      };
    case GET_FILES_SINISTER_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_FILES_SINISTER_FULFILLED:
      return {
        ...state,
        fileSinister: action.payload,
        loading: false,
        error: false,
      };
    case GET_FILES_SINISTER_REJECTED:
      return {
        ...state,
        fileSinister: [],
        loading: false,
        error: true,
      };
    case GET_FILES_SINISTER:
      return {
        ...state,
        fileSinister: [],
      };
    case GET_SINISTER_POLICY_ITEMS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_SINISTER_POLICY_ITEMS_FULFILLED:
      return {
        ...state,
        sinisterItems: action.payload,
        loading: false,
        error: false,
      };
    case GET_SINISTER_POLICY_ITEMS_REJECTED:
      return {
        ...state,
        sinisterItems: [],
        loading: false,
        error: true,
      };
    case GET_SINISTER_POLICY_ITEMS:
      return {
        ...state,
        sinisterItems: [],
      };
    case GET_PDF_SINISTER_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_PDF_SINISTER_FULFILLED:
      return {
        ...state,
        pdf: action.payload,
        loading: false,
        error: false,
      };
    case GET_PDF_SINISTER_REJECTED:
      return {
        ...state,
        pdf: [],
        loading: false,
        error: true,
      };
    case GET_PDF_SINISTER:
      return {
        ...state,
        pdf: [],
      };
    default:
      return state;
  }
}
