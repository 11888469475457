import {
    getDataAgentDocument,
    createDataAgentDocument,
    updateDataAgentDocument
} from '../sevices/agentDocumentService';

export const getDataAgentDocumentApi = (token) => async (dispatch) => {
    await dispatch(getDataAgentDocument(token, dispatch))
};

export const createDataAgentDocumentApi = (data) => async (dispatch) => {
    await dispatch(createDataAgentDocument(data, dispatch))
}

export const updateDataAgentDocumentApi = (data) => async (dispatch) => {
    await dispatch(updateDataAgentDocument(data, dispatch))
}