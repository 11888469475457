import React from "react";
import { Provider } from "react-redux";
import { store } from "./stores/store";
import Routes from "./ui/routes";
import "./App.css";
import AuthHandler from "./ui/authHandler";

const App = () => {
  return (
    <Provider store={store}>
      <AuthHandler>
        <Routes />
      </AuthHandler>
    </Provider>
  );
};

export default App;
