import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Hidden,
  CircularProgress,
} from "@material-ui/core";
import {
  Edit,
  Close,
  Block,
  ArrowBack,
  Person,
  Lock,
} from "@material-ui/icons";
import jwt_decode from "jwt-decode";
import useIsEmployee from "../../../../hooks/useIsEmployee";
import { getDataToken } from "../../../../utils/index";
import EditProfileBasic from "./EditProfileBasic";
import DrawerRight from "../../../drawerRight";
import EditPassword from "./EditPassword";
import { useStyles } from "./styles";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Grid>{children}</Grid>}
    </Grid>
  );
}

const Profile = ({ dataProfile, goBack, open, getDataProfile }) => {
  const token = useSelector((state) => state.auth.token);
  const userInfo = useSelector((state) => state.userInfo.data);
  const classes = useStyles();
  const isEmployee = useIsEmployee();
  const [isExtendedProfile, setIsExtendedProfile] = useState(null);
  const [dataForm, setDataForm] = useState({
    _id: "",
    userId: "",
    fullName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    pictureUrl: "",
  });
  const [disableInput, setDisableInput] = useState(true);
  const [enableEditProfile, setEnableEditProfile] = useState(false);
  const [value, setValue] = useState(0);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const handleChangeTabs = (e, newValue) => {
    setDisabledBtn(false);
    if (newValue === 1 && newValue === 3) {
      setDisabledBtn(true);
    }

    setValue(newValue);
  };

  const handleDisableEditProfile = () => {
    setEnableEditProfile(!enableEditProfile);
    setDisableInput(!disableInput);
  };

  useEffect(() => {
    if (dataProfile && token) {
      const dataToken = getDataToken(token);
      const decoded = jwt_decode(token);
      setDataForm({
        ...dataForm,
        _id: dataProfile._id,
        userId: dataProfile.userId,
        fullName: dataProfile.fullName,
        firstName: dataProfile.firstName,
        lastName: dataProfile.lastName,
        email: dataToken.user.email,
        address: dataProfile.address ? dataProfile.address : "",
        phone: dataProfile.phone ? dataProfile.phone : "",
        pictureUrl: dataProfile.pictureUrl ? dataProfile.pictureUrl : "",
        cc: dataProfile.cc ? dataProfile.cc : "",
      });
      setIsExtendedProfile(
        decoded.user.permissions.Agent?.ExtendedProfile.includes("read")
          ? true
          : false
      );
    }
  }, [dataProfile, token]);

  return (
    <Fragment>
      <DrawerRight open={open}>
        <Grid>
          <Container>
            <Grid container>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.menuProfile}
              >
                <IconButton color="inherit" className={classes.noPadding}>
                  <Hidden xsDown>
                    <Close onClick={goBack} />
                  </Hidden>
                  <Hidden smUp>
                    <ArrowBack onClick={goBack} />
                  </Hidden>
                </IconButton>
                <Typography className={classes.titleDrawer}>
                  {value === 0 && !enableEditProfile
                    ? "Ver Perfil"
                    : value === 0 && enableEditProfile
                    ? "Editar Perfil"
                    : null}
                  {value === 1 && !enableEditProfile && !isEmployee
                    ? "Cambiar Contraseña"
                    : null}
                </Typography>
                {enableEditProfile ? (
                  <IconButton
                    color="inherit"
                    className={classes.noPadding}
                    onClick={() => handleDisableEditProfile()}
                  >
                    <Block />
                  </IconButton>
                ) : (
                  <IconButton
                    color="inherit"
                    className={classes.noPadding}
                    onClick={() => handleDisableEditProfile()}
                    disabled={disabledBtn}
                  >
                    <Edit className={classes.btnClick} />
                  </IconButton>
                )}
              </Grid>
              <Grid container>
                {Object.keys(dataProfile).length === 0 ? (
                  <CircularProgress color="primary" />
                ) : (
                  <Grid item xs={12} style={{ paddingBottom: 30 }}>
                    {isEmployee || !isExtendedProfile ? (
                      <Tabs
                        className={classes.sectionTabBar}
                        value={value}
                        onChange={handleChangeTabs}
                        aria-label="simple tabs"
                      >
                        <Tab
                          className={
                            enableEditProfile && value === 0
                              ? classes.tabBarFull
                              : classes.tabBarMiddle
                          }
                          icon={<Person />}
                        />
                        <Tab
                          className={
                            enableEditProfile && value === 1
                              ? classes.tabBarFull
                              : classes.tabBarMiddle
                          }
                          icon={<Lock />}
                        />
                      </Tabs>
                    ) : (
                      <Tabs
                        className={classes.sectionTabBar}
                        value={value}
                        onChange={handleChangeTabs}
                        aria-label="simple tabs"
                      >
                        <Tab
                          className={
                            enableEditProfile && value === 0
                              ? classes.tabBarFull
                              : classes.tabBar
                          }
                          icon={<Person />}
                        />

                        <Tab
                          className={
                            enableEditProfile && value === 1
                              ? classes.tabBarFull
                              : classes.tabBar
                          }
                          icon={<Lock />}
                        />
                      </Tabs>
                    )}
                    <TabPanel value={value} index={0}>
                      <EditProfileBasic
                        dataProfile={dataProfile}
                        getDataProfile={getDataProfile}
                        disableInput={disableInput}
                        handleDisableEditProfile={handleDisableEditProfile}
                      />
                    </TabPanel>

                    {userInfo.userId.from === "app" ||
                    userInfo.userId.from === undefined ? (
                      <TabPanel
                        value={value}
                        index={isEmployee || !isExtendedProfile ? 1 : 3}
                      >
                        <EditPassword
                          disableInput={disableInput}
                          handleDisableEditProfile={handleDisableEditProfile}
                        />
                      </TabPanel>
                    ) : (
                      <Grid style={{ paddingTop: 30 }}>
                        <Typography style={{ color: "grey" }}>
                          No puedes cambiar tu contraseña aquí, si no mediante{" "}
                          {userInfo.userId.from}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </DrawerRight>
    </Fragment>
  );
};

export default Profile;
