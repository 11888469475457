import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Avatar,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Room, Call, AddLocation, Close, VideoCall } from "@material-ui/icons";
import isEmpty from "lodash/isEmpty";

import { ProfessionalDoctorIcon, MedicalCenterIcon } from "../../assets/icons";

const useStyles = makeStyles((theme) => ({
  topBorder: {
    border: "1px solid rgba(189, 189, 189, 0.8)",
    width: 109,
    margin: "0 auto",
  },
  section: {
    position: "relative",
    padding: "1rem 1rem 0.2rem 1rem",
    display: "flex",
    flexDirection: "column",
    "& .MuiGrid-container": {
      marginBottom: ".5rem",
    },
  },
  title: {
    fontSize: 14,
    letterSpacing: 0.1,
    color: theme.palette.primary.light,
    display: "block",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: ".1rem",
  },
  titleIcon: {
    width: 10,
    height: 10,
    backgroundColor: theme.palette.primary.light,
    borderRadius: "50%",
    color: "#fff",
    padding: 1,
    marginLeft: 8,
  },
  subtitle: {
    fontSize: 8,
    letterSpacing: 0.25,
    textTransform: "uppercase",
    color: "#4B4B4B",
    display: "block",
  },
  additionalInfo: {
    fontSize: 9,
    letterSpacing: "0.01071em",
    color: "#828282",
    textTransform: "none",
    display: "block",
  },
  helper: {
    fontSize: 8,
    letterSpacing: 0.1,
    color: "#C4C4C4",
    marginLeft: 5,
  },
  buttonBase: {
    flex: "0 0 auto",
    padding: 12,
    overflow: "visible",
    fontSize: "1.5rem",
    textAlign: "center",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "50%",
    border: 0,
    cursor: "pointer",
    display: "inline-flex",
    outline: 0,
    position: "relative",
    alignItems: "center",
    verticalAlign: "middle",
    appearance: "none",
    justifyContent: "center",
    textDecoration: "none",
  },
  button: {
    backgroundColor: theme.palette.primary.light,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 12,
    width: 20,
    height: 20,
    padding: 0,
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20,
    },
  },
  buttonsGroup: {
    padding: "1rem 0",
    borderTop: "0.25px solid rgba(151, 151, 151, 0.3)",
    "& .MuiGrid-item": {
      textAlign: "center",
      flex: "25%",
    },
  },
  buttonLabel: {
    fontSize: 10,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    color: "#979797",
    display: "block",
    marginTop: 5,
  },
  favoriteButton: {
    color: theme.palette.primary.light,
    backgroundColor: "#fff",
    border: `1px solid ${theme.palette.primary.light}`,
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  avatar: {
    width: 57,
    height: 57,
    marginRight: "1rem",
    backgroundColor: theme.palette.primary.dark,
    "& .MuiSvgIcon-root": {
      width: 33,
      height: 33,
    },
  },
}));

const MedicalNetworkOptions = ({ selected, open, handleClose, desktop }) => {
  const classes = useStyles();

  const getIconByType = (t) => {
    switch (t.toLowerCase()) {
      case "clínica":
        return <MedicalCenterIcon />;
      case "sucursal":
        return <MedicalCenterIcon />;
      case "profesional":
        return <ProfessionalDoctorIcon />;
      default:
        return <AddLocation />;
    }
  };

  const disabledBtnMap = useMemo(
    () => (selected && isEmpty(selected.adress.location) ? true : false),
    [selected]
  );
  const disabledBtnPhone = useMemo(
    () =>
      (selected && isEmpty(selected.adress.phone)) ||
      (selected &&
        selected.adress.phone
          .filter((phone) => phone.isForCall)
          .map((phone) => phone.number).length === 0)
        ? true
        : false,
    [selected]
  );

  return (
    <div style={{ paddingTop: "0.15rem" }}>
      <div className={classes.topBorder}></div>
      {selected && (
        <div className={classes.section}>
          {!desktop && (
            <div
              className={clsx(classes.closeButton, classes.buttonBase)}
              onClick={() => {
                handleClose(false);
              }}
            >
              <Close />
            </div>
          )}
          <Grid container>
            <Avatar aria-label="type" className={classes.avatar}>
              {getIconByType(selected?.type?.icon)}
            </Avatar>
            <div>
              <div className={classes.titleContainer}>
                <Typography
                  component="span"
                  variant="body1"
                  color="inherit"
                  className={classes.title}
                >
                  {selected.name}
                </Typography>
                {selected.virtual && (
                  <Tooltip arrow title="Asistencia Virtual">
                    <IconButton color="secondary">
                      <VideoCall />
                    </IconButton>
                  </Tooltip>
                )}
                {/* {selected.chargePercentaje !== null && <AttachMoney className={classes.titleIcon}/>} */}
              </div>
              <Typography
                component="span"
                variant="body1"
                color="inherit"
                className={classes.subtitle}
              >
                <strong>Especializaciones:</strong>
                <ul>
                  {selected.services.map((sel, i) => (
                    <li key={i}>{sel.name}</li>
                  ))}
                </ul>
              </Typography>
              <Typography
                component="span"
                variant="body1"
                color="inherit"
                className={classes.additionalInfo}
              >
                <strong>Ubicación:</strong>
                <br />
                {`${selected?.adress?.street ?? ""} ${
                  selected?.adress?.city ?? ""
                } ${selected?.adress?.state ?? ""}`}
                <br />
                <strong>Correo:</strong>
                <br />
                {selected.eMail}
                <br />
                {selected.adress.phone
                  .filter((phone) => phone.isForCall)
                  .map((phone) => phone.number)
                  .join(" | ")}
              </Typography>
              <Typography
                className={classes.additionalInfo}
                component="span"
                variant="body1"
                color="inherit"
              >
                <strong>Horario de Atención:</strong>
                <br />
                {selected?.schedule ? selected?.schedule : "No disponible"}
              </Typography>
            </div>
          </Grid>
        </div>
      )}

      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        className={classes.buttonsGroup}
      >
        <Grid item>
          <IconButton
            disabled={disabledBtnMap}
            aria-label="maps"
            className={clsx(classes.button, classes.buttonBase)}
            onClick={() =>
              redirect_blank(
                `https://maps.google.com/?q=${
                  selected.adress.location.lat || ""
                },${selected.adress.location.lng || ""}`
              )
            }
          >
            <Room />
          </IconButton>
          <span className={classes.buttonLabel}>¿Cómo llegar?</span>
        </Grid>
        {!desktop && (
          <Grid item>
            <IconButton
              disabled={disabledBtnPhone}
              aria-label="phone"
              className={clsx(classes.button, classes.buttonBase)}
              onClick={() =>
                (document.location.href = `tel:${
                  selected &&
                  selected.adress.phone
                    .filter((phone) => phone.isForCall)
                    .map((phone) => phone.number)[0]
                }`)
              }
            >
              <Call />
            </IconButton>
            <span className={classes.buttonLabel}>llamar</span>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

function redirect_blank(url) {
  let a = document.createElement("a");
  a.target = "_blank";
  a.href = url;
  a.click();
}

export default MedicalNetworkOptions;
