import moment from 'moment';

const getDateComparation = (v1, v2, type) => {
  switch(type) {
    case 'minor':
      return moment(v1).isBefore(moment(v2));
    case 'major':
      return moment(v1).isAfter(moment(v2));
    case 'equal':
      return moment(v1).isSame(moment(v2));
    default:
      console.log('No type found!');
      break;
  }
};

export const getFilterCondition = (v1, v2, type) => {
  if (moment(v1, 'YYYY-MM-DD', true).isValid()) {
    return getDateComparation(v1, v2, type);
  } else if (typeof v1 === 'string') {
    const _v2 = v2 ? v2 : '';
    return v1.toLowerCase() === _v2.toLowerCase();
  } else {
    return v1 === v2;
  }
}