import { makeStyles } from "@material-ui/core";

export const cardInsured = makeStyles((theme) => ({
  content: {
    paddingLeft: 14,
    paddingRight: 13,
    paddingTop: 17,
    paddingBottom: 22,
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
    borderRadius: 15,
  },
  rootLibra: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
    borderRadius: 15,
    cursor: "pointer",
    "&:hover": {
      transform: "translateY(-10px)",
    },
  },
  header: {
    marginBottom: 16,
  },
  title: {
    display: "block",
    color: "#333333",
    width: "100%",
    height: 48,
    fontFamily: "Work Sans",
    fontWeight: 500,
    fontSize: 22,
    lineHeight: "24px",
    letterSpacing: "-1px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  status: {
    color: ({ colorStatus }) => colorStatus,
    display: "flex",
    "& > svg": {
      marginTop: 10,
      fill: "transparent",
    },
    "& > p": {
      marginTop: 10,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "16px",
      letterSpacing: "1.25px",
    },
  },
  policy: {
    display: "block",
    marginTop: 4,
    color: theme.palette.neutral.grey5,
    fontStyle: "normal",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "-0.04px",
  },
  ramo: {
    fontStyle: "normal",
    display: "block",
    color: theme.palette.typography && theme.palette.typography.main,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "-0.04px",
  },
  dueDate: {
    display: "block",
    marginTop: 20,
    color: theme.palette.typography && theme.palette.typography.main,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "14.06px",
    letterSpacing: "0.5px",
  },
  icon: {
    width: 56,
    height: "auto",
    maxHeight: 45,
  },
  stateColor: {
    color: ({ colorStatus }) => colorStatus,
  },
  text: {
    paddingLeft: 15,
  },
  selected: {
    border: `2px solid ${
      theme.palette.variants && theme.palette.variants.variant1
    }`,
    boxShadow: `0px 6px 20px -5px ${
      theme.palette.variants && theme.palette.variants.variant1
    }`,
    transform: "translateY(-10px)",
  },
}));
