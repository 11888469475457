import { makeStyles } from '@material-ui/core';

export const cardComplaintsStyle = makeStyles((theme) => ({
    root: {
        position: 'relative',
        padding: 20,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0
        }
    },
    icon: {
        width: 70,
        height: 70
    },
    pagination: {
        position: 'absolute',
        right: 13,
        top: 12,
        fontSize: 12,
        color: theme.palette.typography && theme.palette.typography.main
    },
    dataComplaint: {
        paddingLeft: 25
    },
    title: {
        fontSize: 23,
        lineHeight: '30px',
        fontWeight: 400,
        letterSpacing: '-1px',
        color: theme.palette.typography && theme.palette.typography.variant1,
        marginBottom: 5
    },
    policy: {
        fontSize: 23,
        lineHeight: '30px',
        fontWeight: 400,
        letterSpacing: '-1px',
        color: theme.palette.primary && theme.palette.primary.main,
        marginBottom: 8,
        cursor: 'pointer'
    },
    status: {
        marginTop: 10,
        fontSize: 13,
        lineHeight: '24px',
        fontWeight: 300,
        color: theme.palette.typography && theme.palette.typography.main
    },
    statusContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 22,
        marginTop: 10,
        '& p:first-child': {
            fontSize: 12,
            lineHeight: '16px',
            fontWeight: 500,
            letterSpacing: 1.25,
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            color: '#219653',
            '& span': {
                border: '6px solid #6FCF97',
                borderRadius: '50%',
                width: 4,
                display: 'block',
                height: 4,
                marginRight: 8
            }
        },
        '& p:last-child': {
            fontSize: 12,
            lineHeight: '14.06px',
            fontWeight: 500,
            letterSpacing: 0.04,
            margin: 0,
            color: '#C4C4C4'
        },
    },
    progress: {
        borderRadius: 5
    }
}));
