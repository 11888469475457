import { createAsyncAction } from "redux-promise-middleware-actions";
import {
    insuredUrlApi,
  exceptionCodeResponse,
  validateJSONResponse,
} from "../utils/urls";

export const attendanceAvailableAPI = createAsyncAction(
  "GET_ATTENDANCE_AVAILABLE",
  async (token, payload) => {
    const body = {"insured_code" : payload}
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    const res = await fetch(`${insuredUrlApi}insured/list/attendanceAvailable`, options)
      .then((response) => validateJSONResponse(response))
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });
    return res;
  }
);

export const getAttendanceByInsuredAPI = createAsyncAction(
  "GET_ATTENDANCE_BY_INSURED",
  async (token, payload) => {
    const body = {"insured_code": payload};
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    const res = await fetch(`${insuredUrlApi}insured/list/attendance`, options)
      .then((response) => validateJSONResponse(response))
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });
    return res;
  }
);
