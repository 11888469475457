import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Button, Grid, TextField, CircularProgress, Typography } from '@material-ui/core';
import {
    renderTextField,
    CustomUploadFileAsync,
    UploadFileAsync,
    renderSelectField,
    renderDateField
} from '../form/renderInputsForm';
import { RenderSearchSelectFieldSimple } from '../quoter/quoteInputs/index';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { showError } from '../../actions/notificationActions';
import { resetUploadFile } from "../../actions/uploadFiles";
import { generateTemporaryIdentityNumber } from "../../actions/fileManagementActions";

const useStylesForm = makeStyles((theme) => ({
    sectFormComment: {
        display: 'flex',
        alignItems: 'baseline',
        padding: '15px !important'
    },
    fontSizeLabel: {
        '& label': {
            fontSize: 14
        }
    }
}));
function DetailsSupportForm({ handleStep, last, disableSubmit = false, policy }) {
    const classesForm = useStylesForm();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [filesSinister, setFilesSinister] = useState([]);
    const { sinister : { fileSinister } } = useSelector((state) => state);
    const { form : { detailgeneral } } = useSelector((state) => state);
    const { form : { supportUploaded } } = useSelector((state) => state);
    const { sinister : { policyCoverage } } = useSelector((state) => state);
    const [settingField] = useState({
        identifierType: 'siniestros',
        branch: null,
        isTemporal: true
    })
    const fileTypes = ".jpg,.jpeg,.png,.pdf,.doc,.docx";
    const { data: uploadedFiles, temporaryIdentityNumber } = useSelector(
        (selector) => selector.fileManagement
    );

    useEffect(() => {
        const firstLoad = async () => {
            let policyCoverages = policyCoverage?.data?.result?.result
            const causeCode = detailgeneral.values.cover;
            const items = policyCoverages.filter((element) => element.cod_cober === causeCode)
            const files = fileSinister.filter((element) => parseInt(element.cod_ram_tec) === items[0].cod_ramo_tec)
            setFilesSinister(files)
        }
        dispatch(resetUploadFile());
        dispatch(generateTemporaryIdentityNumber());
        firstLoad()
    }, []);

    const formatFilesSinister = useMemo(() => {
        return filesSinister.map((x) => x);
    }, [filesSinister]);

    const validateFiles = () => {
        // Validate if are there files to upload
        if (formatFilesSinister.length > 0) {
            // required files list
            const requiredFiles = formatFilesSinister.filter(item => item.required.toLowerCase() === 'true');
            // Validate if are there required files to upload to follow this process
            if (requiredFiles.length > 0 && supportUploaded !== undefined) {
                // Fields names already uploaded
                const uploadedKeyFiles = Object.keys(supportUploaded.values);
                // Counter to get files required already uploaded
                const requiredFilesAlreadyUploaded = requiredFiles.filter((item) => { 
                    if (uploadedKeyFiles.includes(item.file_description)) {
                        return item    
                    }
                })
                if (requiredFiles.length === requiredFilesAlreadyUploaded.length) {
                        return false
                } else {
                    dispatch(showError('No se han subido todos los archivos requeridos.'));
                    return true
                }
            } else {
            dispatch(showError('No se han subido todos los archivos requeridos.'));
            return true
            }
        } else {
            return false
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const validation = validateFiles()
        if (validation) {
            console.log('Faltan archivos por subir !!!!!!!!!');
        } else {
            e.preventDefault();
            handleStep({
                step: "support",
            });
        }
    };

    const parseBoolean = (val) => {
        let word  = val.toLowerCase()
        if (typeof val === 'string' && (word === 'true' || word === 'false')) {
            return JSON.parse(word);
        }
        return val
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
            >
                {
                    filesSinister.length === 0 ? (
                        <Grid item xs={12} md={6}>
                            <Typography className={classes.subtitle}>
                                No requiere archivos de soporte
                            </Typography>
                        </Grid>
                    ) : !formatFilesSinister ? (
                        <Grid item xs={12} md={12}>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        formatFilesSinister.map((typeFile, index) => (
                            <Grid key={`fileSinister-${index}`} item xs={8} md={6} style={{ padding: 8 }}>
                                <Field
                                    key={`field-${typeFile.cod_ram_tec}-${typeFile._id}`}
                                    name={typeFile.file_description}
                                    label={typeFile.file_description}
                                    custom={{ tabIndex: 1 }}
                                    component={CustomUploadFileAsync}
                                    settings={settingField}
                                    maxSize={3000}
                                    fileTypes={fileTypes}
                                    mandatory={parseBoolean(typeFile.required)}
                                    temporaryIdentityNumber={temporaryIdentityNumber}
                                    // disabled
                                />
                            </Grid>
                        ))
                    )
                }
                {
                    filesSinister.length > 0 ? (
                        <Grid item xs={12} style={{ paddingTop: 16, paddingLeft: 8, color: 'rgba(0, 0, 0, 0.54)' }}>
                            <span>Los tipos de archivos permitidos son: pdf, png, jpg, jpeg, doc o docx</span>
                        </Grid>
                    ) : null
                }
                {
                    policy?.codigo_ramo == 4 || policy?.codigo_ramo == 24 ?
                    <Grid item xs={12} style={{ paddingTop: 16, paddingLeft: 8, color: 'rgba(0, 0, 0, 0.54)' }}>
                        <span>Si tienes documentación adicional como presupuesto, vídeo del evento, causa penal, etc. Enviar a ampliacionesdenuncias@libraseguros.com.ar.</span>
                    </Grid> : null
                }
                <Grid item sx={6} md={6} style={{ padding: 10 }}></Grid>
                <Grid item xs={12} md={12} style={{ padding: 0 }}>
                    <div className={classes.buttonContainer}>
                        <Button
                            disabled={disableSubmit}
                            type="submit"
                            className={classes.buttonNextStep}
                        >
                            {last ? 'Enviar' : 'Siguiente'}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}

DetailsSupportForm.propTypes = {};

export default reduxForm({ form: 'support' })(DetailsSupportForm);
