import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { navigate } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';

import {
    ClickAwayListener,
    AppBar,
    Grid,
    Toolbar,
    Typography,
    IconButton,
    Avatar,
    Hidden
} from '@material-ui/core';
import {
    NotificationsOutlined,
    PowerSettingsNew,
    Menu
} from '@material-ui/icons';
import SectionTitle from '../sectionTitle/sectionTitle';
import { getLogout } from '../../actions/authActions';
import { useStyles } from './styles';
import { getDataUserLoggeIn } from '../../actions/userInfoActions';
import { getDataAgentBankApi } from '../../actions/agentBankActions';
import { getDataAgentDocumentApi } from '../../actions/agentDocumentActions';
import { getAccountTypesApi } from '../../actions/accountTypeActions';
import DrawerEditProfile from './drawers/drawerEditProfile';
import DrawerNotification from './drawers/drawerNotification';
import DrawerMenuMobile from './drawers/drawerMenuMobile';
import StyledBadge from '../notifications/styledBadge';
import { getUserConfig } from '../../actions/userConfigActions';
import { INSURED_CLIENT } from '../../utils/urls';
import AvatarDefault from '../../assets/images/avatar_libra_asegurados.png';

const BarMenu = ({ menuOpen, notifications }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [menuProfile, setMenuProfile] = useState(false);
    const [showEditProfile, setShowEditProfile] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [showMenuDrawer, setShowMenuDrawer] = useState(true);
    const [showDrawerMenuMobile, setShowDrawerMenuMobile] = useState(false);
    const [, setShowDrawerNotifications] = useState(false);
    const [, setOpen] = useState(false);

    const userInfo = useSelector((state) => state.userInfo);
    //console.log("userInfo: ", userInfo);
    const agentBankInfo = useSelector((state) => state.agentBankInfo);
    const agentDocumentInfo = useSelector((state) => state.agentDocumentInfo);
    const auth = useSelector((state) => state.auth);
    //console.log("auth: ", auth);
    const config = useSelector((state) => state.userConfig.data);

    const handleDrawerMenuMobile = () => {
        setShowDrawerMenuMobile(!showDrawerMenuMobile);
    };

    const handleDrawerNotification = () => {
        setShowNotification(!showNotification);
    };

    const handleLogout = () => {
        dispatch(getLogout());
        navigate('/login');
    };

    const handleDrawerEditProfile = () => {
        //abrir o cerrar drawer edit profile
        setShowEditProfile(!showEditProfile);
    };

    const handleDrawerNotifications = (opt) => {
        setShowEditProfile(false);
        setShowDrawerNotifications(opt);
    };

    const handleGetDataUserLoggeIn = () => {
        if (auth.token) {
            dispatch(getDataUserLoggeIn(auth.token));
        }
    };

    const handleGetApiDataAgentBank = () => {
        if (auth.token) {
            dispatch(getDataAgentBankApi(auth.token));
        }
    };

    const handleGetApiDataAgentDocument = () => {
        if (auth.token) {
            dispatch(getDataAgentDocumentApi(auth.token));
        }
    };

    const handleGetApiDataAllAccountTypes = () => {
        if (auth.token) {
            dispatch(getAccountTypesApi(auth.token));
        }
    };

    const handleGetUserConfig = (token) => {
        if (token) {
            dispatch(getUserConfig(token));
        }
    };

    useEffect(() => {
        if (menuProfile) {
            setMenuProfile(false);
        }
        setShowEditProfile(false);
        if (!showMenuDrawer) {
            setShowMenuDrawer(true);
        }
        if (auth.token) {
            //Info Basic
            handleGetDataUserLoggeIn(auth.token);
            //Theme config
            handleGetUserConfig(auth.token);
            //Agent Basic
            //handleGetApiDataAgentBank(auth.token);
            //Document Basic
            //handleGetApiDataAgentDocument(auth.token)
            //All banks
            //handleGetApiDataAllBanks(auth.token)
            // All account types
            handleGetApiDataAllAccountTypes(auth.token);
        }
    }, [auth]);

    const handleCloseDrawer = () => {
        setOpen(false);
    };

    const unreadMessages = notifications.filter((noti) => !noti?.read);

    const renderAvatar = () => {
        return (
            <>
                {userInfo.data ? (
                    userInfo.data.pictureUrl ? (
                        <Avatar src={userInfo.data.pictureUrl} />
                    ) : (
                        <Avatar className={classes.iconUser}>
                            {userInfo.data.firstName ? (
                                <Typography
                                    style={{
                                        fontStyle: 'uppercase'
                                    }}
                                >
                                    {`${userInfo.data.firstName.charAt(
                                        0
                                    )}${userInfo.data.lastName.charAt(0)}`}
                                </Typography>
                            ) : null}
                        </Avatar>
                    )
                ) : null}
            </>
        );
    };

    const renderAvatarLibra = () => {
        return (
            <>
                {userInfo.data ? (
                    userInfo.data.pictureUrl ? (
                        <Avatar src={userInfo.data.pictureUrl} alt="avatar" />
                    ) : (
                        <Avatar src={AvatarDefault} alt="avatar" />
                    )
                ) : null}
            </>
        );
    };

    return (
        <>
            <ClickAwayListener onClickAway={() => handleCloseDrawer()}>
                <Grid>
                    <AppBar
                        position="absolute"
                        className={clsx(
                            classes.appBar,
                            menuOpen && classes.appBarShift
                        )}
                    >
                        <Toolbar className={classes.toolbar}>
                            <Grid item className={classes.sectionMenu}>
                                <Hidden smUp>
                                    <IconButton
                                        onClick={() => handleDrawerMenuMobile()}
                                    >
                                        <Menu
                                            style={{
                                                color:
                                                    config.theme &&
                                                    config.theme.secondary.main
                                            }}
                                        />
                                    </IconButton>
                                </Hidden>
                                <Hidden xsDown>
                                    <Typography className={classes.title}>
                                        <SectionTitle />
                                    </Typography>
                                </Hidden>
                            </Grid>
                            <Grid className={classes.topMenu}>
                                <IconButton
                                    onClick={() => handleDrawerNotification()}
                                >
                                    <StyledBadge
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        overlap="circular"
                                        color="secondary"
                                        badgeContent={unreadMessages.length}
                                        max={99}
                                        // theme={config.theme}
                                    >
                                        <NotificationsOutlined
                                            style={{
                                                color:
                                                    config.theme &&
                                                    config.theme.secondary.dark
                                            }}
                                        />
                                    </StyledBadge>
                                </IconButton>
                                <Hidden xsDown>
                                    <IconButton onClick={() => handleLogout()}>
                                        <PowerSettingsNew
                                            style={{
                                                color:
                                                    config.theme &&
                                                    config.theme.secondary.dark
                                            }}
                                        />
                                    </IconButton>
                                </Hidden>
                                <Hidden xsDown>
                                    <IconButton
                                        aria-label="open drawer"
                                        onClick={() =>
                                            handleDrawerEditProfile()
                                        }
                                        edge="start"
                                        style={{ marginLeft: 0 }}
                                    >
                                        {INSURED_CLIENT === 'LIBRA'
                                            ? renderAvatarLibra()
                                            : renderAvatar()}
                                    </IconButton>
                                </Hidden>
                                <Hidden smUp>
                                    <IconButton
                                        aria-label="open drawer"
                                        onClick={() =>
                                            handleDrawerEditProfile()
                                        }
                                        edge="start"
                                    >
                                        {INSURED_CLIENT === 'LIBRA'
                                            ? renderAvatarLibra()
                                            : renderAvatar()}
                                    </IconButton>
                                </Hidden>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <DrawerMenuMobile
                        open={showDrawerMenuMobile}
                        handleDrawerMenuMobile={handleDrawerMenuMobile}
                    />

                    <DrawerEditProfile
                        open={showEditProfile}
                        goBack={() => handleDrawerEditProfile()}
                        openNotifications={() =>
                            handleDrawerNotifications(true)
                        }
                        //datos
                        dataProfile={userInfo.data}
                        dataAgentBank={agentBankInfo.data}
                        dataAgentDocument={agentDocumentInfo.data}
                        //consultas
                        getDataProfile={handleGetDataUserLoggeIn}
                        getDataAgentBank={handleGetApiDataAgentBank}
                        getDataAgentDocument={handleGetApiDataAgentDocument}
                    />

                    <DrawerNotification
                        open={showNotification}
                        goBack={() => handleDrawerNotification()}
                    />
                </Grid>
            </ClickAwayListener>
        </>
    );
};

export default BarMenu;
