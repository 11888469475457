import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SearcherPolicy from '../../components/sinisterPolicy/SearcherPolicy';
import { Search } from '@material-ui/icons';
import AdvanceLineSinisterComponent from './components/AdvanceLineSinisterComponent';
function PolicyComponent({ children }) {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <AdvanceLineSinisterComponent />
            </Grid>
        </Grid>
    );
}

export default PolicyComponent;
