import {
  GET_ATTENDANCE_AVAILABLE_FULFILLED,
  GET_ATTENDANCE_AVAILABLE_PENDING,
  GET_ATTENDANCE_AVAILABLE_REJECTED,
  GET_ATTENDANCE_BY_INSURED_FULFILLED,
  GET_ATTENDANCE_BY_INSURED_PENDING,
  GET_ATTENDANCE_BY_INSURED_REJECTED,
} from "../actions/types";

const initialState = {
  data: [],
  loading: false,
  error: false,
};

export const attendanceAvailableReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ATTENDANCE_AVAILABLE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_ATTENDANCE_AVAILABLE_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_ATTENDANCE_AVAILABLE_REJECTED:
      return {
        ...state,
        data: [],
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};


export const getAttendanceByInsuredReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ATTENDANCE_BY_INSURED_PENDING:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case GET_ATTENDANCE_BY_INSURED_FULFILLED:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case GET_ATTENDANCE_BY_INSURED_REJECTED:
        return {
          ...state,
          data: [],
          loading: false,
          error: true,
        };
      default:
        return state;
    }
  };