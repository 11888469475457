import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPolicies, getTrips, getUpdatedTrips } from '../../actions/orangeTimeActions';

const OrangeMenu = ({ token }) => {
  const dispatch = useDispatch();
  const orange = useSelector((state) => state.orange);

  const handleGetPolicies = async () => {
    await dispatch(getPolicies(token));
  };

  const handleGetTrips = async (data) => {
    await dispatch(getTrips(data, token));
  };

  const handleUpdateTrips = async (data, trips) => {
    await dispatch(getUpdatedTrips(data, trips, token));
  };

  useEffect(() => {
    handleGetPolicies();
  }, []);

  useEffect(() => {
    if (orange.data) {
      handleGetTrips(orange.data);
    }
  }, [orange.data]);

  useEffect(() => {
    let interval = null;
    if (orange.trips.length > 0) {
      interval = setInterval(() => {
        if (!orange.refetching) {
          console.log('----UPDATING TRIPS----');
          handleUpdateTrips(orange.data, orange.trips, token);
        }
      }, 180000);
    }

    return () => clearInterval(interval);
  }, [orange.trips]);

  console.log(orange);
  return null;
}

export default OrangeMenu