import { createAsyncAction } from "redux-promise-middleware-actions";
import {
  GET_MEDICAL_NETWORK_ATTENTION_POINT_TYPES,
  GET_MEDICAL_NETWORK_SERVICES,
  GET_MEDICAL_NETWORK_SISE,
  GET_SUPPLIER_MEDICAL_NETWORK_SISE,
} from "../actions/types";
import {
  urlBaseApi,
  validateResponse,
  exceptionCodeResponse,
  insuredApi,
} from "../utils/urls";

export const getMedicalNetworkAPI = createAsyncAction(
  "GET_MEDICAL_NETWORK",
  async (token = null, page = 0, search = "", filtersList = {}) => {
    const headers = { Authorization: `Bearer ${token}` };
    const nearest = filtersList.nearest.show;
    const favorite = filtersList.favorite.show;
    const services = filtersList.specialities.join(",");
    const locationType = filtersList.location.value || "";
    const locationSearch = filtersList.location.search || "";
    const typesList = Object.entries(filtersList.tsupplier || {});
    const queryTypes = typesList.reduce(function (filtered, option) {
      const [, obj] = option;
      if (obj.active) {
        filtered.push(obj.value);
      }
      return filtered;
    }, []);

    const res = await fetch(
      `${urlBaseApi}attentionPoints?page=${page}&limit=4&search=${search}&nearest=${nearest}&favorite=${favorite}&services=${services}&locationType=${locationType}&locationSearch=${locationSearch}${
        queryTypes.length ? `&types=${queryTypes.join(",")}` : ""
      }`,
      { headers }
    )
      .then((response) => validateResponse(response))
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });

    return res;
  }
);

export const getMedicalNetworkSISEAPI = createAsyncAction(
  GET_MEDICAL_NETWORK_SISE,
  async (token = null, name = "", filterList, suppliers = [], service = "") => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const filter = { name };
    if (filterList && filterList.location && filterList.location.value) {
      filter.location = filterList.location;
    }
    if (suppliers.length) {
      filter.suppliers = suppliers;
    }
    if (service.length) {
      filter.service = service;
    }
    
    const body = JSON.stringify(filter);
    const res = await fetch(`${insuredApi}v1/insured/list/medicalNetworks`, {
      method: "POST",
      headers,
      body,
    })
      .then((response) => validateResponse(response))
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });

    return res;
  }
);

export const getMedicalNetworkServicesAPI = createAsyncAction(
  "GET_MEDICAL_NETWORK_SERVICES",
  async (token = null) => {
    const headers = { Authorization: `Bearer ${token}` };

    const res = await fetch(`${urlBaseApi}attentionPoints/services`, {
      headers,
    })
      .then((response) => validateResponse(response))
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });

    return res;
  }
);

export const getMedicalNetworkPointAttentionTypeAPI = createAsyncAction(
  GET_MEDICAL_NETWORK_ATTENTION_POINT_TYPES,
  async (token = null) => {
    const headers = { Authorization: `Bearer ${token}` };

    const res = await fetch(`${urlBaseApi}attentionPointsTypes`, {
      headers,
    })
      .then((response) => validateResponse(response))
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });

    return res;
  }
);

export const getSuppliersAPI = createAsyncAction(
  GET_SUPPLIER_MEDICAL_NETWORK_SISE,
  async (token = null) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const body = JSON.stringify({});
    const res = await fetch(
      `${insuredApi}v1/insured/list/suppliersNetworkType`,
      {
        method: "POST",
        headers,
        body,
      }
    )
      .then((response) => validateResponse(response))
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });

    return res;
  }
);

export const getServicesSISEAPI = createAsyncAction(
  GET_MEDICAL_NETWORK_SERVICES,
  async (token = null) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const body = JSON.stringify({});
    const res = await fetch(
      `${insuredApi}v1/insured/list/servicesNetworkType`,
      {
        method: "POST",
        headers,
        body,
      }
    )
      .then((response) => validateResponse(response))
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });

    return res;
  }
);
