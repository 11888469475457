import {
  fileManagementUploadAPI,
  fileManagementChangeTemporalyToCompleteAPI,
  generateTemporaryIdentityNumberAPI
} from "../sevices/fileManagementService";

export const fileManagementUpload = (token, data, url) => async (dispatch) => {
  await dispatch(fileManagementUploadAPI(token, data, url, dispatch));
};

export const generateTemporaryIdentityNumber = () => async (dispatch) => {
  await dispatch(generateTemporaryIdentityNumberAPI(dispatch));
};

export const fileManagementChangeTemporalyToComplete = (token, data, url) => async (dispatch) => {
  await dispatch(fileManagementChangeTemporalyToCompleteAPI(token, data, url, dispatch));
};
