import {
    GET_Q_SISE_DASHBOARD_REJECTED,
    GET_Q_SISE_DASHBOARD_PENDING,
    GET_Q_SISE_DASHBOARD,
    GET_Q_SISE_DASHBOARD_FULFILLED,
} from '../actions/types';

  const initialState = {
    data: {},
    loading: false,
    error: false
  };

  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_Q_SISE_DASHBOARD_PENDING:
        return {
          ...state,
          loading: true,
          error: false
        };
      case GET_Q_SISE_DASHBOARD_FULFILLED:
        return {
          ...state,
          data: {...state.data, ...action.payload},
          loading: false,
          error: false
      };
      case GET_Q_SISE_DASHBOARD_REJECTED:
        return {
          ...state,
          data: [],
          loading: false,
          error: true
        };
      case GET_Q_SISE_DASHBOARD:
        return {
          ...state,
          selected: action.payload
        };
      default:
        return state;
    }
  }