import { createAsyncAction } from "redux-promise-middleware-actions";

import { getToken } from "../utils";
import {
  urlBaseApi,
  validateJSONResponse,
  exceptionCodeResponse,
  insuredUrlApi,
} from "../utils/urls";
import { GET_ORANGE_POLICIES, GET_ORANGE_TRIPS, UPDATE_ORANGE_TRIPS } from "../actions/types";

export const getPoliciesAPI = createAsyncAction(
  GET_ORANGE_POLICIES,
  async (token) => {
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const res = await fetch(`${insuredUrlApi}ot/trips`, options)
      .then((response) => validateJSONResponse(response))
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });

    return res.data;
  }
);

export const getTripsAPI = createAsyncAction(
  GET_ORANGE_TRIPS,
  async (data, token) => {
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data,
      }),
    };
    const res = await fetch(`${insuredUrlApi}ot/trips`, options)
      .then((response) => validateJSONResponse(response))
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });

    return res.data;
  }
);

export const updateTripsAPI = createAsyncAction(
  UPDATE_ORANGE_TRIPS,
  async (data, trips, token) => {
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data,
        trips
      }),
    };
    const res = await fetch(`${insuredUrlApi}ot/updatedtrips`, options)
      .then((response) => validateJSONResponse(response))
      .catch((error) => {
        throw exceptionCodeResponse(error);
      });

    return res.data;
  }
);

export const getTripsByPeriod = async (patent, start, end) => {
  const token = getToken();
  const data = {
    patent,
    start,
    end,
  };
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const res = await fetch(`${insuredUrlApi}ot/tripsbyperiod`, options)
    .then((response) => validateJSONResponse(response))
    .catch((error) => {
      throw exceptionCodeResponse(error);
    });

  return res.data;
};
