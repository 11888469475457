import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    notificationListItem: {
        fontSize: 16,
        color: '#333333',
        padding: '20px 27px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: 'white',
        border: '1px solid rgba(63, 63, 68, 0.005)',
        boxShadow: '0px 1px 0px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15)',
    },
    list: {
        borderRadius: 4
    },
    notificationListItemSecondary: {
        fontSize: 14,
        display: 'flex',
        '& .hora': {
            fontSize: 14,
            fontStyle: 'italic',
            color: '#000000'
        },
        '& .fecha': {
            fontSize: 14,
            color: '#000000',
            paddingLeft: 10
        }
    },
    unread: {
        backgroundColor: theme.palette.variants && theme.palette.variants.variant3,
        fontWeight: 600,
        '& > div:last-child': {
            color: '#000000'
        },
        '&:hover': {
            backgroundColor: theme.palette.variants && theme.palette.variants.variant1,
        }
    },
    truncateText: {
        maxWidth: 200,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));
