import { getPoliciesAPI, getTripsAPI, updateTripsAPI } from "../sevices/orangeTimeService";

export const getPolicies = (token) => async (dispatch) => {
  await dispatch(getPoliciesAPI(token));
};

export const getTrips = (data, token) => async (dispatch) => {
  await dispatch(getTripsAPI(data, token));
};

export const getUpdatedTrips = (data, trips, token) => async (dispatch) => {
  await dispatch(updateTripsAPI(data, trips, token));
};
