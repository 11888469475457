import { createAsyncAction } from 'redux-promise-middleware-actions';
import settings from '../settings';
import { validateResponse } from '../utils/urls';

export const getPaymentMethodsAPI = createAsyncAction('GET_PAYMENT_METHODS', async (token) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    const res = await fetch(`${settings.quotationUrlBase}paymentMethodsPublic`, { headers })
        .then((response) => validateResponse(response))
        .catch((error) => {
            console.log('error: ', error);
            throw error;
        });
    return res;
});
