import { makeStyles } from '@material-ui/core';

export const useStyledProgress = makeStyles((theme) => ({
  root: {
    position: "relative"
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
  },
  top: {
    color: theme.palette.primary && theme.palette.primary.main,
    animationDuration: "550ms",
    position: "absolute",
    left: 0
  },
  circle: {
    strokeLinecap: "round"
  }
}));