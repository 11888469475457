import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    rightDrawer: {
        width: 450,
        height: '100%',
        zIndex: '9000 !important',
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: '1sg'
        }),
        ['@media (max-width:426px)']: { // eslint-disable-line no-useless-computed-key
            width: '100%',
            zIndex: '9000 !important'
        },
        borderTopLeftRadius: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    // Drawer Profile
    widthDrawerProfile: {
        paddingBottom: 50,
        '& .options': {
            padding: '0 50px',
            ['@media (max-width:426px)']: { // eslint-disable-line no-useless-computed-key
                padding: '0 20px',
            }
        },
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuProfile: {
        paddingTop: 30
    },
    titleDrawer: {
        fontSize: 16,
        color: '#3B3B3B',
        paddingLeft: 15,
        position: 'absolute',
        left: 50
    },
    btnClick: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    btnClickPurple: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.primary.dark
        }
    },
    sectionTabBar: {
        paddingTop: 30,
    },
    tabBar: {
        minWidth: '25% !important'
    },
    tabBarFull: {
        minWidth: '100% !important'
    },
    tabBarMiddle: {
        minWidth: '50% !important'
    },
    sectionAvatar: {
        paddingTop: 35,
        paddingBottom: 35,
        position: 'relative',
        '& .nameProfile': {
            paddingTop: 21,
            color: theme.palette.secondary.main
        },
        '& .iconUpload': {
            backgroundColor: '#6C63FF'
        }
    },
    photoProfile: {
        height: 155,
        width: 155,
        backgroundColor: theme.palette.primary.main
    },
    spaceInput: {
        paddingBottom: 20,
        width: '100%'
    },
    sectBtnSave: {
        paddingTop: 20,
        '& .btn-save': {
            backgroundColor: '#6C63FF'
        }
    },
    noPadding: {
        padding: 0
    }
}))