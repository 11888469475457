import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;
export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: "flex",
    padding: "0 8px",
    height: 110,
    ...theme.mixins.toolbar,
    "& > div": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      paddingLeft: 13,
    },
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    zIndex: "1000 !important",
    borderRight: "none !important",
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    zIndex: "1000 !important",
    overflowX: "hidden",
    position: "relative",
    borderRight: "none !important",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  iconActive: {
    color: theme.palette.secondary.main,
    zIndex: 2,
    fontSize: 23,
  },
  icon: {
    color: theme.palette.primary.main,
    zIndex: 2,
    fontSize: 23,
  },
  navList: {
    position: "relative",
    padding: "17px 0px 20px 8px",
    "& .MuiListItem-root": {
      alignItems: "flex-start",
    },
  },
  activeLinkSingle: {
    paddingLeft: 10,
  },
  activeLink: {
    "& > svg": {
      color: `${theme.palette.secondary.dark}`,
    },
    "& .MuiListItem-root": {
      color: theme.palette.primary.main,
      display: "flex",
      alignItems: "flex-start",
      "& span": {
        fontWeight: 700,
      },
    },
    "& .MuiListItemIcon-root": {
      zIndex: 2,
      minWidth: 56,
    },
    "& .MuiListItemText-root": {
      zIndex: 2,
    },
    "& .hover-effect": {
      width: "100% !important",
      height: "100%",
      left: 0,
      top: 0,
      position: "absolute",
      borderRadius: 40,
      zIndex: 1,
      transition: "width 0.5s ease-in",
    },
  },
  deactivateLink: {
    width: "100%",
    "& .MuiListItem-root": {
      color: "rgba(130, 130, 130, 0.47)",
    },
  },
  listText: {
    wordBreak: "break-word",
    whiteSpace: "break-spaces",
    minWidth: 140,
  },
  barIcon: {
    transition: "all .5s ease-out",
  },
  barIconRotate: {
    transform: "rotate(-180deg)",
  },
  listItem: {
    borderTopLeftRadius: 40,
    borderBottomLeftRadius: 40,
    "&.MuiListItem-button:hover": {
      // backgroundColor: '#F4F4FA'
    },
  },
  listItemActive: {
    "& span": {
      fontWeight: 900,
      color: theme.palette.primary.main,
    },
  },
  listItemBackground: {
    backgroundColor: "#F4F4FA",
    borderTopLeftRadius: 43,
    borderBottomLeftRadius: 43,
  },
  collapsibleItem: {
    "&:hover": {
      cursor: "pointer",
    },
    "& .icono": {
      minWidth: 0,
    },
    "& .titulo": {
      paddingLeft: 10,
      fontWeight: 900,
    },
  },

  menu: {
    paddingLeft: 8,
  },
  menuOpen: {
    position: "fixed",
    top: 0,
    left: 72,
    width: 250,
    height: "100vh",
    boxShadow: "26px 5px 32px -17px rgba(0, 0, 0, 0.1)",
    "& .div": {
      position: "relative",
      top: 110,
      borderTop: "1px solid #D8D0F6",
    },
  },
  menuExpand: {
    position: "fixed",
    top: 0,
    left: 72,
    // height: '100%',
    width: 245,
    backgroundColor: "#F4F4FA",
    boxShadow: "26px 5px 32px -17px rgba(0, 0, 0, 0.1)",
    zIndex: 2000,
  },
  backgroundOptionMenuActive: {
    backgroundColor: "#F4F4FA",
    borderRadius: "0px 20px 20px 0px",
  },
  optionMenuActive: {
    position: "absolute",
    left: 50,
    top: -10,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    zIndex: 1000,
    minWidth: 220,
    webkitBoxShadow: "4px 4px 7px 0px rgba(0,0,0,0.27)",
    boxShadow: "4px 4px 7px 0px rgba(0,0,0,0.27)",
    "& ul": {
      "& li": {
        "& a": {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  optionMenu: {
    position: "relative",
    left: 5,
    zIndex: 1000,
    paddingBottom: 15,
    "& ul": {
      "& li": {
        "& a": {
          color: "#828282",
        },
      },
    },
  },
  sectionIconSingle: {
    display: "flex",
    paddingLeft: 0,
    marginBottom: 20,
  },
  sectionIcon: {
    display: "flex",
    position: "relative",
    paddingLeft: 11,
    paddingBottom: 24,
    "& .line": {
      borderLeft: "4px solid",
      position: "absolute",
      height: 39,
      top: -8,
      left: -8,
      borderRadius: "0px 10px 10px 0px",
    },
    "& .bg-selected": {
      position: "absolute",
      height: 45,
      width: 65,
      backgroundColor: "#F4F4FA",
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      top: -11,
      left: 3,
    },
  },
  titleMenuActive: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    paddingLeft: 10,
  },
  titleMenuDisabled: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    paddingLeft: 10,
    paddingBottom: 9,
  },
  paddingList: {
    padding: 0,
    "& li": {
      padding: "10px 0px",
    },
  },
  optionActive: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    backgroundColor: "white",
    borderRadius: 20,
    width: "100%",
    padding: "11px 10px",
  },
  optionDeactivate: {
    color: theme.palette.secondary.main,
    paddingLeft: 10,
  },
  pointer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  arrowUp: {
    width: 0,
    height: 0,
    borderTop: "5px solid transparent",
    borderBottom: "5px solid transparent",
    borderRight: `8px solid ${theme.palette.primary.main}`,
    position: "absolute",
    top: "35%",
    left: -8,
  },
}));
