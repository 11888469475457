import React, { useEffect, useState, useMemo } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid, MenuItem } from '@material-ui/core';
import {
    renderTextField,
    renderSelectField,
    renderDateField,
    renderTimeField
} from '../form/renderInputsForm';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { getQsiseDashboard } from '../../actions/qSiseDashboardAction';
import { getItemsPolicy } from '../../actions/itemsPolicyAction';
import { getAllRiskType } from '../../actions/riskTypeActions';
import { getClaimCauses } from '../../actions/claimCausesAction';
import { showError } from '../../actions/notificationActions';
import { getSinisterPolicyDetail, getSinisterPolicyItems } from '../../actions/sinisterActions';
import { RenderSearchSelectFieldSimple } from '../quoter/quoteInputs';
import { SHOW_TECHNICAL_INSURANCE_RISK_TARGET_IDS } from '../../utils/urls';

const validate = (values) => {
    const errors = {};
    const requiredFields = [
        'incidenceDate',
        'startDate',
        'timeSinister',
        'policy',
        'tomador',
        'startDate',
        'endDate',
        'items',
        'email'
    ];

    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'El correo es invalido';
    } 

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    return errors;
};

const technicalInsuranceType = [
    {
        value: "Agricola"
    },
    {
        value: "Otros"
    }
]

function DetailPolicyForm({
    handleSubmit,
    handleStep,
    setPolicy,
    setCoberturas
}) {
    const dispatch = useDispatch();
    const [time, setTime] = useState();
    const [startDate, setStartDate] = useState();
    const [incidenceDate, setIncidenceDate] = useState();
    const [endDate, setEndDate] = useState();
    const [policyData, setPolicyData] = useState([]);
    const [showTechnicalInsuranceType, setShowTechnicalInsuranceType] = useState(false);

    const classes = useStyles();
    const { auth, risktype } = useSelector((state) => state);
    const { token } = auth;
    const sinisterPolicyDetail = useSelector((state) => state.sinister.details.data);
    const { ...qSiseDashboard } = useSelector((state) => state.qSiseDashboard);
    // const { data: { data: itemSinister } } = useSelector((state) => state.items);
    const itemSinister = useSelector((state) => state.sinister.sinisterItems?.data?.result);
    const executeItemsPolicy = async (id_pv) => {
        await dispatch(getItemsPolicy(token, id_pv));
    };

    const executeClaimCauses = async (prefixCode) => {
        await dispatch(getClaimCauses(token, prefixCode));
    };

    // ** call execute query policies
    useEffect(() => {
        try {
            if (Object.keys(qSiseDashboard.data).length === 0) {
                dispatch(getQsiseDashboard(token));
            }
            if (Object.keys(risktype.data).length === 0) {
                dispatch(getAllRiskType(token));
            }
        } catch (error) {
            // console.log('Error ', error);
            dispatch(showError('Error obteniendo los riesgos'))
        }
        /* if (Object.keys(insurance.data).length === 0) {
            dispatch(getInsurance(token));
        } */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (qSiseDashboard?.data?.data) {
            setPolicyData(qSiseDashboard?.data?.data?.listaPolizas?.result);
        }
    }, [qSiseDashboard]);

    useEffect(() => {
        if (sinisterPolicyDetail !== undefined) {
            if (sinisterPolicyDetail.length > 0) {
                dispatch(
                    change(
                        'detailpolicy',
                        'tomador',
                        sinisterPolicyDetail[0].Nombre_tomador || 'Nombre del tomador'
                    )
                );
            }
        }
    }, [sinisterPolicyDetail]);

    useEffect(() => {
        const today = moment().format('MM/DD/YYYY');
        setIncidenceDate(today);
        dispatch(change('detailpolicy', 'incidenceDate', today));
    }, []);

    useEffect(() => {
        const hour = moment().format();
        setTime(hour);
        dispatch(
            change('detailpolicy', 'timeSinister', moment().format('HH:mm A'))
        );
    }, [incidenceDate]);

    useEffect(() => {
        if(policyData && policyData.length && policyData[0] && policyData[0].codigo_ramo){
            if(SHOW_TECHNICAL_INSURANCE_RISK_TARGET_IDS){
                const IDS = JSON.parse(SHOW_TECHNICAL_INSURANCE_RISK_TARGET_IDS);
                setShowTechnicalInsuranceType(IDS.includes(policyData[0].codigo_ramo));
                //setShowTechnicalInsuranceType(IDS.includes(riskSelected.codeBE));
            }
        }        
    }, [policyData]);

    const handleSubmits = () => {
        handleStep({
            step: 'general'
        });
    };

    const fetchItems = async (e) => {
        const id_pv = e.target.value;
        const policy = policyData.find((policy) => policy.id_pv === id_pv);
        await dispatch(
            getSinisterPolicyDetail(token, 0, policy.codigo_ramo, policy.nro_poliza, incidenceDate)
        );
        const newData = await dispatch(
            getSinisterPolicyItems(token, policy.codigo_ramo, policy.nro_poliza, `${moment(incidenceDate).format('YYYY-MM-DD')} ${moment(time).format('HH:mm')}`)
        );
        if (
            newData?.value?.data?.result?.result != null && 
            newData?.value?.data?.result?.result[0]?.id_pv != null &&
            newData?.value?.data?.result?.result.length > 0 
        ) {
            dispatch(change('detailpolicy', 'newPolicyIdPv', newData?.value?.data?.result?.result[0]?.id_pv));
            policy['newPolicyIdPv'] = newData?.value?.data?.result?.result[0]?.id_pv
        }
        setPolicy(policy);
        const start = moment(policy.fecha_inicio).format('MM/DD/YYYY');
        const end = moment(policy.fecha_fin).format('MM/DD/YYYY');
        setEndDate(end);
        setStartDate(start);
        dispatch(change('detailpolicy', 'startDate', start));
        dispatch(change('detailpolicy', 'endDate', end));
        // await executeItemsPolicy(id_pv);
        await executeClaimCauses(policy?.codigo_ramo || 0);
    };

    const handleItemSelected = async (e) => {
        const item = e.target.value;
        await setCoberturas(item?.coberturas ? item.coberturas.split(',') : []);
    };

    const validateData = (value) => {
        const incidentDate = moment(incidenceDate, 'MM/DD/YYYY').format("YYYY-MM-DD");
        const newStartDate = moment(value.startDate, 'MM/DD/YYYY').subtract(1, 'days').format("YYYY-MM-DD");
        const newEndDate = moment(value.endDate, 'MM/DD/YYYY').add(1, 'days').format("YYYY-MM-DD");
        if (
            moment(incidentDate).isAfter(newStartDate) && 
            moment(incidentDate).isBefore(newEndDate)
        ){
            handleSubmits()
        }else {
            dispatch(showError('La fecha de ocurrencia seleccionada debe estar comprendida entre las fechas de vigencia de la poliza seleccionada'))
        }
    }

    const handleTimeSinisterDateChange = async (dateTime) => {
        const hours = moment(dateTime).format('HH:mm');
        const dateToValidate = moment(incidenceDate).add(hours.split(':')[0], 'hours').add(hours.split(':')[1], 'minutes').format("YYYY-MM-DD HH:mm")
        if (!moment(dateToValidate).isAfter(moment())) {
            const hour = moment(dateTime).format();
            setTime(hour);
            dispatch(
                change('detailpolicy', 'timeSinister', moment(dateTime).format('HH:mm A'))
            );
        } else {
            dispatch(showError('La hora seleccionada debe ser menor a la hora actual'));
        }
    };

    const handleChangeCustomInput = async (value) => {
        dispatch(change('detailpolicy', 'items', value));
        await setCoberturas(value?.coberturas ? value.coberturas.split(',') : []);
    };

    const getItemMessage = (item) => {
        let message = `${item.descripcion_item || ''}`
        let arrPoliciesFiltered = []
        let policySelected = null
        if (sinisterPolicyDetail.length > 0) {
            arrPoliciesFiltered = sinisterPolicyDetail.filter(element => item.id_pv === element.IdPv )
            if (arrPoliciesFiltered.length > 0) {
                policySelected = arrPoliciesFiltered[0]
            }
        }
        if (policySelected !== null ) {
            message = policySelected.Cod_ramo === 4 || 
                policySelected.Cod_ramo === 24 ? `${item.descripcion_item} ${item.Patente_auto !== null && item.Patente_auto !== undefined  ? '\\' : ''} ${item.Patente_auto !== null && item.Patente_auto !== undefined ? item.Patente_auto : ''}` : 
                policySelected.Cod_ramo === 16 || 
                policySelected.Cod_ramo === 1 || 
                policySelected.Cod_ramo === 7 || 
                policySelected.Cod_ramo === 12 ? `${item.descripcion_item}` : item.Direccion_hogar !== undefined && item.Localidad_hogar !== undefined ? `${item.Direccion_hogar !== null && item.Direccion_hogar !== undefined ? item.Direccion_hogar : ''} ${ item.Localidad_hogar !== null && item.Localidad_hogar !== undefined ? '\\' : ''} 
                    ${item.Localidad_hogar !== null && item.Localidad_hogar !== undefined ? item.Localidad_hogar : ''}` : item.descripcion_item
        }
        return message
    }

    const formatOptionList = useMemo(()=>{
        if (itemSinister != null) {
            if (itemSinister) {
                if (itemSinister?.result && itemSinister?.result?.length > 0) {
                    return itemSinister.result.map(item => ({
                        'value':item,
                        'name': getItemMessage(item)
                    }));
                }
            }
        }
        return []
    },[itemSinister])

    const handleIncidentDate = (date) => {
        const isAfter = moment(date).isAfter(moment().format('MM/DD/YYYY'));
        if (!isAfter) {
            const formatDate = moment(date).format('MM/DD/YYYY')
            setIncidenceDate(formatDate)
            dispatch(change('detailpolicy', 'incidenceDate', formatDate));
        } else {
            const today = moment().format('MM/DD/YYYY');
            setIncidenceDate(today);
            dispatch(change('detailpolicy', 'incidenceDate', today));
            dispatch(showError('La fecha de ocurrencia no debe ser mayor a la fecha actual'))
        }
    }

    const onChangeTechnicalInsuranceType = async ( e ) => {
        const technicalInsuranceType = e.target.value;
        dispatch(change('detailpolicy', 'technicalInsuranceType', technicalInsuranceType));
    }

    return (
        <form onSubmit={handleSubmit(validateData)}>
            <Grid container direction="row">
                <Grid item xs={12} md={4} style={{ padding: 8 }}>
                    <Field
                        minDate={false}
                        maxDate={moment().format('MM/DD/YYYY')}
                        name="incidenceDate"
                        label="Fecha de ocurrencia"
                        value={incidenceDate}
                        dateValue={incidenceDate}
                        onChange={(date) => handleIncidentDate(date)}
                        component={renderDateField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                        customFormat = "DD/MM/YYYY"
                    />
                </Grid>
                <Grid item xs={12} md={4} style={{ padding: 8 }}>
                    <Field
                        name="timeSinister"
                        label="Hora de ocurrencia"
                        component={renderTimeField}
                        custom={{ tabIndex: 0 }}
                        value={time}
                        dateValue={time}
                        onChange={(date) => handleTimeSinisterDateChange(date)}
                        classes={classes.fontSizeLabel}
                    />
                </Grid>
                {showTechnicalInsuranceType && <Grid item xs={12} md={4} style={{ padding: 8 }}>
                    <Field
                        name="technicalInsuranceType"
                        label="Tipo de seguro técnico"
                        component={renderSelectField}
                        custom={{ tabIndex: 6 }}
                        onChange={onChangeTechnicalInsuranceType}
                    >
                        {technicalInsuranceType.map((item, index) => (
                                <MenuItem
                                    key={`${item.value}-${index}`}
                                    value={item.value}
                                >
                                    {`${item.value}`}
                                </MenuItem>
                            ))
                        }
                    </Field>
                </Grid>}
                <Grid item xs={12} md={4} style={{ padding: 8 }}>
                    <Field
                        name="tomador"
                        label="Nombre del tomador"
                        component={renderTextField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={8} style={{ padding: 8 }}>
                    <Field
                        name="policy"
                        label="Seleccionar Póliza"
                        component={renderSelectField}
                        custom={{ tabIndex: 6 }}
                        onChange={fetchItems}
                    >
                        {policyData &&
                            policyData.map((item) => (
                                <MenuItem key={item.id_pv} value={item.id_pv}>
                                    {`Póliza: ${item.nro_poliza} - ${item.descripcion_item}`}
                                </MenuItem>
                            ))}
                    </Field>
                </Grid>
                <Grid item xs={12} md={4} style={{ padding: 8 }}>
                    <Field
                        name="startDate"
                        label="Vigencia de póliza desde"
                        value={startDate}
                        dateValue={startDate}
                        component={renderDateField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4} style={{ padding: 8 }}>
                    <Field
                        name="endDate"
                        label="Vigencia de póliza hasta"
                        value={endDate}
                        dateValue={endDate}
                        component={renderDateField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={8} style={{ padding: 8, position:'relative'  }}>
                    <label className={classes.styledLabel}>Items a siniestrar</label>
                    <Field
                        name="items"
                        label="Item a siniestrar"
                        component={RenderSearchSelectFieldSimple}
                        nothingText='No se encontraron items vigentes para la fecha de ocurrencia.'
                        custom={{ tabIndex: 6 }}
                        classes={classes.fontSizeLabel}
                        options={formatOptionList}
                        handleChangeSelect={handleChangeCustomInput}
                    />
                </Grid>
                <Grid item xs={12} md={4} style={{ padding: 8 }}>
                    <Field
                        name="email"
                        label="Correo (Asegurado)"
                        component={renderTextField}
                        custom={{ tabIndex: 0 }}
                        classes={classes.fontSizeLabel}
                    /> 
                </Grid>

                <Grid item xs={12} md={12} style={{ padding: 0 }}>
                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            className={classes.buttonNextStep}
                        >
                            Siguiente
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}

DetailPolicyForm.propTypes = {};

export default reduxForm({ form: 'detailpolicy', validate })(DetailPolicyForm);
