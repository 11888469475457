import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, reduxForm, change, reset, touch } from 'redux-form';
import PropTypes from 'prop-types';
import {
    Button,
    Grid,
    IconButton,
    Drawer,
    TextField,
    MenuItem,
    Typography
} from '@material-ui/core';
import { renderTextField, renderSelectField, renderNumberField } from '../form/renderInputsForm';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { getDocumentsList } from '../../actions/listDocumentsAction.js';
//import { validate } from '../../utils/validateInputSinisterPolicy';
import InputList from '../InputList';
import { Add } from '@material-ui/icons'

const useStylesForm = makeStyles((theme) => ({
    sectFormComment: {
        display: 'flex',
        alignItems: 'baseline',
        padding: '15px !important'
    },
    fontSizeLabel: {
        '& label': {
            fontSize: 14
        }
    }
}));

const validate = (values) => { 
    console.log("validate values: ",values)
    const errors = {};
    const requireFields = [
        'clientName',
        'email',
        'phone',
        'location',
        'documentType',
        'document'
    ];

    requireFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });

    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'El correo es invalido'
        console.log('El correo debe ser valido ===> ', errors);
    } 

    if (values.document && !/^[0-9]*$/i.test(values.document)) {
        errors.document = 'El documento es invalido'
        console.log('El documento debe ser valido ===> ', errors);
    } 

    console.log("validate errors: ",requireFields)

    return errors;
};

function ThirdDataForm({ handleStep, policy, valid }) {
    const dispatch = useDispatch();
    const initialState = {
        clientName: '',
        email: '',
        phone: '',
        document: '',
        documentType: '',
        location: '',
        company: '',
        brand: '',
        model: '',
        patente: '',
        observations: ''
    };
    const [drawerState, setDrawerState] = useState(false);
    const classes = useStyles();
    const [saveThirdData, setSaveThirdData] = useState([]);
    const [formThirdData, setFormThirdData] = useState(initialState);
    const { listDocumentsType } = useSelector((state) => state);
    const [editMode, setEditMode] = useState(false);
    const [editIndex, setEditIndex] = useState(null);

    useEffect(() => {
        try {
            dispatch(getDocumentsList());
        } catch (error) {
            // console.log('Error al consultar el listado de DOcumentos => ', error);
        }
    }, []);

    const formatListDocument = useMemo(() => {
        return listDocumentsType.data?.map((x) => x);
    }, [listDocumentsType]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormThirdData({
            ...formThirdData,
            [name]: value
        });
    };

    const fieldThirdData = useMemo(() => {
        return [
            {
                validations: { pattern: '', message: '' },
                value: null,
                remote: null,
                dependency: [],
                isOptional: true,
                mask: 'none',
                name: 'clientName',
                label: 'Nombre',
                dataType: 'string',
                controlType: 'input text',
                order: 0
            },
            {
                validations: { pattern: '', message: '' },
                value: null,
                remote: null,
                dependency: [],
                isOptional: true,
                mask: 'none',
                name: 'clientLastName',
                label: 'Apellido',
                dataType: 'string',
                controlType: 'input text',
                order: 0
            },
            {
                validations: { pattern: '', message: '' },
                value: null,
                remote: null,
                dependency: [],
                isOptional: false,
                mask: 'none',
                name: 'email',
                label: 'Email',
                dataType: 'string',
                controlType: 'input text',
                order: 1
            },
            {
                validations: { pattern: '', message: '' },
                value: null,
                remote: null,
                dependency: [],
                isOptional: false,
                mask: 'none',
                name: 'phone',
                label: 'Telefono',
                dataType: 'string',
                controlType: 'input text',
                order: 2
            }
        ];
    });

    const loadThirdData = () => {
        setDrawerState(true);
    };

    const toggleDrawer = (open) => {
        setDrawerState(open);
    };

    const handleNext = () => {
        handleStep({
            step: 'thirdData'
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validation = Object.keys(validate(formThirdData));
        if (validation.length === 0) {
            if (!editMode) {
                setSaveThirdData([...saveThirdData, formThirdData]);
            } else {
                let newData = [...saveThirdData]
                newData[editIndex] = formThirdData
                setSaveThirdData(newData)
                setEditMode(false)
                setEditIndex(null)
            }
            dispatch(reset('thirdData'));
            setFormThirdData(initialState);
            toggleDrawer(false);
        } else {
            dispatch(
                touch('thirdData', 'clientName')
            )
            dispatch(
                touch('thirdData', 'documentType')
            )
            dispatch(
                touch('thirdData', 'document')
            )
            dispatch(
                touch('thirdData', 'clientLastName')
            )
            dispatch(
                touch('thirdData', 'email')
            )
            dispatch(
                touch('thirdData', 'phone')
            )
            dispatch(
                touch('thirdData', 'location')
            )
        }
    };

    useEffect(() => {
        dispatch(
            change('thirdData', 'list', saveThirdData)
        )
    }, [saveThirdData]);

    const customHandleEdit = (item, index) =>  {
        setEditIndex(index)
        for (const property in item) {
            dispatch(change('thirdData', property, item[property]));
        }
        setFormThirdData({...item, index: index})
        setEditMode(true)
        toggleDrawer(true)
    }

    return (
        <Grid container direction="row">
            <Grid item xs={12} style={{ padding: 8 }}>
                <InputList
                    items={saveThirdData}
                    fields={fieldThirdData}
                    label="Datos de Terceros"
                    setItems={setSaveThirdData}
                    add={false}
                    customHandleEdit={customHandleEdit}
                />
            </Grid>
            <Grid item xs={12} md={12} style={{ padding: 8 }}>
                {
                    saveThirdData.length < 5 ? (
                        <Button onClick={loadThirdData} className={classes.buttonAddThirdPerson}>
                            Agregar datos <Add/>
                        </Button>
                    ) : null
                }
            </Grid>
            <Drawer
                anchor="right"
                open={drawerState}
                onClose={() => toggleDrawer(false)}
                classes={{
                    paper: classes.drawerEl
                }}
            >
                <header className={classes.headerDrawer}>
                    <div className={classes.titleContainer}>
                        <span>Cargar Datos de Terceros</span>
                    </div>
                    <IconButton
                        aria-label="close details"
                        onClick={() => toggleDrawer(false)}
                    >
                        <Close style={{ color: '#fff' }} />
                    </IconButton>
                </header>
                <form name="thirdData" onSubmit={handleSubmit}>
                    <Grid container direction="row" style={{ paddingRight: 10, paddingLeft: 10 }} >
                        <Grid item xs={12} md={6} style={{ padding: 10 }}>
                            <Field
                                name="clientName"
                                label="Nombre"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.clientName}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: 10 }}>
                            <Field
                                name="clientLastName"
                                label="Apellido"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.clientLastName}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: 10 }}>
                            <Field
                                name="email"
                                label="Email"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.email}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: 10 }}>
                            <Field
                                name="phone"
                                label="Teléfono"
                                component={renderNumberField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.phone}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: 10 }}>
                            <Field
                                name="documentType"
                                label="Tipo de documento"
                                component={renderSelectField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.documentType}
                                onChange={handleInputChange}
                            >
                                {formatListDocument &&
                                    formatListDocument.map((doc, index) => (
                                        <MenuItem
                                            key={`type-document-${index}`}
                                            value={doc.IdentificationTypeID}
                                        >
                                            {doc.Description}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: 10 }}>
                            <Field
                                name="document"
                                label="Número de documento"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.document}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: 10 }}>
                            <Field
                                name="location"
                                label="Domicilio"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.location}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: 10 }}>
                            <Field
                                name="company"
                                label="Compañia de seguros"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.company}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        {
                            policy?.codigo_ramo == 4 || policy?.codigo_ramo == 24 ?
                            <>
                            </> : null
                        }
                        <Grid item xs={12} md={6} style={{ padding: 10 }}>
                            <Field
                                name="brand"
                                label="Marca"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.brand}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: 10 }}>
                            <Field
                                name="model"
                                label="Modelo"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.model}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: 10 }}>
                            <Field
                                name="patente"
                                label="Patente"
                                component={renderTextField}
                                custom={{ tabIndex: 0 }}
                                classes={classes.fontSizeLabel}
                                value={formThirdData.patente}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item sx={12} style={{ padding: 10, width: '100%' }}>
                            <Field 
                                name="observations" 
                                label="Observaciones" 
                                component={renderTextField}
                                custom={{ tabIndex: 6 }}
                                rows={4}
                                multiline
                                fullWidth
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div className={classes.buttonContainer}>
                                <Button
                                    type="submit"
                                    className={classes.buttonNextStep}
                                    // disabled={!valid}
                                >
                                    Guardar
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </Drawer>

            <Grid item xs={12} md={12} style={{ padding: 0 }}>
                <div className={classes.buttonContainer}>
                    <Button
                        onClick={handleNext}
                        className={classes.buttonNextStep}
                    >
                        Siguiente
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

ThirdDataForm.propTypes = {};

export default reduxForm({ form: 'thirdData', validate })(ThirdDataForm);
