import React, { useEffect, useState } from 'react';
import { globalHistory } from '@reach/router';

const SectionTitle = () => {
    const [title, setTitle] = useState('');

    const getTitleByRoute = (route) => {
        switch (route) {
            case 'dashboard':
                return 'Inicio';
            case 'agent':
                return 'Cartera';
            case 'medical-network':
                return 'Red Médica';
            case 'notifications':
                return 'Notificaciones';
            case 'balance-inquiry':
                return 'Saldo';
            case 'request':
                return 'Solicitudes';
            /* case 'faq':
                return 'Ayuda'; */
            case 'news':
                return 'Noticias';
            default:
                return 'Iconnectance';
        }
    };

    useEffect(() => {
        setTitle(getTitleByRoute(window.location.pathname.split('/')[1]));
        return globalHistory.listen((route) => {
            setTitle(getTitleByRoute(route.location.pathname.split('/')[1]));
        });
    }, []);

    return <span>{title}</span>;
};

export default SectionTitle;
