import { createAsyncAction } from "redux-promise-middleware-actions";
import axios from "axios";
import {
    quotationUrlApi,
    exceptionCodeResponse,
} from "../utils/urls";
import { POST_UPLOAD_FILE_MANAGEMENT, POST_CHANGE_TEMPORALY_FILE_MANAGEMENT, TEMPORARY_NUMBER } from "../actions/types";

export const fileManagementUploadAPI = createAsyncAction(
    POST_UPLOAD_FILE_MANAGEMENT,
    async (token, bodyReq, url, dispatch) => {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const formData = new FormData();
      for (let clave in bodyReq) {
        formData.append(clave, bodyReq[clave]);
      }

      const config = {
        headers,
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      };

      const res = await axios
        .post(`${quotationUrlApi}${url}`, formData, config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw exceptionCodeResponse(error);
        });

      return res;
  }
);

export const generateTemporaryIdentityNumberAPI = createAsyncAction(
  TEMPORARY_NUMBER,
  async (dispatch) => {
    try {
      const res = await axios.get(`${quotationUrlApi}documentManagement/generateTemporaryIdentityNumber`);
      return res.data.identifierNumber;
    } catch (error) {
      throw exceptionCodeResponse(error);
    }
  }
);

export const fileManagementChangeTemporalyToCompleteAPI = createAsyncAction(
  POST_CHANGE_TEMPORALY_FILE_MANAGEMENT,
  async (token, data, url, dispatch) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const res = await axios.post(`${quotationUrlApi}${url}`, data, {headers})
      return res
    } catch (error) {
      throw exceptionCodeResponse(error);
    }
}
);