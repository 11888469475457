import React from "react";
import { Grid } from "@material-ui/core";
import { Location } from "@reach/router";

const Container = ({ children }) => {
  return (
    <Location>
      {() => {
        return (
          <Grid container className={"appContainer"}>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        );
      }}
    </Location>
  );
};

export default Container;
