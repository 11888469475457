import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, IconButton, Drawer, Typography } from "@material-ui/core";
import { SettingsOutlined, Close } from "@material-ui/icons";
import NotificationsEditForm from "../../notificationsEditForm";
import { updateUserEvents } from "../../../../actions/userEventsActions";
import { setUserEventListeners } from "../../../../sevices/notificationService";
import { useStyles } from "./styles";
import Logo from "../../../../assets/images/iConnectance_mini_logo_2.png";

const DrawerNotifications = ({ openNotiConfig, handleOpenClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector((state) => state.auth.token);
  const userEvent = useSelector((state) => state.userEvents);
  const config = useSelector((state) => state.userConfig.data);

  const handleUpdateNotificationConfig = async (updatedEvents) => {
    await dispatch(updateUserEvents(token, updatedEvents));
    setUserEventListeners(
      userEvent.io,
      updatedEvents,
      token,
      dispatch,
      config.logoImage ? config.logoImage : Logo
    );
    handleOpenClose(false);
  };

  return (
    <Drawer
      ModalProps={{
        onClose: true,
        hideBackdrop: true,
      }}
      anchor="right"
      open={openNotiConfig}
      classes={{
        paper: classes.configMenu,
      }}
      onClose={() => {
        handleOpenClose(false);
      }}
    >
      <Grid
        justifyContent="center"
        alignItems="center"
        container
        className={classes.drawerPaper}
        justifyContent="space-between"
      >
        <IconButton
          style={{ alignSelf: "flex-end" }}
          aria-label="close config menu"
          onClick={() => {
            handleOpenClose(false);
          }}
        >
          <Close />
        </IconButton>
        <Grid item container justifyContent="center" alignItems="center">
          <Typography component="h3">
            Configuracion de notificaciones
          </Typography>
          <SettingsOutlined
            style={{ color: "#000", marginLeft: 20 }}
            fontSize="large"
          />
        </Grid>
      </Grid>
      <Grid container>
        <NotificationsEditForm
          events={userEvent.data}
          handleUpdate={handleUpdateNotificationConfig}
        />
      </Grid>
    </Drawer>
  );
};

export default DrawerNotifications;
