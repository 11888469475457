import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Field, change } from 'redux-form';
import { renderSelectField } from '../form/renderInputsForm';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, MenuItem, InputAdornment, IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import { renderTextField, RenderSearchSelectFieldSimple } from '../quoter/quoteInputs';
import { Search } from '@material-ui/icons';
import GenericModal from '../modal/genericModal';
import SearchProvinceForm from './SearchProvinceForm';
import { useStyles } from '../quoter/styles';
import { getStateList, getPostalCode } from '../../actions/quotationAgentActions';

const CAPITAL_FEDERAL = 'CAPITAL FEDERAL';

export default function SearchProvinceComponent({
    colorPrimary,
    formName,
    defaultvalues = {
        province: '1',
    },
    defaultLocation,
}) {
    const { stateList, postalCode, auth } = useSelector((state) => state);
    const [disabledLocation, setDisabledLocation] = useState(false);
    const [disabledStreetNumber, setDisabledStreetNumber] = useState(false);
    const [modalSearchPostCode, setModalSearchPostCode] = useState(false);
    const [timerGetPovincia, setTimerGetProvincia] = useState();
    const [locationValue, setLocationValue] = useState(defaultLocation || '');
    const [errorSearch, setErrorSearch] = useState('');
    const [provinceSelected, setProvinceSelected] = useState();
    const refStreet = useRef(null);
    const refNumber = useRef(null);
    const refPostalCode = useRef(null);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        dispatch(change(formName, 'country', defaultvalues['cuntryCode'] || '1'));
        dispatch(getStateList(auth.token));
    }, []);

    useEffect(() => {
        if (postalCode.data?.postalCode?.result) {
            if (postalCode.data?.postalCode?.result[0]?.nro_altura_desde === null) {
                setDisabledLocation(false);
            } else {
                dispatch(change(formName, 'location', CAPITAL_FEDERAL));
                setLocationValue(CAPITAL_FEDERAL);
                dispatch(change(formName, 'municipio', 1));
                setDisabledLocation(true);
            }
        }
    }, [postalCode]);

    useEffect(() => {
        if (stateList.data?.stateList?.result.length > 0 && defaultvalues['province']) {
            dispatch(change(formName, 'province', defaultvalues['province']));
            setProvinceSelected(defaultvalues['province']);
            if (defaultvalues['province'] === '1') {
                dispatch(change(formName, 'location', CAPITAL_FEDERAL));
                setLocationValue(CAPITAL_FEDERAL);
                dispatch(change(formName, 'municipio', 1));
                setDisabledLocation(true);
                return;
            }
            dispatch(getPostalCode(auth.token, `?codigo_provincia=${defaultvalues['province']}`));
        }
    }, [stateList.data?.stateList?.result]);

    const streetLocations = useMemo(() => {
        return postalCode.data?.postalCode?.result?.map((location, i) => ({
            CPLocation: `${location['codigo_postal']}`,
            street: `${location['calle']}.`,
            nro_altura_desde: location['nro_altura_desde'],
            nro_altura_hasta: location['nro_altura_hasta'],
        }));
    }, [postalCode]);

    useEffect(() => {
        if (postalCode.loading === false && provinceSelected === '1' && streetLocations.length > 0) {
            if (streetLocations.length === 1) {
                dispatch(change(formName, 'postalCode', streetLocations[0].CPLocation));
                dispatch(change(formName, 'street', streetLocations[0].street));
                setErrorSearch('');
                return;
            }
            setModalSearchPostCode(true);
            setErrorSearch('');
            return;
        }

        if (refStreet.current.value && !refPostalCode.current?.value) {
            dispatch(change(formName, 'postalCode', ''));
            setErrorSearch('No se consiguieron coincidencias');
            return;
        }
        setErrorSearch('');
    }, [postalCode.loading, streetLocations, refPostalCode]);

    const provincias = useMemo(() => {
        return stateList.data?.stateList?.result?.map((province, i) => ({
            codigo_estado: `${province['codigo_estado']}`,
            name: `${province['descripcion']}.`,
        }));
    }, [stateList]);

    const locations = useMemo(() => {
        return postalCode.data?.postalCode?.result?.map((location, i) => ({
            value: location['calle'],
            postalCode: `${location['codigo_postal']}`,
            name: `${location['calle']} (${location['codigo_postal']}).`,
            municipio: location['cod_municipio'],
        }));
    }, [postalCode]);

    const locationCABA = [
        {
            value: CAPITAL_FEDERAL,
            postalCode: '',
            name: CAPITAL_FEDERAL,
        },
    ];

    const countries = [
        {
            cuntryCode: '1',
            name: 'Argentina',
        },
    ];

    const handleChangeSelectProvince = (provinceValue) => {
        setProvinceSelected(provinceValue);
        setErrorSearch('');
        dispatch(change(formName, 'location', ''));
        dispatch(change(formName, 'street', ''));
        dispatch(change(formName, 'number', ''));
        dispatch(change(formName, 'postalCode', ''));
        if (provinceValue === '1') {
            dispatch(change(formName, 'location', CAPITAL_FEDERAL));
            setLocationValue(CAPITAL_FEDERAL);
            dispatch(change(formName, 'municipio', 1));
            setDisabledLocation(true);
            return;
        }
        dispatch(getPostalCode(auth.token, `?codigo_provincia=${provinceValue}`));
    };

    const handleChangeSelectLocation = (location) => {
        if (location) {
            const selectedLocation = locations.find((reg) => reg.value === location);
            dispatch(change(formName, 'street', ''));
            //dispatch(change(formName, 'number', ''));
            dispatch(change(formName, 'location', location));
            dispatch(change(formName, 'postalCode', selectedLocation.postalCode));
            dispatch(change(formName, 'municipio', selectedLocation.municipio));
            setErrorSearch('');
            setDisabledStreetNumber(true);
        }
    };

    const handleChangeNumber = (number) => {
        const streetValue = refStreet.current.value;
        if (!streetValue && provinceSelected === '1') {
            setErrorSearch('Campo calle no puede estar vacío');
            return;
        }
        setErrorSearch('');
        clearTimeout(timerGetPovincia);
        if (number.length >= 1 && provinceSelected === '1') {
            setTimerGetProvincia(
                setTimeout(async () => {
                    dispatch(getPostalCode(auth.token, `?codigo_provincia=${provinceSelected}&calle=${streetValue}&nro=${number}`));
                }, 500)
            );
        }
    };

    const handleSearchPostalCode = async () => {
        if (disabledLocation) {
            const streetName = refStreet.current?.value?.toLocaleLowerCase() || '';
            if (!streetName) {
                setErrorSearch('Campo calle no puede estar vacío');
                return;
            }
            setErrorSearch('');
            await dispatch(getPostalCode(auth.token, `?codigo_provincia=${provinceSelected}&calle=${streetName}`));
        } else {
            console.log('solo se valida');
            await dispatch(getPostalCode(auth.token, `?codigo_provincia=${provinceSelected}&calle=`));
        }
    };

    const handleSetPostalCode = (street) => {
        dispatch(change(formName, 'postalCode', street.CPLocation));
        dispatch(change(formName, 'street', street.street));
        //dispatch(change(formName, 'number', ''));
        setErrorSearch('');
        setModalSearchPostCode(false);
    };

    const handelChangePostalCode = (e) => {
        if (e.length >= 3 && provinceSelected != '1') {
            const findedStreetOrLocation = streetLocations.find((streetLocation) => streetLocation['CPLocation'] === e);
            if (findedStreetOrLocation && findedStreetOrLocation.nro_altura_desde) {
                dispatch(change(formName, 'street', findedStreetOrLocation.street));
            } else {
                if (!findedStreetOrLocation) {
                    dispatch(change(formName, 'location', ''));
                    setLocationValue('');
                    setErrorSearch('El código postal ingresado no se encuentra en esta provincia.');
                } else {
                    dispatch(change(formName, 'location', findedStreetOrLocation.street));
                    setLocationValue(findedStreetOrLocation.street);
                    setErrorSearch('');
                }
            }
        }
    };

    return (
        <>
            <Grid item xs={12} md={4} style={{ padding: 8 }}>
                <Field name='country' label='País' component={renderSelectField} custom={{ tabIndex: 6 }}>
                    {countries.map((reg, index) => (
                        <MenuItem key={index} value={reg['cuntryCode']}>
                            {reg['name']}
                        </MenuItem>
                    ))}
                </Field>
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: 8 }}>
                <Field
                    name='province'
                    label='Provincia'
                    component={renderSelectField}
                    custom={{ tabIndex: 6 }}
                    onChange={(e) => {
                        handleChangeSelectProvince(e.target.value);
                    }}
                >
                    {provincias &&
                        provincias.map((reg, index) => (
                            <MenuItem key={index} value={reg['codigo_estado']}>
                                {reg['name']}
                            </MenuItem>
                        ))}
                </Field>
            </Grid>

            <Grid item xs={12} md={4} style={{ padding: 8, position: 'relative' }}>
                <label className={classes.styledLabel}>Localidad</label>
                {postalCode.loading ? (
                    <div className='loading-select-search' style={{ padding: 4 }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <Field
                        name='location'
                        label='Localidad'
                        component={RenderSearchSelectFieldSimple}
                        custom={{ tabIndex: 6 }}
                        classes={classes.fontSizeLabel}
                        options={provinceSelected !== '1' ? locations : locationCABA}
                        limitedOptions={20}
                        defaultValue={locationValue}
                        disabled={disabledLocation}
                        handleChangeSelect={(e) => {
                            handleChangeSelectLocation(e);
                        }}
                    />
                )}
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: 8 }}>
                <Field ref={refStreet} name='street' label='Calle' component={renderTextField} custom={{ tabIndex: 6 }} disabled={disabledStreetNumber} />
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: 8 }}>
                <Field
                    ref={refNumber}
                    name='number'
                    label='Número'
                    component={renderTextField}
                    onChange={(e) => {
                        handleChangeNumber(e.target.value);
                    }}
                    custom={{ tabIndex: 6 }}
                    disabled={disabledStreetNumber}
                />
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: 8 }}>
                {postalCode.loading ? (
                    <div className='loading-select-search' style={{ padding: 4 }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <Field
                            ref={refPostalCode}
                            name='postalCode'
                            label='Código Postal'
                            component={renderTextField}
                            custom={{ tabIndex: 6 }}
                            onChange={(e) => {
                                handelChangePostalCode(e.target.value);
                            }}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <Tooltip title='Buscar por calle' arrow placement='top'>
                                        <IconButton
                                            aria-label='check input value'
                                            style={{
                                                color: colorPrimary,
                                            }}
                                            disabled={!disabledLocation}
                                            onClick={handleSearchPostalCode}
                                        >
                                            <Search />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            }
                        />
                        <span style={{ color: 'red' }}>{errorSearch}</span>
                    </>
                )}
            </Grid>
            <GenericModal
                open={modalSearchPostCode}
                handleCancel={() => {
                    setModalSearchPostCode(false);
                }}
                loading={false}
                title='Código Postal'
            >
                <SearchProvinceForm
                    handleCancel={() => {
                        setModalSearchPostCode(false);
                    }}
                    handleSubmitItem={handleSetPostalCode}
                    streets={streetLocations}
                />
            </GenericModal>
        </>
    );
}
