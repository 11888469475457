import React, { useState } from 'react';
import {
    Grid,
    Box,
    Button,
    Typography,
    Menu,
    MenuItem
} from '@material-ui/core';
import { useStyles } from './styles';

const ButtonAction = ({
    icon,
    title,
    onClick,
    isMenu = false,
    ariaControls = '',
    listMenu = []
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid>
            <Button
                className={classes.button}
                onClick={isMenu ? handleClick : onClick}
                aria-controls={ariaControls}
                aria-haspopup={isMenu}
            >
                <Box component="span" className={classes.icon}>
                    {icon}
                </Box>
                <Typography className={classes.title}>{title}</Typography>
            </Button>
            {isMenu && (
                <Menu
                    id={ariaControls}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {listMenu.map((item, index) => (
                        <MenuItem key={index} onClick={handleClose}>
                            {item?.name.toUpperCase()}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </Grid>
    );
};

export default ButtonAction;
