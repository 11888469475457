import { useContext, useMemo } from 'react';
import { useSelector } from "react-redux";
import jwt from 'jsonwebtoken';

import { AbilityContext } from '../authorization/abilities';

function useIsEmployee() {
    const token = useSelector((state) => state.auth.token);
    const decode = jwt.decode(token);
    const isEmployee = useMemo(() => token && decode.user.permissions.Agent?.EmployeeConfiguration.includes('read') ? true : false, [decode, token]);

    const ability = useContext(AbilityContext);

    return ability ? ability.can("read", "EmployeeConfiguration") : isEmployee;
}

export default useIsEmployee;