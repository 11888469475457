import {
  GET_ORANGE_POLICIES_PENDING,
  GET_ORANGE_POLICIES_FULFILLED,
  GET_ORANGE_POLICIES_REJECTED,
  GET_ORANGE_TRIPS_PENDING,
  GET_ORANGE_TRIPS_FULFILLED,
  GET_ORANGE_TRIPS_REJECTED,
  UPDATE_ORANGE_TRIPS_PENDING,
  UPDATE_ORANGE_TRIPS_FULFILLED,
  UPDATE_ORANGE_TRIPS_REJECTED,
} from "../actions/types";

const initialState = {
  trips: [],
  loading: false,
  error: false,
  refetching: false,
  data: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ORANGE_POLICIES_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_ORANGE_POLICIES_FULFILLED:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_ORANGE_POLICIES_REJECTED:
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
      };
    case GET_ORANGE_TRIPS_PENDING:
    case UPDATE_ORANGE_TRIPS_PENDING:
      return {
        ...state,
        refetching: true,
        error: false,
      };
    case GET_ORANGE_TRIPS_FULFILLED:
    case UPDATE_ORANGE_TRIPS_FULFILLED:
      return {
        ...state,
        trips: action.payload,
        refetching: false,
        error: false,
      };
    case GET_ORANGE_TRIPS_REJECTED:
      return {
        ...state,
        trips: [],
        refetching: false,
        error: true,
      };
    case UPDATE_ORANGE_TRIPS_REJECTED:
      return {
        ...state,
        refetching: false,
        error: true,
      };
    default:
      return state;
  }
}
