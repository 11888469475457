import jwt_decode from "jwt-decode";

export const getParams = function (url) {
  var params = {};
  var parser = document.createElement("a");
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }

  return params;
};

export const saveToken = (token = null) => {
  if (token) localStorage.setItem("iC:insured", token);
};

export const getDataToken = (token = null) => {
  if (token) {
    const tokenDecode = jwt_decode(token);
    return tokenDecode;
  } else {
    const token = localStorage.getItem("iC:insured");
    const tokenDecode = jwt_decode(token);

    return tokenDecode;
  }
};

export const getToken = () => {
  const token = localStorage.getItem("iC:insured");

  return token;
};

export const headers = () => {
  const token = localStorage.getItem("iC:insured");

  const headers = { Authorization: `Bearer ${token}` };

  return { headers };
};

export const b64toBlob = (
  b64Data,
  fileName,
  contentType = "",
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  return blob;
};

export const truncateText = (text, defaultLength = 45) => {
  if (text && text.length > defaultLength) {
    text = `${text.substring(0, defaultLength)}...`;
  }
  return text;
};

export const saveDataCardInLocalStorage = (name, data) => {
  localStorage.setItem(name, JSON.stringify(data));
};

export const jsonToHtml = (json, dicctionary) => {
  let html = "<div>";
  html += "<ul>";

  const processObject = (obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (typeof value === "object") {
        html += `<li><b>${dicctionary[key] || key}:</b></li>`;
        html += "<ul>";
        processObject(value);
        html += "</ul>";
      } else {
        html += `<li><b>${dicctionary[key] || key}:</b> ${value}</li>`;
      }
    });
  };

  processObject(json);
  html += "</ul>";
  html += "</div>";

  return html;
};
