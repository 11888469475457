import { createAsyncAction } from 'redux-promise-middleware-actions';
import { urlBase, urlList } from '../utils/urls';
import { getToken } from "../utils/index";

export const getDocumentsListAPI = createAsyncAction(
    'GET_DOCUMENTS_LIST',
    async () => {
        const token = getToken();
        const res = await fetch(`${urlList}listsExternal/ep/IdentificationTypes?`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

const validateResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        throw exeptionCodeResponse();
    }
};

const exeptionCodeResponse = (error = 'error') => {
    return error;
};
