import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  img: {
    marginBottom: 10,
    width: 'auto',
    maxHeight: 91
  },
  ribbon: {
    backgroundColor: theme.palette.error.light,
    position: "absolute",
    color: "white",
    width: 150,
    zIndex: 3,
    textAlign: "center",
    textTransform: "uppercase",
    padding: 5,
    font: "Lato",
    "&::before": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    "&::after": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    transform: "rotate(-45deg)",
    top: 25,
    left: "-5px",
    marginLeft: -40,
  },
  bcgCard: {
    margin: 5,
    overflow: "hidden",
    backgroundColor: "white",
    borderRadius: 15,
    padding: 20,
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  title: {
    fontSize: 22,
    textAlign: "center",
    color: theme.palette.typography && theme.palette.typography.variant1,
  },
  sectData: {
    marginBottom: 50,
  },
  detail: {
    fontSize: 14,
    color: theme.palette.typography && theme.palette.typography.main,
    paddingTop: 5,
    textAlign: "center",
  },
  sectBtn: {
    // paddingTop: 15,
    position: "absolute",
    left: 0,
    bottom: 20,
    "& button": {
      color: theme.palette.primary && theme.palette.primary.main,
    },
  },
  BtnPaymentLink:{
    border: `1px solid ${theme.palette.primary.main} !important`,
    color: theme.palette.primary && theme.palette.primary.main,
    width: '380px',
  },
  closeBtn: {
    position: 'absolute',
    top: 14,
    right: 10
},
}));
