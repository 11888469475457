import {
  GET_FILES_SINISTER_PENDING,
  GET_FILES_SINISTER_FULFILLED,
  GET_FILES_SINISTER_REJECTED,
} from "../actions/types";

const initialState = {
    data: [],
    loading: false,
    error: false,
  };
  
  export const filesSinisterReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_FILES_SINISTER_PENDING:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case GET_FILES_SINISTER_FULFILLED:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case GET_FILES_SINISTER_REJECTED:
        return {
          ...state,
          data: [],
          loading: false,
          error: true,
        };
      default:
        return state;
    }
  };