import React, { useEffect, useState } from 'react';
import { reduxForm, change, reset, Field} from 'redux-form';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { renderTextField } from '../../form/renderInputsForm';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from '../styles';
import { getSinisterCollisionTypes } from '../../../actions/sinisterActions';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import ViolenceForm from './ViolenceForm';
import WithoutviolenceForm from './WithoutviolenceForm';

const violenceRequiredFields = [
    "withViolence",
    "callEmergency_V",
    "documentation_V",
    "witnesses_V",
    "cameras_V",
    "carUse_V",
    "occupation_V",
    "name_V",
    "typeNumberDocument_V",
    "homeAddress_V",
    "workAddress_V",
    "mail_V",
    "phoneNumber_V",
    "delayReasons_V",
    "insuredOtherCompanies_V",
    "kilometres_V"
  ];
  
  const witoutViolenceRequiredFields = [
    "withViolence",
    "dateLeftVehicle",
    "dateLookVehicle",
    "placeAfterLeavingVehicle",
    "callEmergency",
    "inquiriesInArea",
    "dateSatelliteRecovery",
    "recoveryMechanism",
    "recoveryMechanismActivation",
    "parkedPublicRoad",
    "alarm",
    "consultedTrafficControlCompany",
    "securityMeasures",
    "cameras",
    "keys",
    "witness",
    "carUse",
    "documentation",
    "carStore",
    "occupation",
    "name",
    "typeNumberDocument",
    "homeAddress",
    "workAddress",
    "mail",
    "phoneNumber",
    "delayReasons",
    "insuredOtherCompanies",
    "kilometres"
  ];

const validate = (values) => {
    const errors = {};

    let requiredFields = ["withViolence"];
                                                                          
    if(values && Object.keys(values).length > 0 && values.hasOwnProperty('withViolence')){
        if(values.withViolence == "Si") {
            requiredFields = violenceRequiredFields;
        }

        if(values.withViolence == "No") {
            requiredFields = witoutViolenceRequiredFields;
        }
    }

    if (values.mail_V && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.mail_V)) {
        errors.mail_V = 'El correo es invalido'
        console.log('1-El correo debe ser valido ===> ', errors);
    } 

    if (values.mail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.mail)) {
        errors.mail = 'El correo es invalido'
        console.log('2-El correo debe ser valido ===> ', errors);
    } 
    
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });

    return errors;
};
 
const useStylesForm = makeStyles((theme) => ({
    sectFormComment: {
        display: 'flex',
        alignItems: 'baseline',
        padding: '15px !important'
    },
    fontSizeLabel: {
        '& label': {
            fontSize: 14
        }
    },
    radioGroup:{
        marginTop: "5%",
        marginBottom: "5%"
    },
    title:{
        marginTop: "5%"
    },
    formControlLabel: {
        [theme.breakpoints.down('sm')]: {
          margin: '5% 0',
        },
      },
}));

function StealingForm({ handleSubmit, handleStep }) {
    const dispatch = useDispatch();
    const [registerDate, setRegisterDate] = useState();
    const classesForm = useStylesForm();
    const classes = useStyles();
    const { claimCauses } = useSelector((state) => state);
    const [causes, setCauses] = useState([]);
    const [coverages, setCoverages] = useState([]); 
    const { auth } = useSelector((state) => state);
    const { token } = auth;
    const policyCoverage = useSelector((state) => state.sinister.policyCoverage);  
    const { sinister: {collisionTypes} } = useSelector((state) => state);
    const [stealingForm, setStealingForm] = useState(null);
    
    const handleRadioChange = async (event) => { 
        dispatch(reset("stealingForm"));

        const withViolence = event.target.value === 'withViolence';
        dispatch(change('stealingForm', 'withViolence', withViolence ? "Si" : "No"));
        
        setStealingForm(withViolence);
    };


    useEffect(() => {
        const firstRun = async() => {
            await dispatch(getSinisterCollisionTypes(token));
        }

        firstRun()
    }, []);

    useEffect(() => {
        setCoverages(policyCoverage?.data?.result?.result)
    }, [policyCoverage]);

    useEffect(() => {
        const today = moment().format('MM/DD/YYYY');
        setRegisterDate(today);
        dispatch(change('detailgeneral', 'registerDate', today));
    }, []);

    useEffect(() => {
        setCauses(claimCauses?.data?.DatosAdicionales || []);
    }, [claimCauses?.data?.DatosAdicionales]);

    const handleSubmits = (value) => {
        handleStep({
            step: 'policy'
        });
    };
    
    return (
        <form onSubmit={handleSubmit(handleSubmits)}>
            <Grid container direction="row">
                <Grid container direction="row" style={{marginBottom:"1%"}}>
                    <RadioGroup
                        aria-label="stealingForm"
                        name="stealingForm"
                        value={stealingForm == null ? "" :  stealingForm ? 'withViolence' : 'withoutViolence'}
                        onChange={handleRadioChange}
                        style={{marginBottom:"4% important!"}}
                    >
                        <FormControlLabel
                            value="withoutViolence"
                            control={<Radio />}
                            className={classes.formControlLabel}
                            label="No hubo empleo de violencia, amenaza o fuerza."
                        />
                        <FormControlLabel
                            value="withViolence"
                            control={<Radio />}
                            className={classes.formControlLabel}
                            label="Hubo empleo de violencia, amenaza y/o fuerza."
                        />
                    </RadioGroup>
                    <Grid style={{ display: "none"}}>
                        <Field 
                            name="withViolence" 
                            label="withViolence" 
                            component={renderTextField}
                            custom={{ tabIndex: 0 }}
                            rows={4}
                            multiline
                        />
                    </Grid>
                </Grid>
                {
                    stealingForm == null 
                    ? ""
                    : stealingForm
                        ? <ViolenceForm />
                        : <WithoutviolenceForm />
                }
                <Grid item xs={12} md={12} style={{ padding: 0 }}>
                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            className={classes.buttonNextStep}
                        >
                            Siguiente
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}


StealingForm.propTypes = {};

export default reduxForm({ form: 'stealingForm', validate })(
    StealingForm
);
