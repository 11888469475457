import socketIOClient from "socket.io-client";
import {
    GET_EVENTS_BY_USER_ID_PENDING,
    GET_EVENTS_BY_USER_ID_FULFILLED,
    GET_EVENTS_BY_USER_ID_REJECTED,
    GET_NOTIFICATIONS_BY_USER_ID_PENDING,
    GET_NOTIFICATIONS_BY_USER_ID_FULFILLED,
    GET_NOTIFICATIONS_BY_USER_ID_REJECTED,
    GET_NEW_NOTIFICATION_PENDING,
    GET_NEW_NOTIFICATION_FULFILLED,
    GET_NEW_NOTIFICATION_REJECTED,
    READ_NOTIFICATION_PENDING,
    READ_NOTIFICATION_FULFILLED,
    READ_NOTIFICATION_REJECTED,
    UPDATE_USER_EVENTS_PENDING,
    UPDATE_USER_EVENTS_FULFILLED,
    UPDATE_USER_EVENTS_REJECTED
} from "../actions/types";
//import { initUserEventListeners } from "../sevices/notificationService";
import { eventsUrlBase } from "../utils/urls";

const initialState = {
    io: socketIOClient(eventsUrlBase),
    data: [],
    loading: false,
    error: false,
    notifications: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_EVENTS_BY_USER_ID_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_EVENTS_BY_USER_ID_FULFILLED:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };
        case GET_EVENTS_BY_USER_ID_REJECTED:
            return {
                ...state,
                data: [],
                loading: false,
                error: true,
            };
        case GET_NEW_NOTIFICATION_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_NEW_NOTIFICATION_FULFILLED:
            return {
                ...state,
                notifications: [...state.notifications, action.payload[0]],
                loading: false,
                error: false,
            };
        case GET_NEW_NOTIFICATION_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case GET_NOTIFICATIONS_BY_USER_ID_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_NOTIFICATIONS_BY_USER_ID_FULFILLED:
            return {
                ...state,
                notifications: action.payload,
                loading: false,
                error: false,
            };
        case GET_NOTIFICATIONS_BY_USER_ID_REJECTED:
            return {
                ...state,
                notifications: [],
                loading: false,
                error: true,
            };
        case READ_NOTIFICATION_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case READ_NOTIFICATION_FULFILLED: {
            const newData = state.notifications.map((noti) => {
                if (noti._id === action.payload._id) {
                    return action.payload;
                }

                return noti;
            });

            return {
                ...state,
                notifications: newData,
                loading: false,
                error: false,
            };
        }
        case READ_NOTIFICATION_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case UPDATE_USER_EVENTS_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case UPDATE_USER_EVENTS_FULFILLED: {
            //initUserEventListeners(state.io, action.payload);
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };
        }
        case UPDATE_USER_EVENTS_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
}
