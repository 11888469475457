import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  Backdrop,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Hidden, 
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import {
  urlBaseApi
} from '../../utils/urls';
import ModalMessage from '../../components/modal/Modal/index'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: '80vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    marginLeft: '2%',
    marginRight: '2%',
  },
  tableCell: {
    fontWeight: 'bold',
  },
  tableAccordion: {
    marginTop: theme.spacing(2),
  },
  BtnPaymentLink:{
    border: `1px solid ${theme.palette.primary.main} !important`,
    color: theme.palette.primary && theme.palette.primary.main,
  },
  aPaymentLink:{
    color: theme.palette.primary && theme.palette.primary.main,
  },
  table: {
    width: '100%',
    '& td': {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      '& td': {
        fontSize: '1.2rem',
      },
    },
  },
  tableHead: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  tableHeadMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'table-header-group',
    },
  },
  tableRow: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      borderBottom: 'none',
    },
  },
  tableCellMobile: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
      padding: theme.spacing(1, 0),
    },
  },
  detailsBtn:{
    color: theme.palette.primary && theme.palette.primary.main,
  },
  header: {
    marginLeft: 0,
    padding: 25,
    overflowX: 'hidden',
    background: theme.palette.primary.main,
    color: '#fff'
  },
  alignOnline: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
  },
  pointer: {
      cursor: 'pointer'
  },
}));

const finalStates = ["pagado", "expirado","desbloqueado", "pagado en sise"]

const convertTolocalUTC = dateUTC => moment.utc(dateUTC).local().format("DD/MM/YYYY, h:mm a");

const PaymentLinkModal = ({ open, onClose, payments }) => {
  const classes = useStyles();
  const token = useSelector((state) => state.auth.token);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMSG, setErrorMSG] = useState(undefined);

  const handlePayment = async (payment) => {
    if(!payment || !payment.external_reference){
      setErrorMSG("No se puede realizar este pago.")
    }
    setIsDisabled(true)

    const headers = { Authorization: `Bearer ${token}` };
    
    await fetch(`${urlBaseApi}paymentLink/validatepayment/${payment.external_reference}`, { method: 'GET', headers })
    .then(response => {
      if (!response.ok) {
        throw new Error(`No es posible completar este pago, referencia: ${payment.external_reference}`);
      }
      return response.json();
    })
    .then(data => {
      if (data.status === "approved") {
        setErrorMSG("No es posible pagar esta cuota ya que se encuentra en proceso de verificacion de pago, esto podria demorar hasta 1 hora.");
      } else {
        window.open(payment.paymentLink, "_blank");
      }
    })
    .catch(error => {
      setErrorMSG(error.message);
    });

    setIsDisabled(false) 

  }

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>          
          {
            !payments || !payments.docs || !payments.docs.length 
            ? <Grid style={{ textAlign: "center" }} >
                <Alert severity="info">No tiene pagos pendientes.</Alert>
              </Grid>
            : <>
            <Hidden mdDown>
                <Grid container className={classes.header}>
                    <Grid item xs={12} className={classes.alignOnline}>
                        <Typography variant="h5" className={classes.title}>Pagos Incompletos</Typography>
                        <CloseIcon onClick={onClose} className={classes.pointer} />
                    </Grid>
                </Grid>
              </Hidden>

              <Hidden mdUp>
                <Grid item xs={12} className={classes.alignOnline}>
                    <Typography variant="h5" className={classes.title}>Pagos Incompletos</Typography>
                    <CloseIcon onClick={onClose} className={classes.pointer} />
                </Grid>
              </Hidden>            
            </>
          }

          {
            isDisabled && 
            <Grid style={{ textAlign: "center" }} >
              <Alert severity="warning" action={<CircularProgress />}>Verificando en Mercado pago si el pago no ha sido procesado previamente.</Alert>
            </Grid>
          }

          <TableContainer>
            <Table>
              <TableHead></TableHead>
              <TableBody>
                {payments && payments.docs && payments.docs.map((payment) =>{ 
                const canPay = payment.status ? !finalStates.some(state => payment.status.includes(state)) : true

                return(
                  <TableRow key={payment.paymentLink} className={classes.paymentLinkContent}> 
                    <TableCell>
                    
                    <Hidden mdDown>
                      <Accordion className={classes.tableAccordion}>
                        <AccordionSummary>
                          <Grid>
                            <p> {`Poliza${payment.quotas.length > 1 ? 's' : ''}: ${payment.quotasStr}, Total Importe: ${payment.totalImport}`}</p>   
                            <p>{`Valido hasta: ${convertTolocalUTC(payment.expiration_date_to)}`}</p>                         
                            {<p>{`Estado: ${(payment.status.toUpperCase())}`}</p>}                         
                            <a className={classes.aPaymentLink}>Ver Detalles</a>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell className={classes.tableCell}>Cuota</TableCell>
                                    <TableCell className={classes.tableCell}>Poliza/Endoso</TableCell>
                                    <TableCell className={classes.tableCell}>Importe</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {payment.quotas.map((quota) => (
                                    <TableRow key={quota.nroCuota}>
                                      <TableCell>{quota.nroCuota}</TableCell>
                                      <TableCell>{quota.nroPol}/{quota.nroEndo}</TableCell>
                                      <TableCell>{quota.importe_premio}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </AccordionDetails>
                        </Accordion>
                      </Hidden>
                      <Hidden mdUp> 
                        <p>{`Poliza${payment.quotas.length > 1 ? 's' : ''}: ${payment.quotasStr}, total importe: ${payment.totalImport}`}</p>
                        <p>{`Valido hasta: ${convertTolocalUTC(payment.expiration_date_to)}`}</p>                         
                        <p>{`Estado: ${(payment.status.toUpperCase())}`}</p>   
                        {canPay &&
                          <Grid style={{ textAlign: "center", width: "100%" }} >
                            <Button onClick={()=> handlePayment(payment)} disabled={isDisabled} className={classes.BtnPaymentLink}> Pagar </Button>
                          </Grid>
                        }
                      </Hidden>
                    </TableCell>
                    <Hidden mdDown>
                      <TableCell>
                      {canPay &&
                        <Button onClick={()=> handlePayment(payment)} disabled={isDisabled} className={classes.BtnPaymentLink}> Pagar </Button>
                      }
                      </TableCell>
                    </Hidden>
                  </TableRow>
                )}
                
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid style={{ textAlign: "center", width: "100%" }} >
            <Button onClick={onClose}>Cerrar</Button>
          </Grid>
          <ModalMessage 
            title={''} 
            text={errorMSG} 
            openModal={errorMSG} 
            children={
              <Grid container justify="center" alignItems="center">
                <Button onClick={()=>setErrorMSG(undefined)} className={classes.BtnPaymentLink}>Cerrar</Button>
              </Grid>
            }
          />
        </div>
      </Fade>
    </Modal>
  );
};

export default PaymentLinkModal;
