import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import { withStyles, TextField, Box, Grid } from '@material-ui/core'
import { CheckCircleOutline, Edit } from '@material-ui/icons';
 
export const styles = (theme) => ({
    label: {
      fontFamily: 'Poppins',
      marginBottom: '5px',
      fontSize: '12px',
      letterSpacing: '0.4px',
      lineHeight: '16px',
      color: 'rgba(0, 0, 0, 0.38)'
    },
    input: {
      color: 'black',
      outline: 0,
      paddingLeft: '10px'
    },
    errorInput: {
      border: '1px solid #FF2272'
    },
    errorLabel: {
      color: '#FF2272'
    },
    fullWidth: {
        width: '100%',
        '& .MuiFormLabel-root.Mui-focused': {
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: '1.25px'
        },
        //helper text
        '& .MuiFormHelperText-root.Mui-error': {
            position: 'absolute',
            bottom: -20
        },
        //text label
        '& .MuiFormLabel-root': {
            color: theme.palette.variants && theme.palette.variants.variant4
        },
        //line disabled
        '& .MuiInput-underline': {
            '&::before': {
                borderBottom: `1px solid ${theme.palette.variants && theme.palette.variants.variant2}`
            },
            '&:hover': {
                borderBottom: `0px solid ${theme.palette.variants && theme.palette.variants.variant2}`
            }
        }
    },
    fullWidthHover: {
        width: '100%',
        //text label
        '& .MuiFormLabel-root': {
            color: theme.palette.variants && theme.palette.variants.variant4
        },
        '& .MuiInput-underline': {
            '&::before': {
                borderBottom: `1px solid ${theme.palette.variants && theme.palette.variants.variant2} !important`
            },
            '&:hover': {
                borderBottom: `0px solid ${theme.palette.variants && theme.palette.variants.variant2}`
            },
        }
        
    },
    iconCheck: {
        color: '#23D9B7'
    },
    iconEdit: {
        color: theme.palette.secondary.main,
        '&:hover': {
            cursor: 'pointer'
        }
    }
});
  

class TextInput extends ValidatorComponent {

    renderValidatorComponent() {
        const { 
            classes, 
            edit, 
            value,
            handleEnableInput,
            disabled,
            errorMessages, 
            validators, 
            requiredError, 
            validatorListener, 
            ...rest 
        } = this.props;
        const { isValid } = this.state;

        return (
            <div style={{ position: 'relative' }}>
                <TextField
                    {...rest}
                    value={value}
                    ref={(r) => { this.input = r; }}
                    error={!isValid ? true : false}
                    helperText={!isValid ? this.getErrorMessage() : false}
                    className={edit ? classes.fullWidthHover : classes.fullWidth}
                    InputProps = {{
                        readOnly: edit || disabled ? true : false
                    }}
                    disabled={edit}
                />
                {
                    disabled ? (
                        null
                    ) : isValid && !edit ? (
                            <Box style={{ position: 'absolute', right: 0, bottom: 2 }}>
                                <CheckCircleOutline className={classes.iconCheck}/>
                            </Box>
                        ) : null
                }
            </div>
        );
    }
}
 
export default withStyles(styles)(TextInput);