import React, { useState } from 'react';
import { Typography, Grid, Modal, Backdrop } from '@material-ui/core'
import { useStyles } from './styles'

const ModalMessage = ({children, title, text, openModal}) => {
    const classes = useStyles()

    return (
        <Modal
            open={openModal}
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
        >
            <Grid className={classes.gridModal}>
                <Grid>
                    <Typography align="center" variant="h5" className={classes.title}>{title}</Typography>        
                </Grid>
                <Grid style={{ padding: '20px 0px' }}>
                    <Typography align="center" variant="h6" className={classes.text}>{text}</Typography>        
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {children}
                </Grid>
            </Grid>
        </Modal>
    );
}

export default ModalMessage;
